import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { SDKModels } from './SDKModels';
import { BaseLoopBackApi, TCustomHeaders, TCustomOptions } from '../core/base.service';
import { LoopBackConfig } from '../../lb.config';
import { LoopBackAuth } from '../core/auth.service';
import { LoopBackFilter,  } from '../../models/BaseModels';
import { ErrorHandler } from '../core/error.service';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { MyUtils } from '../../models/MyUtils';


/**
 * Api services for the `MyUtils` model.
 */
@Injectable()
export class MyUtilsApi extends BaseLoopBackApi {

  constructor(
    @Inject(HttpClient) protected http: HttpClient,
    @Inject(SDKModels) protected models: SDKModels,
    @Inject(LoopBackAuth) protected auth: LoopBackAuth,
    @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler
  ) {
    super(http,  models, auth, errorHandler);
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} date 
   *
   * @param {object} options 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public myTest(date: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/myTest";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof date !== 'undefined' && date !== null) _urlParams.date = date;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public geoTabAuth(customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/geoTabAuth";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public getGmailPushAuthUrl(customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/getGmailPushAuthUrl";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} code 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public getGmailPushTokenByCode(code: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/getGmailPushTokenByCode";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof code !== 'undefined' && code !== null) _urlParams.code = code;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} refresh_token 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public setGmailPushToStartWatching(refresh_token: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/setGmailPushToStartWatching";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof refresh_token !== 'undefined' && refresh_token !== null) _urlParams.refresh_token = refresh_token;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} refresh_token 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public setGmailPushToStopWatching(refresh_token: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/setGmailPushToStopWatching";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof refresh_token !== 'undefined' && refresh_token !== null) _urlParams.refresh_token = refresh_token;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} args 
   *
   * @param {object} options 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public render(args: any = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/render";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public ping(customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/ping";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public throw(customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/throw";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} _id 
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public updateExportDataCacheAsync(_id: any, data: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "POST";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/updateExportDataCacheAsync";
    let _routeParams: any = {};
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    if (typeof _id !== 'undefined' && _id !== null) _urlParams._id = _id;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public getTimezoneList(customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/getTimezoneList";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} options 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public getDeviceInfo(customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/getDeviceInfo";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public time(customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/time";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public pusherUserAuth(data: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "POST";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/pusherUserAuth";
    let _routeParams: any = {};
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public pusherChannelAuth(data: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "POST";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/pusherChannelAuth";
    let _routeParams: any = {};
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {number} user_id 
   *
   * @param {object} options 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public beamAuth(user_id: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/beamAuth";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof user_id !== 'undefined' && user_id !== null) _urlParams.user_id = user_id;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} deviceUid 
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public pushToMobile(deviceUid: any, data: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "PUT";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/pushToMobile";
    let _routeParams: any = {};
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    if (typeof deviceUid !== 'undefined' && deviceUid !== null) _urlParams.deviceUid = deviceUid;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Upload picture
   *
   * @param {object} data Request data.
   *
   * This method does not accept any data. Supply an empty object.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public uploadPicture(customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "POST";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/uploadPicture";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Get picture link
   *
   * @param {string} filename 
   *
   * @param {string} convert 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public getPictureLink(filename: any, convert: any = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/getPictureLink/:filename";
    let _routeParams: any = {
      filename: filename
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof convert !== 'undefined' && convert !== null) _urlParams.convert = convert;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Download picture
   *
   * @param {string} filename 
   *
   * @param {boolean} redirect 
   *
   * @param {string} convert 
   *
   * @param {object} options 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public downloadPicture(filename: any, redirect: any = {}, convert: any = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/downloadPicture/:filename";
    let _routeParams: any = {
      filename: filename
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof redirect !== 'undefined' && redirect !== null) _urlParams.redirect = redirect;
    if (typeof convert !== 'undefined' && convert !== null) _urlParams.convert = convert;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Mail Hook
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public mailHook(data: any = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "POST";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/mailHook";
    let _routeParams: any = {};
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * MTM notification exchange webhook
   *
   * @param {string} token 
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public mtmWebhook(token: any, data: any = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "POST";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/mtm/event/:token";
    let _routeParams: any = {
      token: token
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * GeoTab notification exchange webhook
   *
   * @param {string} token 
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public geotabWebhook(token: any, data: any = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "POST";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/geotab/exception/:token";
    let _routeParams: any = {
      token: token
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Run Fixer
   *
   * @param {any} args 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public runFixer(args: any = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/runFixer";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof args !== 'undefined' && args !== null) _urlParams.args = args;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Driver Tablet Initial Load
   *
   * @param {string} date `YYYY-MM-DD`
   *
   * @param {number} employeeId 
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public initialLoad1(date: any, employeeId: any, params: any = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "POST";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/initialLoad1";
    let _routeParams: any = {};
    let _postBody: any = {
      params: params
    };
    let _urlParams: any = {};
    if (typeof date !== 'undefined' && date !== null) _urlParams.date = date;
    if (typeof employeeId !== 'undefined' && employeeId !== null) _urlParams.employeeId = employeeId;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   *  - `values` – `{any}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public mongoSaveNotificationSettings(values: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "PUT";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/mongo/notification-settings";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        values: values
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   *  - `values` – `{any}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public mongoSaveEnabledComponents(values: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "PUT";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/mongo/enabled-components";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        values: values
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   *  - `values` – `{any}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public mongoSaveManifestSettings(values: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "PUT";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/mongo/manifest-settings";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        values: values
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   *  - `values` – `{any}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public mongoSaveAutoSchedulingSettings(values: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "PUT";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/mongo/auto-scheduling-settings";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        values: values
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   *  - `values` – `{any}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public mongoSaveAutoDispatchSettings(values: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "PUT";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/mongo/auto-dispatch-settings";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        values: values
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public getTripFacility(body: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "POST";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/getTripFacility";
    let _routeParams: any = {};
    let _postBody: any = {
      body: body
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public isTripAssigned(body: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "POST";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/isTripAssigned";
    let _routeParams: any = {};
    let _postBody: any = {
      body: body
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public getNewConsumerMCIs(body: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "POST";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/getNewConsumerMCIs";
    let _routeParams: any = {};
    let _postBody: any = {
      body: body
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Get Address by latlng
   *
   * @param {string} latlng 
   *
   * @param {object} options 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public reverseGeocode(latlng: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/reverseGeocode";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof latlng !== 'undefined' && latlng !== null) _urlParams.latlng = latlng;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Get latlng by address
   *
   * @param {string} address 
   *
   * @param {object} options 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public geocode(address: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/geocode";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof address !== 'undefined' && address !== null) _urlParams.address = address;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Get mass directions from orgin to destination
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public computeRoutes(routes: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "POST";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/computeRoutes";
    let _routeParams: any = {};
    let _postBody: any = {
      routes: routes
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Get directions from orgin to destination
   *
   * @param {string} from 
   *
   * @param {string} to 
   *
   * @param {string} time Specifies the desired time of arrival|departure for transit directions, in seconds since midnight, January 1, 1970 UTC
   *
   * @param {string} type 'arrive' | 'departure' = 'arrive'
   *
   * @param {object} options 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public directions(from: any, to: any, time: any = {}, type: any = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/directions";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof from !== 'undefined' && from !== null) _urlParams.from = from;
    if (typeof to !== 'undefined' && to !== null) _urlParams.to = to;
    if (typeof time !== 'undefined' && time !== null) _urlParams.time = time;
    if (typeof type !== 'undefined' && type !== null) _urlParams.type = type;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Get route
   *
   * @param {string} from 
   *
   * @param {string} to 
   *
   * @param {string} time Specifies the desired time of arrival|departure for transit directions, in seconds since midnight, January 1, 1970 UTC
   *
   * @param {string} type 'arrive' | 'departure' = 'arrive'
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public route(from: any, to: any, time: any = {}, type: any = {}, waypoints: any = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "PUT";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/route";
    let _routeParams: any = {};
    let _postBody: any = {
      waypoints: waypoints
    };
    let _urlParams: any = {};
    if (typeof from !== 'undefined' && from !== null) _urlParams.from = from;
    if (typeof to !== 'undefined' && to !== null) _urlParams.to = to;
    if (typeof time !== 'undefined' && time !== null) _urlParams.time = time;
    if (typeof type !== 'undefined' && type !== null) _urlParams.type = type;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Remove imports
   *
   * @param {string} metaId 
   *
   * @param {object} options 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public revertImport(metaId: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/revertImport";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof metaId !== 'undefined' && metaId !== null) _urlParams.metaId = metaId;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} startDate `YYYY-MM-DD`
   *
   * @param {string} endDate `YYYY-MM-DD`
   *
   * @param {object} options 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public getMTMTrips(startDate: any, endDate: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/getMTMTrips";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof startDate !== 'undefined' && startDate !== null) _urlParams.startDate = startDate;
    if (typeof endDate !== 'undefined' && endDate !== null) _urlParams.endDate = endDate;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * List Jobs
   *
   * @param {object} options 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public jobsList(customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/jobs";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Abort Job
   *
   * @param {string} key 
   *
   * @param {object} options 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public jobsAbort(key: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "DELETE";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/jobs/:key";
    let _routeParams: any = {
      key: key
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} collection 
   *
   * @param {string} loadOptions 
   *
   * @param {object} options 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public devextreme_mongo(collection: any, loadOptions: any = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/query/:collection";
    let _routeParams: any = {
      collection: collection
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof loadOptions !== 'undefined' && loadOptions !== null) _urlParams.loadOptions = loadOptions;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} collection 
   *
   * @param {object} data Request data.
   *
   *  - `key` – `{string}` - 
   *
   *  - `values` – `{any}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public mongoUpdate(collection: any, key: any, values: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "PUT";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/mongo/:collection";
    let _routeParams: any = {
      collection: collection
    };
    let _postBody: any = {
      data: {
        key: key,
        values: values
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} collection 
   *
   * @param {string} key 
   *
   * @param {object} options 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public mongoDelete(collection: any, key: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "DELETE";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/mongo/:collection";
    let _routeParams: any = {
      collection: collection
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public getMongoDocs(body: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "POST";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/getMongoDocs";
    let _routeParams: any = {};
    let _postBody: any = {
      body: body
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public getMongoCount(body: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "POST";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/getMongoCount";
    let _routeParams: any = {};
    let _postBody: any = {
      body: body
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public getMongoDoc(body: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "POST";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/getMongoDoc";
    let _routeParams: any = {};
    let _postBody: any = {
      body: body
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Upload Apk
   *
   * @param {object} data Request data.
   *
   * This method does not accept any data. Supply an empty object.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public uploadApk(customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "POST";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/uploadApk";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Upload Driver Apk
   *
   * @param {object} data Request data.
   *
   * This method does not accept any data. Supply an empty object.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public uploadDriverApk(customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "POST";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/uploadDriverApk";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Upload Multi-tenant Driver Apk
   *
   * @param {object} data Request data.
   *
   * This method does not accept any data. Supply an empty object.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public uploadMultiDriverApk(customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "POST";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/uploadMultiDriverApk";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Download Apk
   *
   * @param {string} filename 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public downloadApk(filename: any = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/downloadApk";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof filename !== 'undefined' && filename !== null) _urlParams.filename = filename;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Download Driver Apk
   *
   * @param {string} filename 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public downloadDriverApk(filename: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/downloadDriverApk";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof filename !== 'undefined' && filename !== null) _urlParams.filename = filename;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Download Multi-tenant Driver Apk
   *
   * @param {string} filename 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public downloadMultiDriverApk(filename: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/downloadMultiDriverApk";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof filename !== 'undefined' && filename !== null) _urlParams.filename = filename;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Get Last Uploaded Apk Info
   *
   * @param {object} options 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public getApkInfo(customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/getApkInfo";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Get Last Uploaded Driver Apk Info
   *
   * @param {object} options 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public getDriverApkInfo(customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/getDriverApkInfo";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Get Last Uploaded Multi-tenant Driver Apk Info
   *
   * @param {object} options 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public getMultiDriverApkInfo(customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/getMultiDriverApkInfo";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public runJob(data: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "POST";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/runJob";
    let _routeParams: any = {};
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} job 
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public job(job: any, data: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "PUT";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/j/:job";
    let _routeParams: any = {
      job: job
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public runService(body: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "POST";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/runService";
    let _routeParams: any = {};
    let _postBody: any = {
      body: body
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} service 
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `MyUtils` object.)
   * </em>
   */
  public service(service: any, body: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "PUT";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/MyUtils/s/:service";
    let _routeParams: any = {
      service: service
    };
    let _postBody: any = {
      body: body
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `MyUtils`.
   */
  public getModelName() {
    return "MyUtils";
  }
}
