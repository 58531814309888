
declare var Object: any;
export interface AutoDispatchLogInterface {
  "historyId"?: string;
  "threadId"?: string;
  "subject"?: string;
  "broker"?: any;
  "cc"?: Array<any>;
  "incomingAt"?: Date;
  "startAt"?: Date;
  "stopAt"?: Date;
  "replyAt"?: Date;
  "executionTook"?: number;
  "incomingTripsCount"?: number;
  "validTripsCount"?: number;
  "accepted"?: Array<any>;
  "denied"?: Array<any>;
  "tripsAllowed"?: number;
  "daysLoadMap"?: any;
  "id"?: any;
}

export class AutoDispatchLog implements AutoDispatchLogInterface {
  "historyId": string;
  "threadId": string;
  "subject": string;
  "broker": any;
  "cc": Array<any>;
  "incomingAt": Date;
  "startAt": Date;
  "stopAt": Date;
  "replyAt": Date;
  "executionTook": number;
  "incomingTripsCount": number;
  "validTripsCount": number;
  "accepted": Array<any>;
  "denied": Array<any>;
  "tripsAllowed": number;
  "daysLoadMap": any;
  "id": any;
  constructor(data?: AutoDispatchLogInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `AutoDispatchLog`.
   */
  public static getModelName() {
    return "AutoDispatchLog";
  }

  /**
  * This method creates an instance of AutoDispatchLog for dynamic purposes.
  **/
  public static factory(data: AutoDispatchLogInterface): AutoDispatchLog{
    return new AutoDispatchLog(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'AutoDispatchLog',
      plural: 'AutoDispatchLogs',
      path: 'AutoDispatchLogs',
      idName: 'id',
      properties: {
        "historyId": {
          name: 'historyId',
          type: 'string'
        },
        "threadId": {
          name: 'threadId',
          type: 'string'
        },
        "subject": {
          name: 'subject',
          type: 'string'
        },
        "broker": {
          name: 'broker',
          type: 'any'
        },
        "cc": {
          name: 'cc',
          type: 'Array&lt;any&gt;'
        },
        "incomingAt": {
          name: 'incomingAt',
          type: 'Date'
        },
        "startAt": {
          name: 'startAt',
          type: 'Date'
        },
        "stopAt": {
          name: 'stopAt',
          type: 'Date'
        },
        "replyAt": {
          name: 'replyAt',
          type: 'Date'
        },
        "executionTook": {
          name: 'executionTook',
          type: 'number'
        },
        "incomingTripsCount": {
          name: 'incomingTripsCount',
          type: 'number'
        },
        "validTripsCount": {
          name: 'validTripsCount',
          type: 'number'
        },
        "accepted": {
          name: 'accepted',
          type: 'Array&lt;any&gt;'
        },
        "denied": {
          name: 'denied',
          type: 'Array&lt;any&gt;'
        },
        "tripsAllowed": {
          name: 'tripsAllowed',
          type: 'number'
        },
        "daysLoadMap": {
          name: 'daysLoadMap',
          type: 'any'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
      },
      relations: {
      }
    }
  }
}
