
declare var Object: any;
export interface SignatureConsUniqImgViewInterface {
  "id"?: number;
  "consumerId"?: number;
  "count": number;
  "imgFileId"?: string;
}

export class SignatureConsUniqImgView implements SignatureConsUniqImgViewInterface {
  "id": number;
  "consumerId": number;
  "count": number;
  "imgFileId": string;
  constructor(data?: SignatureConsUniqImgViewInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `SignatureConsUniqImgView`.
   */
  public static getModelName() {
    return "SignatureConsUniqImgView";
  }

  /**
  * This method creates an instance of SignatureConsUniqImgView for dynamic purposes.
  **/
  public static factory(data: SignatureConsUniqImgViewInterface): SignatureConsUniqImgView{
    return new SignatureConsUniqImgView(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'SignatureConsUniqImgView',
      plural: 'SignatureConsUniqImgViews',
      path: 'SignatureConsUniqImgViews',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'number'
        },
        "consumerId": {
          name: 'consumerId',
          type: 'number'
        },
        "count": {
          name: 'count',
          type: 'number'
        },
        "imgFileId": {
          name: 'imgFileId',
          type: 'string'
        },
      },
      relations: {
      }
    }
  }
}
