
declare var Object: any;
export interface FuelRefillViewInterface {
  "id": number;
  "amount": number;
  "cardNum": string;
  "ctime": Date;
  "date"?: Date;
  "dateTime": Date;
  "dtime"?: Date;
  "dtime_str"?: string;
  "employee_person_firstname"?: string;
  "employee_person_fullname"?: string;
  "employee_person_lastname"?: string;
  "employee_status"?: string;
  "employee_tenantId"?: number;
  "employeeId": number;
  "facility_shortname"?: string;
  "fuelType": string;
  "meta": any;
  "note"?: string;
  "odometer": number;
  "receiptImgFileId": string;
  "requestID": string;
  "userId": number;
  "utime": Date;
  "vdate"?: any;
  "vehicle_internalId"?: number;
  "vehicleId": number;
}

export class FuelRefillView implements FuelRefillViewInterface {
  "id": number;
  "amount": number;
  "cardNum": string;
  "ctime": Date;
  "date": Date;
  "dateTime": Date;
  "dtime": Date;
  "dtime_str": string;
  "employee_person_firstname": string;
  "employee_person_fullname": string;
  "employee_person_lastname": string;
  "employee_status": string;
  "employee_tenantId": number;
  "employeeId": number;
  "facility_shortname": string;
  "fuelType": string;
  "meta": any;
  "note": string;
  "odometer": number;
  "receiptImgFileId": string;
  "requestID": string;
  "userId": number;
  "utime": Date;
  "vdate": any;
  "vehicle_internalId": number;
  "vehicleId": number;
  constructor(data?: FuelRefillViewInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `FuelRefillView`.
   */
  public static getModelName() {
    return "FuelRefillView";
  }

  /**
  * This method creates an instance of FuelRefillView for dynamic purposes.
  **/
  public static factory(data: FuelRefillViewInterface): FuelRefillView{
    return new FuelRefillView(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'FuelRefillView',
      plural: 'FuelRefillViews',
      path: 'FuelRefillViews',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'number'
        },
        "amount": {
          name: 'amount',
          type: 'number'
        },
        "cardNum": {
          name: 'cardNum',
          type: 'string'
        },
        "ctime": {
          name: 'ctime',
          type: 'Date'
        },
        "date": {
          name: 'date',
          type: 'Date'
        },
        "dateTime": {
          name: 'dateTime',
          type: 'Date'
        },
        "dtime": {
          name: 'dtime',
          type: 'Date'
        },
        "dtime_str": {
          name: 'dtime_str',
          type: 'string'
        },
        "employee_person_firstname": {
          name: 'employee_person_firstname',
          type: 'string'
        },
        "employee_person_fullname": {
          name: 'employee_person_fullname',
          type: 'string'
        },
        "employee_person_lastname": {
          name: 'employee_person_lastname',
          type: 'string'
        },
        "employee_status": {
          name: 'employee_status',
          type: 'string'
        },
        "employee_tenantId": {
          name: 'employee_tenantId',
          type: 'number'
        },
        "employeeId": {
          name: 'employeeId',
          type: 'number'
        },
        "facility_shortname": {
          name: 'facility_shortname',
          type: 'string'
        },
        "fuelType": {
          name: 'fuelType',
          type: 'string'
        },
        "meta": {
          name: 'meta',
          type: 'any'
        },
        "note": {
          name: 'note',
          type: 'string'
        },
        "odometer": {
          name: 'odometer',
          type: 'number'
        },
        "receiptImgFileId": {
          name: 'receiptImgFileId',
          type: 'string'
        },
        "requestID": {
          name: 'requestID',
          type: 'string'
        },
        "userId": {
          name: 'userId',
          type: 'number'
        },
        "utime": {
          name: 'utime',
          type: 'Date'
        },
        "vdate": {
          name: 'vdate',
          type: 'any'
        },
        "vehicle_internalId": {
          name: 'vehicle_internalId',
          type: 'number'
        },
        "vehicleId": {
          name: 'vehicleId',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
