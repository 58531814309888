/**
* @description
* The SDKModule is a generated Software Development Kit automatically built by
* the LoopBack SDK Builder open source module.
*
* The SDKModule provides Angular 2 >= RC.5 support, which means that NgModules
* can import this Software Development Kit as follows:
*
*
* APP Route Module Context
* ============================================================================
* import { NgModule }       from '@angular/core';
* import { BrowserModule }  from '@angular/platform-browser';
* // App Root 
* import { AppComponent }   from './app.component';
* // Feature Modules
* import { SDK[Browser|Node|Native]Module } from './shared/sdk/sdk.module';
* // Import Routing
* import { routing }        from './app.routing';
* @NgModule({
*  imports: [
*    BrowserModule,
*    routing,
*    SDK[Browser|Node|Native]Module.forRoot()
*  ],
*  declarations: [ AppComponent ],
*  bootstrap:    [ AppComponent ]
* })
* export class AppModule { }
*
**/
import { ErrorHandler } from './services/core/error.service';
import { LoopBackAuth } from './services/core/auth.service';
import { LoggerService } from './services/custom/logger.service';
import { SDKModels } from './services/custom/SDKModels';
import { InternalStorage, SDKStorage } from './storage/storage.swaps';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CookieBrowser } from './storage/cookie.browser';
import { StorageBrowser } from './storage/storage.browser';
import { BaseContainerApi } from './services/custom/BaseContainer';
import { ContainerApi } from './services/custom/Container';
import { RoleApi } from './services/custom/Role';
import { RoleMappingApi } from './services/custom/RoleMapping';
import { ACLApi } from './services/custom/ACL';
import { AccessTokenApi } from './services/custom/AccessToken';
import { MyUserApi } from './services/custom/MyUser';
import { MyMessageApi } from './services/custom/MyMessage';
import { ConfigApi } from './services/custom/Config';
import { StateApi } from './services/custom/State';
import { ContactApi } from './services/custom/Contact';
import { AddressApi } from './services/custom/Address';
import { PhoneApi } from './services/custom/Phone';
import { EmailAddressApi } from './services/custom/EmailAddress';
import { FacilityApi } from './services/custom/Facility';
import { EmployeeApi } from './services/custom/Employee';
import { EmployeePayrollApi } from './services/custom/EmployeePayroll';
import { ConsumerApi } from './services/custom/Consumer';
import { VehicleApi } from './services/custom/Vehicle';
import { AcquisitionApi } from './services/custom/Acquisition';
import { PersonApi } from './services/custom/Person';
import { ProgramApi } from './services/custom/Program';
import { FacilityEmPersonApi } from './services/custom/FacilityEmPerson';
import { EmployeeEmPersonApi } from './services/custom/EmployeeEmPerson';
import { ConsumerEmPersonApi } from './services/custom/ConsumerEmPerson';
import { TrainedAtFacilityApi } from './services/custom/TrainedAtFacility';
import { AssignedToFacilityApi } from './services/custom/AssignedToFacility';
import { DocumentApi } from './services/custom/Document';
import { DocumentTypeApi } from './services/custom/DocumentType';
import { EmployeePositionApi } from './services/custom/EmployeePosition';
import { MyFileApi } from './services/custom/MyFile';
import { CustomFieldApi } from './services/custom/CustomField';
import { ObjectCFVApi } from './services/custom/ObjectCFV';
import { TripManifestApi } from './services/custom/TripManifest';
import { TripManifestRecApi } from './services/custom/TripManifestRec';
import { SignatureApi } from './services/custom/Signature';
import { FuelRefillApi } from './services/custom/FuelRefill';
import { DayServiceApi } from './services/custom/DayService';
import { EmployeeDayServiceApi } from './services/custom/EmployeeDayService';
import { Covid19ChecksApi } from './services/custom/Covid19Checks';
import { NoteApi } from './services/custom/Note';
import { AlertApi } from './services/custom/Alert';
import { MyUserViewApi } from './services/custom/MyUserView';
import { ContactViewApi } from './services/custom/ContactView';
import { FacilityViewApi } from './services/custom/FacilityView';
import { EmployeeViewApi } from './services/custom/EmployeeView';
import { EmployeeDocExpCntViewApi } from './services/custom/EmployeeDocExpCntView';
import { ConsumerViewApi } from './services/custom/ConsumerView';
import { ConsumerAddressViewApi } from './services/custom/ConsumerAddressView';
import { PersonViewApi } from './services/custom/PersonView';
import { DocumentViewApi } from './services/custom/DocumentView';
import { CustomFieldValuesViewApi } from './services/custom/CustomFieldValuesView';
import { DocumentFieldsViewApi } from './services/custom/DocumentFieldsView';
import { LastFileViewApi } from './services/custom/LastFileView';
import { LastDocumentWithExpirationApi } from './services/custom/LastDocumentWithExpiration';
import { SignatureViewApi } from './services/custom/SignatureView';
import { SignatureConsUniqImgViewApi } from './services/custom/SignatureConsUniqImgView';
import { FuelRefillViewApi } from './services/custom/FuelRefillView';
import { TripSignatureStatsApi } from './services/custom/TripSignatureStats';
import { ActiveClientSignatureViewApi } from './services/custom/ActiveClientSignatureView';
import { MealsAdcSignatureStatsApi } from './services/custom/MealsAdcSignatureStats';
import { TripSignatureCountApi } from './services/custom/TripSignatureCount';
import { AdcTripSignatureStatsApi } from './services/custom/AdcTripSignatureStats';
import { FacilitySignatureStatsApi } from './services/custom/FacilitySignatureStats';
import { ByClientsSignatureStatsApi } from './services/custom/ByClientsSignatureStats';
import { ByEmployeeSignatureStatsApi } from './services/custom/ByEmployeeSignatureStats';
import { VehicleCheckUpApi } from './services/custom/VehicleCheckUp';
import { PostShiftReportApi } from './services/custom/PostShiftReport';
import { MobileDeviceApi } from './services/custom/MobileDevice';
import { AcquisitionRelatedContactApi } from './services/custom/AcquisitionRelatedContact';
import { AnyNoteApi } from './services/custom/AnyNote';
import { AutoDispatchLogApi } from './services/custom/AutoDispatchLog';
import { AutoRepairShopApi } from './services/custom/AutoRepairShop';
import { FuelCardApi } from './services/custom/FuelCard';
import { VehicleGeotabApi } from './services/custom/VehicleGeotab';
import { VehicleGeotabEventApi } from './services/custom/VehicleGeotabEvent';
import { DriverScheduleApi } from './services/custom/DriverSchedule';
import { EmployeeWorkingTimeApi } from './services/custom/EmployeeWorkingTime';
import { TripInfoApi } from './services/custom/TripInfo';
import { MyUtilsApi } from './services/custom/MyUtils';
import { CommonApi } from './services/custom/Common';
import { MyJobApi } from './services/custom/MyJob';
import { MyServiceApi } from './services/custom/MyService';
import { AuthServiceApi } from './services/custom/AuthService';
import { PersonalPhotoApi } from './services/custom/PersonalPhoto';
import { RouterApi } from './services/custom/Router';
/**
* @module SDKBrowserModule
* @description
* This module should be imported when building a Web Application in the following scenarios:
*
*  1.- Regular web application
*  2.- Angular universal application (Browser Portion)
*  3.- Progressive applications (Angular Mobile, Ionic, WebViews, etc)
**/
@NgModule({
  imports:      [ CommonModule, HttpClientModule ],
  declarations: [ ],
  exports:      [ ],
  providers:    [
    ErrorHandler
  ]
})
export class SDKBrowserModule {
  static forRoot(internalStorageProvider: any = {
    provide: InternalStorage,
    useClass: CookieBrowser
  }): ModuleWithProviders {
    return {
      ngModule  : SDKBrowserModule,
      providers : [
        LoopBackAuth,
        LoggerService,
        SDKModels,
        BaseContainerApi,
        ContainerApi,
        RoleApi,
        RoleMappingApi,
        ACLApi,
        AccessTokenApi,
        MyUserApi,
        MyMessageApi,
        ConfigApi,
        StateApi,
        ContactApi,
        AddressApi,
        PhoneApi,
        EmailAddressApi,
        FacilityApi,
        EmployeeApi,
        EmployeePayrollApi,
        ConsumerApi,
        VehicleApi,
        AcquisitionApi,
        PersonApi,
        ProgramApi,
        FacilityEmPersonApi,
        EmployeeEmPersonApi,
        ConsumerEmPersonApi,
        TrainedAtFacilityApi,
        AssignedToFacilityApi,
        DocumentApi,
        DocumentTypeApi,
        EmployeePositionApi,
        MyFileApi,
        CustomFieldApi,
        ObjectCFVApi,
        TripManifestApi,
        TripManifestRecApi,
        SignatureApi,
        FuelRefillApi,
        DayServiceApi,
        EmployeeDayServiceApi,
        Covid19ChecksApi,
        NoteApi,
        AlertApi,
        MyUserViewApi,
        ContactViewApi,
        FacilityViewApi,
        EmployeeViewApi,
        EmployeeDocExpCntViewApi,
        ConsumerViewApi,
        ConsumerAddressViewApi,
        PersonViewApi,
        DocumentViewApi,
        CustomFieldValuesViewApi,
        DocumentFieldsViewApi,
        LastFileViewApi,
        LastDocumentWithExpirationApi,
        SignatureViewApi,
        SignatureConsUniqImgViewApi,
        FuelRefillViewApi,
        TripSignatureStatsApi,
        ActiveClientSignatureViewApi,
        MealsAdcSignatureStatsApi,
        TripSignatureCountApi,
        AdcTripSignatureStatsApi,
        FacilitySignatureStatsApi,
        ByClientsSignatureStatsApi,
        ByEmployeeSignatureStatsApi,
        VehicleCheckUpApi,
        PostShiftReportApi,
        MobileDeviceApi,
        AcquisitionRelatedContactApi,
        AnyNoteApi,
        AutoDispatchLogApi,
        AutoRepairShopApi,
        FuelCardApi,
        VehicleGeotabApi,
        VehicleGeotabEventApi,
        DriverScheduleApi,
        EmployeeWorkingTimeApi,
        TripInfoApi,
        MyUtilsApi,
        CommonApi,
        MyJobApi,
        MyServiceApi,
        AuthServiceApi,
        PersonalPhotoApi,
        RouterApi,
        internalStorageProvider,
        { provide: SDKStorage, useClass: StorageBrowser }
      ]
    };
  }
}
/**
* Have Fun!!!
* - Jon
**/
export * from './models/index';
export * from './services/index';
export * from './lb.config';
export * from './storage/storage.swaps';
export { CookieBrowser } from './storage/cookie.browser';
export { StorageBrowser } from './storage/storage.browser';

