import * as tslib_1 from "tslib";
import moment from 'moment';
import { MyUtils, Router } from 'src/app/shared/sdk';
import { TIMEZONE } from '../../trips-audit/components/trips-audit-grid/trips-audit-grid.component';
export class RouterHelperService {
    constructor() {
        this.routesMap = {};
        this.markerBaseUrl = '/assets/images/';
    }
    makeTripsPool(trips, manifestGroups, keepManifestTrips, selectedVehicleIdsSet) {
        let tripsPool = trips.filter(t => t.c);
        let vehicles = [];
        if (keepManifestTrips) {
            tripsPool = manifestGroups
                .filter(group => !group.vehicle || !selectedVehicleIdsSet.has(group.vehicle.id))
                .map(group => group.trips)
                .flat();
            vehicles = manifestGroups.filter(group => selectedVehicleIdsSet.has(group.vehicle && group.vehicle.id));
        }
        else {
            tripsPool = tripsPool.filter(t => !t.lock);
            vehicles = JSON.parse(JSON.stringify(manifestGroups)).filter(group => {
                group.trips = group.trips.filter(t => t.lock);
                return group.trips.length > 0;
            });
        }
        return [tripsPool, vehicles];
    }
    proposeGroups(tripsPool, vehiclesCount, distribute, vehicles, date, dss) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.validateTripsPool(tripsPool);
            return yield dss
                .getApi(Router)
                .propose({ tripsPool, vehiclesCount, distribute, vehicles, date })
                .toPromise();
        });
    }
    validateTripsPool(tripsPool) {
        if (!tripsPool.every(t => t.dot && t.dst && t.dur))
            throw new Error('Trips pool is invalid');
    }
    assignProposedGroups(dataSrc, proposedGroups, selectedVehicleIdsSet, selectedVehicleIds, manifestVehiclesMap, vehiclesMap) {
        dataSrc = dataSrc.map(({ manifestGroup }, i) => {
            let proposedGroup = null;
            if (selectedVehicleIdsSet.has(manifestGroup.vehicle && manifestGroup.vehicle.id)) {
                let idx = proposedGroups.findIndex(group => group.vehicle && group.vehicle.id === manifestGroup.vehicle.id);
                if (idx == -1)
                    idx = proposedGroups.findIndex(group => !group.vehicle);
                if (idx !== -1) {
                    proposedGroup = proposedGroups.splice(idx, 1)[0];
                    proposedGroup = this.prepareProposedTrips(manifestGroup, proposedGroup);
                }
            }
            return { manifestGroup, proposedGroup };
        });
        const newVehicleIds = selectedVehicleIds.filter(id => !manifestVehiclesMap[id]);
        proposedGroups.forEach((pt, i) => {
            const vehicle = vehiclesMap[newVehicleIds[i]];
            dataSrc.push({
                manifestGroup: { vehicle },
                proposedGroup: this.prepareProposedTrips({ vehicle }, pt),
            });
        });
        return dataSrc;
    }
    prepareProposedTrips(group, proposedGroup) {
        const v = Object.assign({}, group, { trips: null }, proposedGroup);
        v.load = this.calculateLoadPerGroup(v);
        return v;
    }
    makeManifestGroups(detailedTrips) {
        const [vehiclesMap] = detailedTrips.reduce(([p, empl], trip) => {
            const v = trip.v || -1;
            trip.lock = false;
            if (!p[v]) {
                const employee = trip.__employee && !empl[trip.__employee.id] ? trip.__employee : null;
                if (employee)
                    empl[employee.id] = true;
                const escort = trip.__escort && !empl[trip.__escort.id] ? trip.__escort : null;
                if (escort)
                    empl[escort.id] = true;
                p[v] = { employee, escort, vehicle: trip.__vehicle, trips: [] };
            }
            if (trip.c)
                p[v].trips.push(trip);
            return [p, empl];
        }, [{}, {}]);
        const groups = Object.values(vehiclesMap).map((v) => {
            (v.trips || []).sort(({ t: ta }, { t: tb }) => (ta < tb ? -1 : ta > tb ? 1 : 0));
            v.load = this.calculateLoadPerGroup(v);
            return v;
        });
        return groups;
    }
    calculateLoadPerGroup(v) {
        const trips = v.trips;
        if (!trips || !trips.length)
            return null;
        const changeTripTime = 30;
        const tripsCount = trips.length;
        let [loadedMinutes, loadedDistance] = trips.reduce((p, { dur, dst }) => [p[0] + dur, p[1] + dst], [0, 0]);
        loadedMinutes += changeTripTime * (tripsCount - 1);
        const [travelMinutes, travelDistance] = [null, null, null, null];
        return { tripsCount, loadedMinutes, travelMinutes, travelDistance, loadedDistance };
    }
    sortDataSource(dataSource, order, employeeWorkingTimeMap) {
        return dataSource.sort(({ manifestGroup: a }, { manifestGroup: b }) => {
            if (!a || !a.employee || !a.vehicle)
                return -1;
            if (!b || !b.employee || !b.vehicle)
                return 1;
            const [aTime, bTime] = [employeeWorkingTimeMap[a.employee.id], employeeWorkingTimeMap[b.employee.id]];
            let [aMin, bMin] = [
                (aTime && aTime.totalMinutesBeforeCurrent) || 0,
                (bTime && bTime.totalMinutesBeforeCurrent) || 0,
            ];
            if (order === 'least')
                return aMin - bMin;
            return bMin - aMin;
        });
    }
    getAddr(d, c, destinationsMap) {
        if (d === 'RESIDENCE') {
            const addr = c.person.contact.addresses.filter(a => a.meta.formatted)[0];
            return (addr && addr.meta.formatted) || '';
        }
        return (destinationsMap[d] && destinationsMap[d].address) || d;
    }
    getFromDoToPuAddresses(prev, next, destinationsMap) {
        const from = this.getAddr(prev.d, prev.__consumer, destinationsMap);
        const to = this.getAddr(next.o, next.__consumer, destinationsMap);
        return { from, to };
    }
    getFromDoToPuAddressesAndTime(date, prev, next, destinationsMap) {
        const time = moment.tz(`${date} ${next.t}`, 'YYYY-MM-DD HH:mm', TIMEZONE).utc().unix();
        return Object.assign({ time }, this.getFromDoToPuAddresses(prev, next, destinationsMap));
    }
    getRouteKey(dropOffAddress, pickupAddress, time) {
        return `${time}: ${dropOffAddress} -> ${pickupAddress}`;
    }
    addEmptyKeysToRoutesMap(date, group, destinationsMap) {
        if (!group || !group.trips)
            return;
        const trips = group.trips;
        for (let i = 0; i < trips.length - 1; i++) {
            const { from, to, time } = this.getFromDoToPuAddressesAndTime(date, trips[i], trips[i + 1], destinationsMap);
            const routeKey = this.getRouteKey(from, to, time);
            if (!this.routesMap[routeKey])
                this.routesMap[routeKey] = { from, to, time };
        }
    }
    fillRoutesMap(date, dataSource, destinationsMap, dss) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            for (const data of dataSource) {
                this.addEmptyKeysToRoutesMap(date, data.manifestGroup, destinationsMap);
                this.addEmptyKeysToRoutesMap(date, data.proposedGroup, destinationsMap);
            }
            const routes = Object.values(this.routesMap).filter((route) => !route.duration);
            if (!routes.length)
                return;
            const routesResp = yield dss.getApi(MyUtils).computeRoutes(routes).toPromise();
            routes.forEach((route, i) => {
                const routeKey = this.getRouteKey(route.from, route.to, route.time);
                const { duration, distance } = routesResp[i] || { duration: { value: 0 }, distance: { value: 0 } };
                this.routesMap[routeKey] = Object.assign({}, route, { duration, distance });
            });
        });
    }
    calculateTravelPerGroup(date, group, destinationsMap, employeeWorkingTimeMap) {
        if (!group || !group.trips || !group.load)
            return;
        const trips = group.trips;
        let travelMinutes = group.load.loadedMinutes;
        let travelDistance = group.load.loadedDistance;
        for (let i = 0; i < trips.length - 1; i++) {
            const { from, to, time } = this.getFromDoToPuAddressesAndTime(date, trips[i], trips[i + 1], destinationsMap);
            const routeKey = this.getRouteKey(from, to, time);
            if (this.routesMap[routeKey]) {
                travelMinutes += Math.floor(this.routesMap[routeKey].duration.value / 60);
                travelDistance += this.routesMap[routeKey].distance.value;
            }
        }
        const firstTrip = trips[0];
        const lastTrip = trips[trips.length - 1];
        let workingMinutes = group.workingMinutes;
        if (!workingMinutes && group.employee && employeeWorkingTimeMap[group.employee.id])
            workingMinutes = employeeWorkingTimeMap[group.employee.id].currentMinutes;
        if (!workingMinutes)
            workingMinutes = moment.duration(moment(lastTrip.dot, 'hh:mm').diff(moment(firstTrip.t, 'hh:mm'))).asMinutes();
        let percent = Math.floor((travelMinutes * 100) / workingMinutes);
        if (percent > 100)
            percent = 100;
        const freeMinutes = workingMinutes - travelMinutes;
        group.load = Object.assign({}, group.load, { percent, travelMinutes, travelDistance, freeMinutes });
    }
    calculateTravel({ manifest, proposed }, date, dataSource, destinationsMap, dss, employeeWorkingTimeMap) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.fillRoutesMap(date, dataSource, destinationsMap, dss);
            for (const data of dataSource) {
                this.calculateTravelPerGroup(date, data.manifestGroup, destinationsMap, employeeWorkingTimeMap);
                this.calculateTravelPerGroup(date, data.proposedGroup, destinationsMap, employeeWorkingTimeMap);
            }
            this.calculateCombinedTravel(manifest, dataSource.map(d => d.manifestGroup));
            this.calculateCombinedTravel(proposed, dataSource.map(d => d.proposedGroup));
        });
    }
    calculateCombinedTravel(totals, groups) {
        const groupsWithVehicle = groups.filter(v => v && v.vehicle && v.load);
        const [totalMinutes, totalDistance] = groupsWithVehicle.reduce((p, { load: { travelMinutes, travelDistance } }) => [p[0] + travelMinutes, p[1] + travelDistance], [0, 0]);
        totals.combinedTravelMinutes = totalMinutes;
        totals.combinedTravelDistance = totalDistance;
        totals.combinedFreeMinutes =
            totalMinutes > totals.combinedLoadedMinutes ? totalMinutes - totals.combinedLoadedMinutes : 0;
    }
    calculateTotals(groups) {
        const groupsWithVehicle = groups.filter(v => v.vehicle);
        const totalVehicles = groupsWithVehicle.length;
        const [totalLoadedMin, totalFreeMin, totalDistance] = groups
            .filter(g => g.load)
            .reduce((p, { load: { loadedMinutes, freeMinutes, loadedDistance } }) => [
            p[0] + loadedMinutes,
            p[1] + freeMinutes,
            p[2] + loadedDistance,
        ], [0, 0, 0]);
        const totalNumberOfTrips = groups
            .filter(g => g.load)
            .reduce((p, { load: { tripsCount } }) => p + tripsCount, 0);
        return {
            totalVehicles,
            combinedLoadedMinutes: totalLoadedMin,
            totalNumberOfTrips,
            combinedLoadedDistance: totalDistance,
            combinedTravelMinutes: null,
            combinedTravelDistance: null,
        };
    }
    getRoutesAndMarkers(trips, destinationsMap) {
        const routes = [];
        const markers = [];
        for (let i = 0; i < trips.length; i++) {
            const trip = trips[i];
            const origing = this.getAddr(trip.o, trip.__consumer, destinationsMap);
            const destination = this.getAddr(trip.d, trip.__consumer, destinationsMap);
            routes.push(this.getRoute([origing, destination], false, i));
            const nextTrip = trips[i + 1];
            if (nextTrip) {
                const nextOriging = this.getAddr(nextTrip.o, nextTrip.__consumer, destinationsMap);
                routes.push(this.getRoute([destination, nextOriging], true));
            }
            markers.push(...this.getPuDoMarkers([
                { location: origing, dateTime: trip.t },
                { location: destination, dateTime: trip.dot },
            ], trip.__consumer.person));
        }
        return { routes, markers };
    }
    getPuDoMarkers(positions, ePerson) {
        return [
            Object.assign({}, positions[0], { iconSrc: this.markerBaseUrl + 'marker-pickup.png', isPU: true }),
            Object.assign({}, positions[1], { iconSrc: this.markerBaseUrl + `marker-dropoff.png` }),
        ].map(({ dateTime, location, iconSrc, isPU }) => ({
            iconSrc,
            location: `${location}`,
            tooltip: {
                text: `${ePerson.lastname}, ${ePerson.firstname}` +
                    `<br/><em>${isPU ? 'Pick Up' : 'Drop Off'} Time:</em> ${moment(dateTime, 'HH:mm:ss').format('hh:mm A')}`,
                isShown: false,
            },
        }));
    }
    getTripAddresses(trip, destinationsMap) {
        const from = this.getAddr(trip.o, trip.__consumer, destinationsMap);
        const to = this.getAddr(trip.d, trip.__consumer, destinationsMap);
        return { from, to };
    }
    getRoute(locations, isBetween, trupNumber = 0) {
        const colors = ['red', 'green', 'blue', 'brown', '#0083ff', '#3cbc4d', '#a02370', '#7f7213', '#12677c'];
        return {
            weight: 4,
            color: isBetween ? 'red' : colors[trupNumber % colors.length],
            opacity: isBetween ? 0.3 : 0.8,
            mode: '',
            locations: [...locations],
            tooltip: {
                text: 'asdf',
                isShown: true,
            },
        };
    }
}
