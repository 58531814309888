import difference from 'lodash-es/difference';
import { AccessToken, MyUser } from '../../../shared/sdk';
import {
  Actions,
  CHECK_AUTH_FAIL,
  CHECK_AUTH_SUCCESS,
  CheckAuthSuccess,
  LOAD_ROLES,
  LOAD_ROLES_FAIL,
  LOAD_ROLES_SUCCESS,
  LoadRolesSuccess,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGIN_WITH_MFA,
  LoginSuccess,
  LoginWithMfa,
  LOGOUT_FAIL,
  LOGOUT_SUCCESS,
  SET_TENANT_FAIL,
  SET_TENANT_SUCCESS,
  SetTenantSuccess,
} from '../../actions/sign';

export interface IEnabledComponents {
  autoDispatch: boolean;
}

export interface InfoState {
  accessToken: AccessToken | null;
  signedUser: MyUser | null;
  signedIn: boolean;

  currentTenant: number;
  userTenants: number[];
  roles: string[];
  isSU: boolean;

  mfaSecret: string | null;
  enabledComponents: IEnabledComponents;
}

export const initialState: InfoState = {
  accessToken: null,
  signedUser: null,
  signedIn: false,

  currentTenant: undefined,
  userTenants: [],
  roles: [],
  isSU: false,

  mfaSecret: null,
  enabledComponents: { autoDispatch: false },
};

export function infoReducer(state = initialState, action: Actions): InfoState {
  switch (action.type) {
    case LOGIN_SUCCESS: {
      const token: AccessToken = (action as LoginSuccess).payload.token;
      const tenants: number[] = (action as LoginSuccess).payload.tenants;
      // const roles: string[] = (action as LoginSuccess).payload.roles;
      const enabledComponents: IEnabledComponents = (action as LoginSuccess).payload.enabledComponents;

      return {
        ...state,
        accessToken: token,
        signedUser: new MyUser(token.user),
        signedIn: true,

        currentTenant: undefined,
        userTenants: tenants,
        roles: [],
        isSU: false,

        mfaSecret: '',
        enabledComponents,
      } as InfoState;
    }

    case LOGIN_WITH_MFA: {
      const token: any = (action as LoginWithMfa).payload.token;
      return {
        ...state,
        accessToken: null,
        signedUser: new MyUser(token.user),
        signedIn: false,

        currentTenant: undefined,
        mfaSecret: token.mfaSecret,
      } as InfoState;
    }

    case CHECK_AUTH_SUCCESS: {
      const token: AccessToken = (action as CheckAuthSuccess).payload.token;
      const currentTenant: number = (action as CheckAuthSuccess).payload.currentTenant;
      const tenants: number[] = (action as CheckAuthSuccess).payload.tenants;
      // const roles: string[] = (action as CheckAuthSuccess).payload.roles;
      const enabledComponents: IEnabledComponents = (action as CheckAuthSuccess).payload.enabledComponents;

      return {
        ...state,
        accessToken: token,
        signedUser: token ? new MyUser(token.user) : null,
        signedIn: !!token,

        currentTenant: token ? currentTenant : undefined,
        userTenants: token ? tenants : [],
        // roles: token ? roles : [],
        roles: [],
        isSU: false,
        enabledComponents,
      } as InfoState;
    }

    case LOGIN_FAIL:
    case CHECK_AUTH_FAIL: {
      return {
        ...state,
        accessToken: null,
        signedUser: null,
        signedIn: false,

        currentTenant: undefined,
        userTenants: [],
        roles: [],
        isSU: false,
      } as InfoState;
    }

    case LOGOUT_FAIL:
    case LOGOUT_SUCCESS: {
      return {
        ...state,
        accessToken: null,
        signedUser: null,
        signedIn: false,

        currentTenant: undefined,
        userTenants: [],
        roles: [],
        isSU: false,
      } as InfoState;
    }

    // case SET_TENANT_LOCAL: {
    //   const tenantId: number = (action as SetTenantLocal).payload;
    //
    //   return {
    //     ...state,
    //     currentTenant: tenantId,
    //   };
    // }

    case SET_TENANT_SUCCESS: {
      const tenantId: number = (action as SetTenantSuccess).payload;

      return {
        ...state,
        currentTenant: tenantId,
        roles: [],
        isSU: false,
      } as InfoState;
    }

    case SET_TENANT_FAIL: {
      return {
        ...state,
        currentTenant: undefined,
        roles: [],
        isSU: false,
      } as InfoState;
    }

    // case LOAD_ROLES: {
    //   return {
    //     ...state,
    //     // roles: [],
    //     // isSU: false,
    //   } as InfoState;
    // }

    case LOAD_ROLES_SUCCESS: {
      const roles = (action as LoadRolesSuccess).payload;
      const isSU = roles.includes('SU');

      const sameRoles = difference(roles, state.roles).length === 0 && difference(state.roles, roles).length === 0;

      return sameRoles
        ? state
        : ({
            ...state,
            roles,
            isSU,
          } as InfoState);
    }

    case LOAD_ROLES_FAIL: {
      return {
        ...state,
        roles: [],
        isSU: false,
      } as InfoState;
    }

    default: {
      return state;
    }
  }
}

export const infoGetAccessToken = (state: InfoState) => state.accessToken;
export const infoGetUser = (state: InfoState) => state.signedUser;
export const infoIsSignedIn = (state: InfoState) => state.signedIn;
export const infoGetMFASecret = (state: InfoState) => state.mfaSecret;

export const infoGetCurrentTenant = (state: InfoState) => state.currentTenant;
export const infoGetTenants = (state: InfoState) => state.userTenants;
export const infoGetRoles = (state: InfoState) => state.roles;
export const infoIsSU = (state: InfoState) => state.isSU;
export const infoGetEnabledComponents = (state: InfoState) => state.enabledComponents;
