import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import ArrayStore from 'devextreme/data/array_store';
import CustomStore from 'devextreme/data/custom_store';
import DataSource, { DataSourceOptions } from 'devextreme/data/data_source';
import notify from 'devextreme/ui/notify';
import { of } from 'rxjs';
import { catchError, finalize, map, takeUntil, tap } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import { LoopBackStoreOptions } from '../../../../shared/classes/loopback-custom-store/generic/store-options/LoopBackStoreOptions';
import { headersAllTenantsAppend, isAlpha } from '../../../../shared/classes/utils/utils';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import {
  Employee,
  EmployeeView,
  Facility,
  FuelCard,
  LoggerService,
  MyUser,
  MyUserApi,
  Vehicle,
} from '../../../../shared/sdk';
import { HelperService as EmployeeHelperService } from '../../../employee/services/helper.service';
import { DlgImportCardComponent } from '../dlg-import-card/dlg-import-card.component';

@Component({
  selector: 'app-fuel-cards-grid',
  templateUrl: './fuel-cards-grid.component.html',
  styleUrls: ['./fuel-cards-grid.component.scss'],
})
export class FuelCardsGridComponent extends ABaseComponent implements OnInit {
  accountNames = isAlpha() ? [] : ['MERCY AMBULANCE & EMS'];
  accountNumbers = isAlpha() ? [] : ['0496002692002'];

  ds: DataSource;

  grid_stateStoring: any;

  facilityDso: any;
  userDso$: any;
  employeeDso: any;
  vehicleDso: any;

  @ViewChild(DxDataGridComponent, { static: false }) grid: DxDataGridComponent;

  constructor(
    protected logger: LoggerService,
    public config: ConfigService,
    private dss: DataSourceService,
    private gridHelper: GridHelperService,
    public employeeHelper: EmployeeHelperService,
    private dialog: MatDialog,
    private common: CommonService,
  ) {
    super(logger);

    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: 'a5bac0af-5bea-45fb-bd41-f6fff3d4948e',
    };

    this.ds = this.buildDataSource();
    this.facilityDso = this.buildFacilityDataSource();
    this.userDso$ = this.buildUserDataSource();
    this.employeeDso = this.buildEmployeeDataSource();
    this.vehicleDso = this.buildVehicleDataSource();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  grid_onInitialized(e) {
    this.gridHelper.handle(e.component, {});
  }

  grid_onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      name: 'importCards',
      locateInMenu: 'auto',
      widget: 'dxButton',
      location: 'after',
      sortIndex: 30,
      showText: 'inMenu',
      options: {
        icon: 'fas fa-file-import',
        text: 'Import Cards',
        hint: 'Import Fuel Cards',
        onClick: this.grid_toolbar_importCards_onClick.bind(this),
      },
    });
  }

  private grid_toolbar_importCards_onClick(e) {
    this.dialog
      .open(DlgImportCardComponent, {
        hasBackdrop: true,
        disableClose: true,
        data: { jobName: 'IMPORT_WAWA_CARDS' },
      })
      .afterClosed()
      .pipe(
        tap(res => {
          if (res) oc(this.grid).instance.refresh()();
        }),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  private buildDataSource() {
    const so = this.dss.getStoreOptions(FuelCard, undefined, false) as LoopBackStoreOptions<any, any>;
    so.useRegExp = true;
    so.noSql = true;
    const store: CustomStore = new CustomStore(so);
    return new DataSource({ store } as DataSourceOptions);
  }

  private buildUserDataSource() {
    return this.dss
      .getApi<MyUserApi>(MyUser)
      .getUsernames()
      .pipe(map(arr => new ArrayStore(arr)));
  }

  private buildFacilityDataSource() {
    const store = this.dss.getStore(Facility);
    return {
      store,
      filter: ['type', 'inq', ['ADC', 'BASE']],
      sort: [{ selector: 'type' }, { selector: 'shortname' }],
    } as DataSourceOptions;
  }

  private buildEmployeeDataSource() {
    const so = this.dss.getStoreOptions(Employee, EmployeeView, false) as LoopBackStoreOptions<any, any>;
    so.customHeaders = headersAllTenantsAppend;

    return {
      store: new CustomStore(so),
      sort: [{ selector: 'facility_shortname' }, { selector: 'person_firstname' }, { selector: 'person_lastname' }],
    } as DataSourceOptions;
  }

  private buildVehicleDataSource() {
    const so = this.dss.getStoreOptions(Vehicle, undefined, false) as LoopBackStoreOptions<any, any>;
    so.customHeaders = headersAllTenantsAppend;

    return {
      store: new CustomStore(so),
      sort: [{ selector: 'internalId' }],
    } as DataSourceOptions;
  }

  empDisplayExpr(e: EmployeeView) {
    return [...[e.facility_shortname ? [`${e.facility_shortname}:`] : []], e.person_firstname, e.person_lastname].join(
      ' ',
    );
  }

  driverDropDown_onValueChanged(cellInfo, e) {
    cellInfo.setValue(e.value);
  }

  qrPrintClick = e => {
    this.grid.instance.beginCustomLoading('...');
    this.common
      .printSizedQr(e.row.data.cardNumber, [150, 150, 200, 200])
      .pipe(
        catchError(err => of(notify(err.message, 'error'))),
        finalize(() => this.grid.instance.endCustomLoading()),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  };
}
