import { Routes } from '@angular/router';
import { DashboardComponent } from './core/components/dashboard/dashboard.component';
import { EmptyComponent } from './core/containers/empty/empty.component';
import { LoadingComponent } from './core/containers/loading/loading.component';
import { NotFoundComponent } from './core/containers/not-found/not-found.component';
import { AlertComponent } from './modules/alert/containers/alert/alert.component';
import { BILLING_ROUTES } from './modules/billing/billing-routes';
import { AllConsumerGridComponent } from './modules/consumer/components/all-consumer-grid/all-consumer-grid.component';
import { CtsBrokerTripsComponent } from './modules/consumer/components/cts-broker-trips/cts-broker-trips.component';
import { CtsClaimsComponent } from './modules/consumer/components/cts-claims/cts-claims.component';
import { EligibilityGridComponent } from './modules/consumer/components/eligibility-grid/eligibility-grid.component';
import { HhaRecordsComponent } from './modules/consumer/components/hha-records/hha-records.component';
import { LgtcBrokerTripsComponent } from './modules/consumer/components/lgtc-broker-trips/lgtc-broker-trips.component';
import { LgtcClientsComponent } from './modules/consumer/components/lgtc-clients/lgtc-clients.component';
import { MtmBrokerTripsComponent } from './modules/consumer/components/mtm-broker-trips/mtm-broker-trips.component';
import { MtmClaimsComponent } from './modules/consumer/components/mtm-claims/mtm-claims.component';
import { BrokerTripsComponent } from './modules/consumer/containers/broker-trips/broker-trips.component';
import { ConsumerComponent } from './modules/consumer/containers/consumer/consumer.component';
import { DocumentTypeComponent } from './modules/document-type/containers/document-type/document-type.component';
import { DocumentComponent } from './modules/document/containers/document/document.component';
import { EmployeeComponent } from './modules/employee/containers/employee/employee.component';
import { FacilityComponent } from './modules/facility/containers/facility/facility.component';
import { FLEET_ROUTES } from './modules/fleet/fleet-routes';
import { ImportComponent } from './modules/import/containers/import/import.component';
import { LOG_ROUTES } from './modules/log/log-routes';
import { OPERATIONS_ROUTES } from './modules/operations/operations-routes';
import { MealsGenerateInvoiceComponent } from './modules/reports/components/meals-generate-invoice/meals-generate-invoice.component';
import { ReportsComponent } from './modules/reports/containers/reports/reports.component';
import { SERVICE_AUTHS_ROUTES } from './modules/service-authorization/service-authorization-routes';
import { SCHEDULE_ROUTES } from './modules/schedule/schedule-routes';
import { SETTINGS_ROUTES } from './modules/settings/settings-routes';
import { SignComponent } from './modules/sign/containers/sign/sign.component';
import { STATS_ROUTES } from './modules/stats/stats-routes';
import { SU_ROUTES } from './modules/su/su-routes';
import { USER_ROUTES } from './modules/users/users-routes';
import { VehicleBackupComponent } from './modules/vehicle-backup/containers/vehicle-backup/vehicle-backup.component';
import { VehicleHRCertsComponent } from './modules/vehicle-hr-certs/containers/vehicle-hr-certs/vehicle-hr-certs.component';
import { VehicleInMaintenanceComponent } from './modules/vehicle-in-maintenance/containers/vehicle-in-maintenance/vehicle-in-maintenance.component';
import { VehicleLocationComponent } from './modules/vehicle-location/containers/vehicle-location/vehicle-location.component';
import { VehicleUtilisation2Component } from './modules/vehicle-utilisation2/containers/vehicle-utilisation2/vehicle-utilisation2.component';
import { VehicleToFacilityComponent } from './modules/vehicle/components/vehicle-to-facility/vehicle-to-facility.component';
import { VehicleComponent } from './modules/vehicle/containers/vehicle/vehicle.component';
//
import { AuthGuard } from './shared/modules/my-common/guards/auth.guard';
import { DashboardGuard } from './shared/modules/my-common/guards/dashboard.guard';
import { PendingChangesGuard } from './shared/modules/my-common/guards/pending-changes.guard';
import { ConfigResolver } from './shared/modules/my-common/services/config-resolver.service';
import { DashboardCanLoad } from './shared/modules/my-common/services/dashboard-can-load.service';
import { TestComponent } from './shared/modules/ui/components/test/test.component';
import { EmployeeExtComponent } from './modules/employee/containers/employee-ext/employee-ext.component';
import { EmployeePerfComponent } from './modules/employee/containers/employee-perf/employee-perf.component';
import { EmployeeHRCertsComponent } from './modules/employee/containers/employee-hr-certs/employee-hr-certs.component';
import { EmployeeFleetComponent } from './modules/employee/containers/employee-fleet/employee-fleet.component';
import { EmployeePositionComponent } from './modules/employee/containers/employee-position/employee-position.component';
import { EmployeeWorkingTimeGridComponent } from './modules/employee/components/employee-working-time/employee-working-time-grid/employee-working-time-grid.component';
const ɵ0 = { mode: 'OLD' }, ɵ1 = {}, ɵ2 = {};
const routes = [
    { path: 'sign', component: SignComponent },
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard],
        resolve: { config: ConfigResolver },
        canLoad: [DashboardCanLoad],
        children: [
            { path: 'alerts', component: AlertComponent, canActivate: [AuthGuard] },
            { path: 'facilities', component: FacilityComponent, canActivate: [AuthGuard] },
            { path: 'employees', component: EmployeeComponent, canActivate: [AuthGuard] },
            { path: 'employees-ext', component: EmployeeExtComponent, canActivate: [AuthGuard] },
            { path: 'employees-perf', component: EmployeePerfComponent, canActivate: [AuthGuard] },
            { path: 'employee-docs', component: EmployeeHRCertsComponent, canActivate: [AuthGuard] },
            { path: 'employee-working-hours', component: EmployeeWorkingTimeGridComponent, canActivate: [AuthGuard] },
            { path: 'vehicles', component: VehicleComponent, canActivate: [AuthGuard] },
            { path: 'vehicles-backup', component: VehicleBackupComponent, canActivate: [AuthGuard] },
            { path: 'vehicles-in-maintenance', component: VehicleInMaintenanceComponent, canActivate: [AuthGuard] },
            { path: 'vehicles-locations', component: VehicleLocationComponent, canActivate: [AuthGuard] },
            { path: 'vehicle-docs', component: VehicleHRCertsComponent, canActivate: [AuthGuard] },
            { path: 'vehicle-utilisation', component: VehicleUtilisation2Component, canActivate: [AuthGuard] },
            { path: 'drivers-info', component: EmployeeFleetComponent, canActivate: [AuthGuard] },
            {
                path: 'consumers',
                component: ConsumerComponent,
                canActivate: [AuthGuard],
                canDeactivate: [PendingChangesGuard],
            },
            {
                path: 'all-consumers',
                component: AllConsumerGridComponent,
                canActivate: [AuthGuard],
                canDeactivate: [PendingChangesGuard],
            },
            {
                path: 'eligibility',
                component: EligibilityGridComponent,
                canActivate: [AuthGuard],
                canDeactivate: [PendingChangesGuard],
            },
            {
                path: 'assign-vehicle',
                component: VehicleToFacilityComponent,
                canActivate: [AuthGuard],
                canDeactivate: [PendingChangesGuard],
            },
            {
                path: 'lgtc-clients',
                component: LgtcClientsComponent,
                canActivate: [AuthGuard],
                canDeactivate: [PendingChangesGuard],
            },
            {
                path: 'broker-trips',
                component: BrokerTripsComponent,
                canActivate: [AuthGuard],
                children: [
                    {
                        path: 'mtm',
                        component: MtmBrokerTripsComponent,
                        canActivate: [AuthGuard],
                        canDeactivate: [PendingChangesGuard],
                    },
                    {
                        path: 'cts',
                        component: CtsBrokerTripsComponent,
                        canActivate: [AuthGuard],
                        canDeactivate: [PendingChangesGuard],
                    },
                    {
                        path: 'lgtc',
                        component: LgtcBrokerTripsComponent,
                        canActivate: [AuthGuard],
                        canDeactivate: [PendingChangesGuard],
                    },
                    { path: '', redirectTo: 'mtm', pathMatch: 'prefix' },
                    { path: '**', component: NotFoundComponent },
                ],
            },
            {
                path: 'broker-claims',
                component: BrokerTripsComponent,
                canActivate: [AuthGuard],
                children: [
                    {
                        path: 'mtm-claims',
                        component: MtmClaimsComponent,
                        canActivate: [AuthGuard],
                        canDeactivate: [PendingChangesGuard],
                    },
                    {
                        path: 'cts-claims',
                        component: CtsClaimsComponent,
                        canActivate: [AuthGuard],
                        canDeactivate: [PendingChangesGuard],
                    },
                    { path: '', redirectTo: 'mtm-claims', pathMatch: 'prefix' },
                    { path: '**', component: NotFoundComponent },
                ],
            },
            {
                path: 'hha-records',
                component: HhaRecordsComponent,
                canActivate: [AuthGuard],
                canDeactivate: [PendingChangesGuard],
            },
            { path: 'documents', component: DocumentComponent, canActivate: [AuthGuard] },
            { path: 'document-types', component: DocumentTypeComponent, canActivate: [AuthGuard] },
            { path: 'employee-positions', component: EmployeePositionComponent, canActivate: [AuthGuard] },
            ...LOG_ROUTES,
            ...SETTINGS_ROUTES,
            ...SCHEDULE_ROUTES,
            ...USER_ROUTES,
            ...BILLING_ROUTES,
            ...SERVICE_AUTHS_ROUTES,
            ...STATS_ROUTES,
            ...FLEET_ROUTES,
            ...OPERATIONS_ROUTES,
            ...SU_ROUTES,
            {
                path: 'import',
                component: ImportComponent,
                canActivate: [AuthGuard],
                canDeactivate: [PendingChangesGuard],
            },
            { path: 'old-reports', component: ReportsComponent, data: ɵ0, canActivate: [AuthGuard] },
            { path: 'reports', component: ReportsComponent, data: ɵ1, canActivate: [AuthGuard] },
            { path: 'meals-gen-invoice', component: MealsGenerateInvoiceComponent, data: ɵ2, canActivate: [AuthGuard] },
            { path: 'empty', component: EmptyComponent },
            { path: 'loading', component: LoadingComponent },
            { path: 'default', component: EmptyComponent, canActivate: [DashboardGuard] },
            { path: '', pathMatch: 'prefix', redirectTo: 'default' },
            { path: '**', component: NotFoundComponent },
        ],
    },
    { path: 'test', component: TestComponent },
    { path: 'empty', component: EmptyComponent },
    { path: 'loading', component: LoadingComponent },
    { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
    { path: '**', component: NotFoundComponent },
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2 };
