
declare var Object: any;
export interface LastFileViewInterface {
  "id": number;
  "documentId": number;
  "container": string;
  "dtime"?: Date;
  "dtime_str"?: string;
  "filename": string;
  "originalFilename": string;
  "uploadedAt": Date;
  "uploadedById": number;
}

export class LastFileView implements LastFileViewInterface {
  "id": number;
  "documentId": number;
  "container": string;
  "dtime": Date;
  "dtime_str": string;
  "filename": string;
  "originalFilename": string;
  "uploadedAt": Date;
  "uploadedById": number;
  constructor(data?: LastFileViewInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `LastFileView`.
   */
  public static getModelName() {
    return "LastFileView";
  }

  /**
  * This method creates an instance of LastFileView for dynamic purposes.
  **/
  public static factory(data: LastFileViewInterface): LastFileView{
    return new LastFileView(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'LastFileView',
      plural: 'LastFileViews',
      path: 'LastFileViews',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'number'
        },
        "documentId": {
          name: 'documentId',
          type: 'number'
        },
        "container": {
          name: 'container',
          type: 'string'
        },
        "dtime": {
          name: 'dtime',
          type: 'Date'
        },
        "dtime_str": {
          name: 'dtime_str',
          type: 'string'
        },
        "filename": {
          name: 'filename',
          type: 'string'
        },
        "originalFilename": {
          name: 'originalFilename',
          type: 'string'
        },
        "uploadedAt": {
          name: 'uploadedAt',
          type: 'Date'
        },
        "uploadedById": {
          name: 'uploadedById',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
