import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import notify from 'devextreme/ui/notify';
//
import { LoggerService, MyUser, MyUserView, MyUserViewApi, MyUtils, MyUtilsApi } from '../../../../shared/sdk';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { HelperService as EmployeeHelperService } from '../../../employee/services/helper.service';
import { gqlMongoLoad } from 'src/app/shared/classes/loopback-custom-store/generic/store.utils';
import { intersection } from 'lodash-es';

@Component({
  selector: 'app-notifications-form',
  templateUrl: './notifications-form.component.html',
  styleUrls: ['./notifications-form.component.scss'],
  providers: [EmployeeHelperService],
})
export class NotificationsFormComponent extends ABaseComponent {
  processing = false;
  form: FormGroup;

  usersDso$ = [];

  private formSettingsMap: Map<string, any> = new Map<string, any>();
  private defaultData: any;

  constructor(
    protected logger: LoggerService,
    protected common: CommonService,
    protected fb: FormBuilder,
    protected dss: DataSourceService,
    public config: ConfigService,
    public employeeHelper: EmployeeHelperService,
  ) {
    super(logger);
    this.buildDataSource();
    this.buildForm();
    this.loadSettings();
  }

  displayExpr = (u: MyUser): string => `${this.employeeHelper.displayExpr(u as any) || u.username} - ${u.email}`;

  form_ngSubmit(e): boolean {
    e.preventDefault();

    void (async () => {
      this.processing = true;
      if (this.form.valid) {
        const data = this.form.value;
        await this.dss.getApi<MyUtilsApi>(MyUtils).mongoSaveNotificationSettings(data).toPromise();
        this.form.markAsPristine();
        notify('Done!', 'success');
      } else {
        notify('There are not valid fields', 'warning', 5000);
      }
    })()
      .catch(err => notify(err.message, 'error', 5000))
      .finally(() => (this.processing = false));
    return false;
  }

  reset_onClick(e): void {
    setTimeout(() => this.form.reset(this.defaultData));
  }

  private buildForm() {
    this.formSettingsMap.set('', {
      opsAlertUsers: [],
      opsAlertUsersIndividual: [],
      opsAlertUsersIndividualSms: [],
      opsAlertUsersCheckedInWithOtherVehicle: [],
      opsAlertUsersNewClientAutoCreated: [],
      opsAlertUsersMinutesLate: [],
      opsAlertUsersIndividualMinutesLate: [],
      fleetStatusReportUsers: [],
      fleetBackupReportUsers: [],
      fleetMaintenanceReportUsers: [],
      hrNotificationUsers: [],
      unresolvedEtdIssuesUsers: [],
      vehicleStatusChangeNotificationUsers: [],
      vehicleBackup2AssignedAutoChangeNotificationUsers: [],
    });
    this.form = this.fb.group(this.formSettingsMap.get(''));
    this.defaultData = this.form.value;
  }

  private async buildDataSource() {
    const users = await this.dss
      .getApi<MyUserViewApi>(MyUserView)
      .find({ where: { status: 'ACTIVE' }, order: ['person_firstname ASC', 'person_lastname ASC', 'username ASC'] })
      .toPromise();
    this.usersDso$ = users.filter(({ _roles }) => intersection(['SU', 'CLERK', 'MANAGER', 'BILLER'], _roles).length);
  }

  private async loadSettings() {
    const settings = (await gqlMongoLoad(this.dss, 'NotificationSettings', {}, []).pipe().toPromise())[0] || {};
    this.form.get('opsAlertUsers').setValue(settings.opsAlertUsers);
    this.form.get('opsAlertUsersIndividual').setValue(settings.opsAlertUsersIndividual);
    this.form.get('opsAlertUsersIndividualSms').setValue(settings.opsAlertUsersIndividualSms);
    this.form.get('opsAlertUsersCheckedInWithOtherVehicle').setValue(settings.opsAlertUsersCheckedInWithOtherVehicle);
    this.form.get('opsAlertUsersNewClientAutoCreated').setValue(settings.opsAlertUsersNewClientAutoCreated);
    this.form.get('opsAlertUsersMinutesLate').setValue(settings.opsAlertUsersMinutesLate);
    this.form.get('opsAlertUsersIndividualMinutesLate').setValue(settings.opsAlertUsersIndividualMinutesLate);
    this.form.get('fleetStatusReportUsers').setValue(settings.fleetStatusReportUsers);
    this.form.get('fleetBackupReportUsers').setValue(settings.fleetBackupReportUsers);
    this.form.get('fleetMaintenanceReportUsers').setValue(settings.fleetMaintenanceReportUsers);
    this.form.get('hrNotificationUsers').setValue(settings.hrNotificationUsers);
    this.form.get('unresolvedEtdIssuesUsers').setValue(settings.unresolvedEtdIssuesUsers);
    this.form.get('vehicleStatusChangeNotificationUsers').setValue(settings.vehicleStatusChangeNotificationUsers);
    this.form
      .get('vehicleBackup2AssignedAutoChangeNotificationUsers')
      .setValue(settings.vehicleBackup2AssignedAutoChangeNotificationUsers);
  }
}

