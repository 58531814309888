import { Location } from '@angular/common';
import { ApplicationRef, Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { getCurrentScope } from '@sentry/browser';
import { combineLatest, concat, defer, iif, Observable, of, throwError } from 'rxjs';
import { catchError, delay, exhaustMap, first, map, switchMap, tap } from 'rxjs/operators';
import { ExtLoopBackAuth } from '../../shared/modules/ext-sdk/services/ext-sdk-auth.service';
import { CommonService } from '../../shared/modules/my-common/services/common.service';
import { FacilityApi, LoggerService, LoopBackAuth, MyUser, MyUserApi } from '../../shared/sdk';
import { HideLoadingPanel, LoadConfig, LoadEnvVars, ShowLoadingPanel } from '../actions/core';
import {
  CHECK_AUTH,
  CHECK_AUTH_FAIL,
  CHECK_AUTH_SUCCESS,
  CheckAuth,
  CheckAuthFail,
  CheckAuthSuccess,
  LOAD_ROLES_SUCCESS,
  LoadRoles,
  LoadRolesSuccess,
  Login,
  LOGIN,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGIN_WITH_MFA,
  LoginFail,
  LoginSuccess,
  LoginWithMfa,
  LOGOUT,
  LOGOUT_FAIL,
  LOGOUT_SUCCESS,
  LogoutFail,
  LogoutSuccess,
  SET_TENANT,
  SET_TENANT_FAIL,
  SET_TENANT_SUCCESS,
  SetTenant,
  SetTenantFail,
  SetTenantSuccess,
} from '../actions/sign';

@Injectable()
export class SignEffects {
  @Effect({ dispatch: false })
  init$ = defer(() => this.appRef.isStable).pipe(
    first(isStable => isStable === true),
    delay(1), // wait for load all effects
    tap(() => {
      this.common.store.dispatch(new CheckAuth());
    }),
  );

  @Effect()
  check$ = this.actions$.pipe(
    ofType(CHECK_AUTH),
    switchMap(g =>
      iif(
        () => this.userApi.isAuthenticated(),
        of(this.auth.getToken()).pipe(
          exhaustMap(token =>
            combineLatest([
              of(token),
              of(this.auth.getCurrentTenant() || null),
              this.userApi.getTenants(token.userId) as Observable<number[]>,
              // this.userApi.getRoles(token.userId) as Observable<string[]>,
              this.userApi.getEnabledComponents(token.userId),
            ]),
          ),
        ),
        throwError('User not authenticated'),
      ).pipe(
        map(
          ([
            token,
            currentTenant,
            tenants,
            // roles,
            enabledComponents,
          ]) =>
            new CheckAuthSuccess({
              token,
              currentTenant,
              tenants,
              // roles,
              enabledComponents,
            }),
        ),
        catchError(err => of(new CheckAuthFail(err))),
      ),
    ),
  );

  @Effect()
  login$ = this.actions$.pipe(
    ofType(LOGIN),
    map((action: Login) => action.payload),
    tap(() => {
      this.auth.setCurrentTenant(null);
      // this.common.store.dispatch(new SetTenantLocal(null));
    }),
    exhaustMap((user: MyUser) =>
      this.userApi.login(user).pipe(
        switchMap((token: any) =>
          token.mfaSecret
            ? of([token])
            : combineLatest([
                of(token),
                this.userApi.getTenants(token.userId) as Observable<number[]>,
                // this.userApi.getRoles(token.userId) as Observable<string[]>,
                this.userApi.getEnabledComponents(token.userId),
              ]),
        ),
        map(
          ([
            token,
            tenants,
            // roles,
            enabledComponents,
          ]) =>
            token.mfaSecret
              ? new LoginWithMfa({ token })
              : new LoginSuccess({
                  token,
                  tenants,
                  // roles,
                  enabledComponents,
                }),
        ),
        catchError(err => of(new LoginFail(err))),
      ),
    ),
  );

  @Effect()
  logout$ = this.actions$.pipe(
    ofType(LOGOUT),
    exhaustMap(() =>
      concat(this.userApi.logout(), this.router.navigate(['/sign'])).pipe(
        map(() => new LogoutSuccess()),
        catchError(err => of(new LogoutFail(err))),
      ),
    ),
  );

  @Effect({ dispatch: false })
  loginSuccess$ = this.actions$.pipe(
    ofType(LOGIN_SUCCESS),
    map((action: LoginSuccess) => action.payload),
    // tap(() => this.common.store.dispatch(new ShowLoadingPanel())),
    // tap(({token, roles, tenants}) => {}),
    // tap(() => this.common.store.dispatch(new HideLoadingPanel())),
  );

  @Effect({ dispatch: false })
  loginWithMfa$ = this.actions$.pipe(
    ofType(LOGIN_WITH_MFA),
    map((action: LoginWithMfa) => action.payload),
  );

  @Effect({ dispatch: false })
  checkSuccess$ = this.actions$.pipe(
    ofType(CHECK_AUTH_SUCCESS),
    map((action: CheckAuthSuccess) => action.payload),
    // tap(({token, currentTenant, roles, tenants}) => {
    //   // if (payload.currentTenant) {
    //   //   return new sign.SetTenant(payload.currentTenant);
    //   // } else if (payload.tenants.length === 1) {
    //   //   return new sign.SetTenant(payload.tenants[0]);
    //   // }
    // }),
  );

  @Effect({ dispatch: false })
  checkFail$ = this.actions$.pipe(
    ofType(CHECK_AUTH_FAIL),
    tap(() => this.auth.clear()),
    map((action: CheckAuthFail) => action.payload),
    // tap(() => this.router.navigate(['/sign'])),
  );

  @Effect({ dispatch: false })
  loginFail$ = this.actions$.pipe(
    ofType(LOGIN_FAIL),
    tap(() => this.auth.clear()),
    map((action: LoginFail) => action.payload),
    // tap(() => this.router.navigate(['/sign'])),
  );

  @Effect({ dispatch: false })
  logoutFinally$ = this.actions$.pipe(
    ofType(LOGOUT_SUCCESS, LOGOUT_FAIL),
    tap(() => this.auth.clear()),
    tap(() => this.common.store.dispatch(new ShowLoadingPanel())),
    switchMap(async () => this.router.navigate(['/sign'])),
    tap(() => this.common.store.dispatch(new HideLoadingPanel())),
  );

  @Effect({ dispatch: false })
  finally$ = this.actions$.pipe(
    ofType(
      CHECK_AUTH_SUCCESS,
      CHECK_AUTH_FAIL,

      LOGIN_SUCCESS,
      LOGIN_FAIL,

      LOGOUT_SUCCESS,
      LOGOUT_FAIL,

      SET_TENANT_SUCCESS,
      SET_TENANT_FAIL,
    ),
    tap(action => {
      if (this.userApi.isAuthenticated()) {
        const user: MyUser = this.auth.getCurrentUserData();

        getCurrentScope().setUser({
          id: '' + user.id,
          email: user.email,
          username: user.username,
        });
      } else {
        getCurrentScope().setUser(null);
      }
    }),
    delay(1), // wait for each action consumer processed
    tap(action => {
      this.common.store.dispatch(new LoadRoles());
      this.common.store.dispatch(new LoadEnvVars());
      this.common.store.dispatch(new LoadConfig());
    }),
    // tap((action) => this.afterAuth(action)),
  );

  @Effect()
  setTenant$ = this.actions$.pipe(
    ofType(SET_TENANT),
    map((action: SetTenant) => action.payload),
    exhaustMap((tenantId: number) =>
      this.facilityApi.setTenant(tenantId).pipe(
        // delay(1),
        map(() => new SetTenantSuccess(tenantId)),
        catchError(err => of(new SetTenantFail(err))),
      ),
    ),
    // map((tenantId) => {
    //   this.auth.setCurrentTenant(tenantId);
    //   return new SetTenantSuccess(tenantId);
    // }),
  );

  // @Effect({dispatch: false})
  // setTenantLocal$ = this.actions$.pipe(
  //   ofType(SET_TENANT_LOCAL),
  //   map((action: SetTenantLocal) => action.payload),
  //   tap(tenantId => {
  //     this.auth.setCurrentTenant(tenantId);
  //   }),
  // );

  @Effect({ dispatch: false })
  setTenantSuccess$ = this.actions$.pipe(
    ofType(SET_TENANT_SUCCESS),
    map((action: SetTenantSuccess) => action.payload),
    tap(tenantId => {
      // this.common.store.dispatch(new SetTenantLocal(payload.tenantId));
      this.auth.setCurrentTenant(tenantId);
    }),
    switchMap(tenantId =>
      this.actions$.pipe(
        ofType<LoadRolesSuccess>(LOAD_ROLES_SUCCESS), //
        first(),
      ),
    ),
    map(action => {
      // console.log('SetTenantSuccess roles', action.payload);

      if (this.router.isActive('/sign', false)) {
        return this.router.navigate(['/dashboard']);
      } else {
        return this.router.navigate(['/dashboard']);
      }
    }),
  );

  @Effect({ dispatch: false })
  setTenantFail$ = this.actions$.pipe(
    ofType(SET_TENANT_FAIL),
    map((action: SetTenantFail) => action.payload),
    tap(() => {
      // this.common.store.dispatch(new SetTenantLocal(null));
      this.auth.setCurrentTenant(null);
    }),
  );

  constructor(
    private appRef: ApplicationRef,
    private actions$: Actions,
    private router: Router,
    private location: Location,
    @Inject(LoopBackAuth) private auth: ExtLoopBackAuth,
    private logger: LoggerService,
    private common: CommonService,
    private userApi: MyUserApi,
    private facilityApi: FacilityApi,
  ) {}

  // private async afterAuth(action: Action) {
  //   // if (this.common.rt.connection.isConnected())
  //   //   this.common.rt.connection.disconnect();
  //   //
  //   // if (this.userApi.isAuthenticated()) {
  //   //   this.common.rt.onReady();
  //   //   this.common.store.dispatch(new LoadConfig());
  //   // }
  // }
}
