
declare var Object: any;
export interface ByEmployeeSignatureStatsInterface {
  "id"?: string;
  "count": number;
  "employeeId"?: number;
  "tenantId": number;
  "vdate"?: any;
  "weekday"?: number;
}

export class ByEmployeeSignatureStats implements ByEmployeeSignatureStatsInterface {
  "id": string;
  "count": number;
  "employeeId": number;
  "tenantId": number;
  "vdate": any;
  "weekday": number;
  constructor(data?: ByEmployeeSignatureStatsInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `ByEmployeeSignatureStats`.
   */
  public static getModelName() {
    return "ByEmployeeSignatureStats";
  }

  /**
  * This method creates an instance of ByEmployeeSignatureStats for dynamic purposes.
  **/
  public static factory(data: ByEmployeeSignatureStatsInterface): ByEmployeeSignatureStats{
    return new ByEmployeeSignatureStats(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ByEmployeeSignatureStats',
      plural: 'ByEmployeeSignatureStats',
      path: 'ByEmployeeSignatureStats',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'string'
        },
        "count": {
          name: 'count',
          type: 'number'
        },
        "employeeId": {
          name: 'employeeId',
          type: 'number'
        },
        "tenantId": {
          name: 'tenantId',
          type: 'number'
        },
        "vdate": {
          name: 'vdate',
          type: 'any'
        },
        "weekday": {
          name: 'weekday',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
