import { ChangeDetectorRef, Component, Inject, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseModelLoaderComponent } from '../../../../shared/modules/ui/components/abstract/a-base-model-loader.component';
import {
  Config,
  ConfigApi,
  DriverSchedule,
  DriverScheduleApi,
  Employee,
  EmployeeApi,
  EmployeeWorkingTime,
  EmployeeWorkingTimeApi,
  LoggerService,
  LoopBackAuth,
  TripManifest,
  Vehicle,
  VehicleApi,
} from '../../../../shared/sdk';
import { HelperService as ConsumerHelperService } from '../../../consumer/services/helper.service';
import { HelperService as EmployeeHelperService } from '../../../employee/services/helper.service';
import { HelperService } from '../../services/helper.service';
import { ExtLoopBackAuth } from 'src/app/shared/modules/ext-sdk/services/ext-sdk-auth.service';
import { asShortDate } from 'src/app/shared/classes/utils/time.utils';
import { RouterHelperService, ITotals } from '../../services/router-helper.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UiService } from 'src/app/shared/modules/ui/services/ui.service';
import { gqlMongoLoad } from 'src/app/shared/classes/loopback-custom-store/generic/store.utils';
import { MatDialog } from '@angular/material/dialog';
import { DlgRoutesMapComponent } from './dlg-routes-map/dlg-routes-map.component';
import moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';
import { headersAllTenantsAppend } from 'src/app/shared/classes/utils/utils';
import { DlgOverwriteManifestConfirm } from './dlg-overwrite-manifest-confirm/dlg-overwrite-manifest-confirm.component';
import { DlgEmployeeWorkingTimeComponent } from 'src/app/modules/employee/components/employee-working-time/dlg-employee-working-time/dlg-employee-working-time.component';

@Component({
  selector: 'app-router',
  templateUrl: './router.component.html',
  styleUrls: ['./router.component.scss'],
  providers: [HelperService, RouterHelperService, ConsumerHelperService, EmployeeHelperService],
})
export class RouterComponent extends ABaseModelLoaderComponent<Employee> implements OnInit, OnChanges, OnDestroy {
  destinationsMap: any = {};
  selectedDate: Date = new Date();
  vehicles: Vehicle[] = [];
  vehiclesAvailable: Vehicle[] = [];
  manifestVehiclesMap: any = {};
  vehiclesMap: any = {};
  selectedVehicleIds: number[] = [];
  employees: Employee[] = [];
  employeesAvailable: Employee[] = [];
  employeeMap: any = {};
  employeeWorkingTimeMap: any = {};
  selectedEmployeeIdsSet: Set<number> = new Set();
  keepManifestTrips = false;
  numberOfTripsLost = 0;
  manifest: TripManifest;
  manifestGroups: any[] = [];
  manifestGroupsOrg: any[] = [];
  manifestTripsMap: any = {};
  conflictTripsMap: any = {};
  dataSource: any[] = [];
  dataSourceOrder = 'least';
  showWorkTime = false;
  lockSelectedTrips = false;
  totals: { manifest: ITotals; proposed: ITotals } = {
    manifest: { totalVehicles: 0, combinedLoadedMinutes: 0, totalNumberOfTrips: 0 },
    proposed: { totalVehicles: 0, combinedLoadedMinutes: 0, totalNumberOfTrips: 0 },
  };

  constructor(
    private snackBar: MatSnackBar,
    protected cd: ChangeDetectorRef,
    protected logger: LoggerService,
    public config: ConfigService,
    protected dss: DataSourceService,
    private ui: UiService,
    public helper: HelperService,
    public router: RouterHelperService,
    public consumerHelper: ConsumerHelperService,
    public employeeHelper: EmployeeHelperService,
    protected dialog: MatDialog,
    protected sanitizer: DomSanitizer,
    @Inject(LoopBackAuth) protected auth: ExtLoopBackAuth,
  ) {
    super(logger, dss);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.loadData();
  }

  protected get ModelClass(): any {
    return Employee;
  }

  async calendar_onValueChanged(e) {
    this.ui.showLoading();
    await this.loadManigest();
    await this.loadEmployeeWorkingTime();
    this.router.sortDataSource(this.dataSource, this.dataSourceOrder, this.employeeWorkingTimeMap);
    this.ui.hideLoading();
  }

  getConsumerFullName = c => this.consumerHelper.displayExpr(c.__consumer);

  getConsumerSettings = ({ __consumer: c }) => {
    // debugger;
    let settings = [];
    if (c.keepStretcher) settings.push('KS');
    if (c.onBoardingDuration) settings.push('PU+');
    if (c.offBoardingDuration) settings.push('DO+');
    return settings.join(', ');
  };

  getHours(totalMinutes) {
    if (!totalMinutes) return '00:00';
    let hh = Math.floor(totalMinutes / 60);
    let mm = totalMinutes % 60;
    return `${hh.toString().padStart(2, '0')}:${mm.toString().padStart(2, '0')}`;
  }

  distanceInMiles = (distance: number) => (distance && Math.round(distance * 0.000621371192)) || 0;

  selectedVehiclesChangeHandler(e) {
    const set = new Set(this.selectedVehicleIds);
    this.vehiclesAvailable = this.vehicles.filter(v => !set.has(v.id));
    this.dataSource.forEach(d => {
      if (d.manifestGroup && d.manifestGroup.vehicle && !set.has(d.manifestGroup.vehicle.id)) {
        d.manifestGroup.vehicle = null;
        d.manifestGroup.employee = null;
      }
      if (d.proposedGroup && d.proposedGroup.vehicle && !set.has(d.proposedGroup.vehicle.id)) {
        d.proposedGroup.vehicle = null;
        d.proposedGroup.employee = null;
      }
    });
  }

  assignVehicleHandler(group, altGroup, value) {
    if (!this.selectedVehicleIds.includes(value)) {
      this.selectedVehicleIds.push(value);
    }
    group.vehicle = this.vehiclesMap[value];
    if (altGroup) altGroup.vehicle = this.vehiclesMap[value];
  }

  assignEmployeeHandler(group, altGroup, value) {
    this.selectedEmployeeIdsSet.add(value);
    group.employee = this.employeeMap[value];
    if (altGroup) altGroup.employee = this.employeeMap[value];
    this.setEmployeesAvailable();
  }

  assignEscortHandler(group, altGroup, value) {
    this.selectedEmployeeIdsSet.add(value);
    group.escort = this.employeeMap[value];
    if (altGroup) altGroup.escort = this.employeeMap[value];
    this.setEmployeesAvailable();
  }

  removeVehicleHandler(group, altGroup) {
    const idx = this.selectedVehicleIds.indexOf(group.vehicle.id);
    if (idx !== -1) this.selectedVehicleIds.splice(idx, 1);
    group.vehicle = null;
    if (altGroup) altGroup.vehicle = null;
  }

  removeEmployeeHandler(group, altGroup) {
    this.selectedEmployeeIdsSet.delete(group.employee.id);
    group.employee = null;
    if (altGroup) altGroup.employee = null;
    this.setEmployeesAvailable();
  }

  removeEscortHandler(group, altGroup) {
    this.selectedEmployeeIdsSet.delete(group.escort.id);
    group.escort = null;
    if (altGroup) altGroup.escort = null;
    this.setEmployeesAvailable();
  }

  getSchedulingConflicts = () => Object.keys(this.conflictTripsMap).length;

  shouldShowKeepManifestTrips(): boolean {
    return !this.manifestGroups.every(g => g.vehicle);
  }

  async loadData() {
    this.ui.showLoading();
    this.destinationsMap = await this.dss.getApi<ConfigApi>(Config).getDestinationsMap().toPromise();
    this.vehicles = (await this.dss
      .getApi<VehicleApi>(Vehicle)
      .find({ where: { state: 'ACTIVE' }, order: 'internalId' })
      .toPromise()) as Vehicle[];
    this.employees = (
      await this.dss
        .getApi<EmployeeApi>(Employee)
        .find<Employee>(
          { where: { employeePositionId: { inq: [39, 40, 273] } }, include: ['person'] },
          headersAllTenantsAppend,
        )
        .toPromise()
    ).sort((a, b) => {
      const a1 = this.employeeHelper.displayExpr(a);
      const b1 = this.employeeHelper.displayExpr(b);
      return a1 < b1 ? -1 : a1 > b1 ? 1 : 0;
    });

    this.vehiclesMap = this.vehicles.reduce((p, v) => ({ ...p, [v.id]: v }), {});
    this.employeeMap = this.employees.reduce((p, e) => ({ ...p, [e.id]: e }), {});
    await this.loadManigest();
    await this.loadEmployeeWorkingTime();
    this.router.sortDataSource(this.dataSource, this.dataSourceOrder, this.employeeWorkingTimeMap);
    this.ui.hideLoading();
  }

  async loadManigest() {
    this.manifest = await this.helper.api.getCurrentManifest(asShortDate(this.selectedDate)).toPromise();
    const as: any = await this.helper.buildArrayStoreAsync(this.manifest);
    const detailedTrips = as._array;
    this.manifestGroups = this.router.makeManifestGroups(detailedTrips);
    this.manifestGroupsOrg = JSON.parse(JSON.stringify(this.manifestGroups));
    this.makeManifestTripsMap();
    this.manifestVehiclesMap = this.manifestGroups.reduce((p, { vehicle: v }) => (v ? { ...p, [v.id]: v } : p), {});
    this.vehiclesAvailable = this.vehicles.filter(v => !this.manifestVehiclesMap[v.id]);
    this.selectedVehicleIds = this.manifestGroups.map(g => g.vehicle && g.vehicle.id).filter(id => id);
    this.selectedEmployeeIdsSet = new Set(
      this.manifestGroups.flatMap(g => [g.employee && g.employee.id, g.escort && g.escort.id]),
    );
    this.setEmployeesAvailable();
    this.conflictTripsMap = {};
    this.dataSource = this.manifestGroups.map(g => ({ manifestGroup: g }));
    this.totals.manifest = this.router.calculateTotals(this.manifestGroups);
    this.totals.proposed = { totalVehicles: 0, combinedLoadedMinutes: 0, totalNumberOfTrips: 0 };
  }

  async loadEmployeeWorkingTime() {
    const date = asShortDate(this.selectedDate);
    const data: any[] = await this.dss
      .getApi<EmployeeWorkingTimeApi>(EmployeeWorkingTime)
      .findAllByDate(date, headersAllTenantsAppend)
      .toPromise();
    this.employeeWorkingTimeMap = data.reduce((p, v) => ({ ...p, [v.employeeId]: v }), {});
  }

  makeManifestTripsMap() {
    this.manifestTripsMap = {};
    this.manifestGroups.forEach(g => (g.trips || []).forEach(t => (this.manifestTripsMap[t.id] = true)));
    this.numberOfTripsLost = this.manifestGroupsOrg.reduce(
      (p, g) => p + g.trips.reduce((p, t) => p + (this.manifestTripsMap[t.id] ? 0 : 1), 0),
      0,
    );
  }
  setEmployeesAvailable() {
    this.employeesAvailable = this.employees.filter(e => !this.selectedEmployeeIdsSet.has(e.id));
  }
  sortDataSourceHandler(e) {
    this.router.sortDataSource(this.dataSource, this.dataSourceOrder, this.employeeWorkingTimeMap);
  }

  async proposeHandler() {
    this.ui.showLoading();
    try {
      const selectedVehicleIdsSet = new Set(this.selectedVehicleIds);
      const keepManifestTrips = this.shouldShowKeepManifestTrips() && this.keepManifestTrips;

      const [tripsPool, vehicles] = this.router.makeTripsPool(
        this.manifest.data,
        this.manifestGroups,
        keepManifestTrips,
        selectedVehicleIdsSet,
      );

      const proposedGroups = await this.router.proposeGroups(
        tripsPool,
        this.selectedVehicleIds.length,
        true,
        vehicles,
        asShortDate(this.selectedDate),
        this.dss,
      );

      proposedGroups.sort((a, b) => b.workingMinutes - a.workingMinutes);
      let dataSrc = this.router.sortDataSource(
        [...this.manifestGroups].map((manifestGroup, i) => ({ manifestGroup })),
        'least',
        this.employeeWorkingTimeMap,
      );

      dataSrc = this.router.assignProposedGroups(
        dataSrc,
        proposedGroups,
        selectedVehicleIdsSet,
        this.selectedVehicleIds,
        this.manifestVehiclesMap,
        this.vehiclesMap,
      );

      this.totals.proposed = {
        ...this.router.calculateTotals(dataSrc.map(t => t.proposedGroup).filter(t => t)),
      };
      this.router.sortDataSource(dataSrc, this.dataSourceOrder, this.employeeWorkingTimeMap);
      this.dataSource = dataSrc;
    } catch (error) {
      console.error(error);
      this.snackBar.open(error.message, 'Close', {
        duration: 3000,
      });
    }
    this.ui.hideLoading();
  }

  async calculateTravelHandler() {
    this.ui.showLoading();
    await this.router.calculateTravel(
      this.totals,
      asShortDate(this.selectedDate),
      this.dataSource,
      this.destinationsMap,
      this.dss,
      this.employeeWorkingTimeMap,
    );
    this.ui.hideLoading();
  }

  lockFirstTripsOrClearAllHandler() {
    if (this.lockSelectedTrips) this.clearSelectedTrips();
    else this.lockFirstTrips();
  }

  lockFirstTrips() {
    this.dataSource.forEach(({ manifestGroup }) => {
      if (manifestGroup && manifestGroup.trips && manifestGroup.vehicle) {
        const t = manifestGroup.trips[0];
        if (t) t.lock = true;
      }
    });
    this.lockSelectedTrips = true;
  }

  clearSelectedTrips() {
    this.dataSource.forEach(({ manifestGroup }) => {
      if (manifestGroup && manifestGroup.trips) {
        manifestGroup.trips.forEach(t => (t.lock = false));
      }
    });
    this.lockSelectedTrips = false;
  }

  lockChangeHandler() {
    this.lockSelectedTrips = this.dataSource.some(
      ({ manifestGroup }) => manifestGroup && manifestGroup.trips.some(t => t.lock),
    );
  }

  openRoutesMap(group) {
    if (!group || !group.trips) return;
    const title = `Veh #${group.vehicle.internalId} ${this.employeeHelper.displayExpr(group.employee)}`;
    const { routes, markers } = this.router.getRoutesAndMarkers(group.trips, this.destinationsMap);
    this.dialog.open<any, any, number>(DlgRoutesMapComponent, {
      hasBackdrop: true,
      data: {
        title: `Routes Map for ${title}`,
        markers: markers,
        routes: routes,
      },
    });
  }

  openWorkingHours() {
    this.dialog.open<any, any, number>(DlgEmployeeWorkingTimeComponent, {
      hasBackdrop: true,
      data: { selectedDate: asShortDate(this.selectedDate) },
    });
  }

  getFullAddress(cellInfo) {
    return this.router.getAddr(cellInfo.value, cellInfo.data.__consumer, this.destinationsMap);
  }

  errorStyle = 'background-color: #ffc1c1; color: #333; padding: 2px 3px; border-radius: 4px;margin-left: -3px;';
  getAddressCell(cellInfo) {
    const val = cellInfo.value;
    const addr = this.getFullAddress(cellInfo);
    const fn = this.getConsumerFullName(cellInfo.row.data);
    const noAddr = `No address provided for ${val === 'RESIDENCE' ? fn : val}`;
    const title = addr ? `Address: ${addr}` : noAddr;
    const style = !addr ? this.errorStyle : '';
    let html = `<span style="${style}" title="${title}">${val}</span>`;
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  getPickupCell(cellInfo, trips) {
    const puTime = moment(cellInfo.value, 'HH:mm:ss').format('hh:mm A');
    let html = `<span>${puTime}</span>`;
    if (cellInfo.rowIndex > 0) {
      const prev = trips[cellInfo.rowIndex - 1];
      const curr = trips[cellInfo.rowIndex];
      if (prev.dot > curr.t) {
        this.conflictTripsMap[curr.id] = true;
        const doTime = moment(prev.dot, 'HH:mm:ss').format('hh:mm A');
        const title = `Previous Dropoff Time (${doTime}) is after Current Pickup Time (${puTime})`;
        html = `<span style="${this.errorStyle}" title="${title}">${puTime}</span>`;
      }
    }
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  // async exportGroups(groupName: string) {
  //   const workbook = new Workbook();

  //   for (const [index, dataGrid] of this.dataGrids.toArray().entries()) {
  //     const worksheet = workbook.addWorksheet(`Grid ${index + 1}`);

  //     await exportDataGrid({
  //       component: dataGrid.instance,
  //       worksheet: worksheet,
  //       autoFilterEnabled: true
  //     });
  //   }

  //   // const buffer = await workbook.xlsx.writeBuffer();
  //   // saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrids.xlsx');
  // }

  async overwriteAllManifestHandler() {
    this.dialog
      .open<any, any, number>(DlgOverwriteManifestConfirm, {
        hasBackdrop: true,
        data: { selectedDate: moment(this.selectedDate).format('dddd M/D/YYYY') },
      })
      .afterClosed()
      .subscribe(async (result: any) => {
        if (result) {
          this.ui.showLoading();
          await this.overwriteAllManifest();
          if (result.overwriteSchedules) await this.overwriteSchedules();
          await this.saveEmployeeWorkingTime();
          await this.loadManigest();
          await this.loadEmployeeWorkingTime();
          this.router.sortDataSource(this.dataSource, this.dataSourceOrder, this.employeeWorkingTimeMap);
          this.ui.hideLoading();
        }
      });
  }

  async overwriteAllManifest() {
    const proposedTripsPool = this.dataSource.flatMap(({ proposedGroup }) =>
      ((proposedGroup && proposedGroup.trips) || []).map((t: any, i) => ({
        ...t,
        e: (proposedGroup.employee && proposedGroup.employee.id) || -1,
        esc: (proposedGroup.escort && proposedGroup.escort.id) || null,
        v: (proposedGroup.vehicle && proposedGroup.vehicle.id) || null,
        tr: i + 1,
      })),
    );
    await this.helper.api.updateManifest(this.manifest.id, proposedTripsPool).toPromise();
  }

  async saveEmployeeWorkingTime() {
    const date = asShortDate(this.selectedDate);
    const data = this.dataSource.flatMap(({ proposedGroup: pg }) => {
      if (!pg || !pg.load || !pg.trips) return [];
      const { workingMinutes } = pg;
      const arr = [];
      if (pg.employee && pg.employee.id > 0) arr.push({ status: 'DRIVER', employeeId: pg.employee.id, workingMinutes });
      if (pg.escort && pg.escort.id > 0) arr.push({ status: 'ESCORT', employeeId: pg.escort.id, workingMinutes });
      return arr;
    });
    await this.dss
      .getApi<EmployeeWorkingTimeApi>(EmployeeWorkingTime)
      .saveManyByDate(date, data, headersAllTenantsAppend)
      .toPromise();
  }

  async overwriteSchedules() {
    const date = asShortDate(this.selectedDate);
    await this.dss.getApi<DriverScheduleApi>(DriverSchedule).dropAll(date, true).toPromise();
    const data = this.dataSource.flatMap(({ proposedGroup: pg }) => {
      if (!pg || !pg.load || !pg.trips) return [];
      const { startTime, finishTime } = pg;

      const [startTimeCalculatedAt, finishTimeCalculatedAt] = [new Date(), new Date()];
      const facilityId = this.auth.getCurrentTenant();
      const { startLocationAddress, finishLocationAddress, startLocationCoords, finishLocationCoords } = pg;
      const { startLocationCalculated, finishLocationCalculated, startTravelDuration, finishTravelDuration } = pg;
      const { startTripTime, finishTripTime, startTripLocationAddress, finishTripLocationAddress } = pg;
      const { startTripLocation, startTripConsumerName, finishTripLocation, finishTripConsumerName } = pg;
      const data = {
        ...{ mode: 'AUTO', day: date, date: `${date}T16:00:00.000Z` },
        ...{ facilityId, startFacilityId: facilityId, finishFacilityId: facilityId },
        ...{ startTime, finishTime, startTimeCalculatedAt, finishTimeCalculatedAt },
        ...{ startInstructions: '', finishInstructions: '' },
        ...{ startLocationAddress, finishLocationAddress, startLocationCoords, finishLocationCoords },
        ...{ startLocationCalculated, finishLocationCalculated, startTravelDuration, finishTravelDuration },
        ...{ startTripTime, finishTripTime, startTripLocationAddress, finishTripLocationAddress },
        ...{ startTripLocation, startTripConsumerName, finishTripLocation, finishTripConsumerName },
      };
      const arr = [];
      if (pg.employee && pg.employee.id > 0) arr.push({ status: 'DRIVER', driverId: pg.employee.id, ...data });
      if (pg.escort && pg.escort.id > 0) arr.push({ status: 'ESCORT', driverId: pg.escort.id, ...data });
      return arr;
    });
    try {
      await this.dss.getApi<DriverScheduleApi>(DriverSchedule).createMany(data, headersAllTenantsAppend).toPromise();
    } catch (error) {
      console.log('Ignore it if you have manual schedules', error);
    }
  }

  async overwriteToManifestHandler(proposedGroup, dataSourceIdx) {
    this.ui.showLoading();
    const ds = JSON.parse(JSON.stringify(this.dataSource));
    for (let trip of proposedGroup.trips) {
      for (let i = 0; i < ds.length; i++) {
        if (i === dataSourceIdx || !ds[i].manifestGroup) continue;
        const trips = ds[i].manifestGroup.trips || [];
        const idx = trips.findIndex(t => t.id === trip.id);
        if (idx !== -1) {
          trips.splice(idx, 1);
          this.router.calculateLoadPerGroup(ds[i].manifestGroup);
          break;
        }
      }
    }
    ds[dataSourceIdx].manifestGroup = JSON.parse(JSON.stringify(proposedGroup));
    this.manifestGroups = ds.map(d => d.manifestGroup).filter(d => d);
    this.makeManifestTripsMap();
    this.conflictTripsMap = {};
    this.dataSource = ds;
    this.totals.manifest = this.router.calculateTotals(this.manifestGroups);
    // wait a bit to let the UI update
    await new Promise(resolve => setTimeout(resolve, 500));
    this.ui.hideLoading();
  }
}
