
declare var Object: any;
export interface TripSignatureStatsInterface {
  "id"?: string;
  "appName"?: string;
  "consumerId"?: number;
  "count": number;
  "employeeId"?: number;
  "serviceType"?: string;
  "tenantId": number;
  "units"?: number;
  "vdate"?: any;
}

export class TripSignatureStats implements TripSignatureStatsInterface {
  "id": string;
  "appName": string;
  "consumerId": number;
  "count": number;
  "employeeId": number;
  "serviceType": string;
  "tenantId": number;
  "units": number;
  "vdate": any;
  constructor(data?: TripSignatureStatsInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `TripSignatureStats`.
   */
  public static getModelName() {
    return "TripSignatureStats";
  }

  /**
  * This method creates an instance of TripSignatureStats for dynamic purposes.
  **/
  public static factory(data: TripSignatureStatsInterface): TripSignatureStats{
    return new TripSignatureStats(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'TripSignatureStats',
      plural: 'TripSignatureStats',
      path: 'TripSignatureStats',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'string'
        },
        "appName": {
          name: 'appName',
          type: 'string'
        },
        "consumerId": {
          name: 'consumerId',
          type: 'number'
        },
        "count": {
          name: 'count',
          type: 'number'
        },
        "employeeId": {
          name: 'employeeId',
          type: 'number'
        },
        "serviceType": {
          name: 'serviceType',
          type: 'string'
        },
        "tenantId": {
          name: 'tenantId',
          type: 'number'
        },
        "units": {
          name: 'units',
          type: 'number'
        },
        "vdate": {
          name: 'vdate',
          type: 'any'
        },
      },
      relations: {
      }
    }
  }
}
