/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./auto-dispatch-details-tabs.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../auto-dispatch-details-tab-accepted/auto-dispatch-details-tab-accepted.component.ngfactory";
import * as i3 from "../../../trip-manifest/services/helper.service";
import * as i4 from "@angular/common/http";
import * as i5 from "../../../../shared/sdk/services/custom/TripManifest";
import * as i6 from "../../../../shared/modules/my-common/services/common.service";
import * as i7 from "../../../../shared/modules/my-common/services/config.service";
import * as i8 from "../../../../shared/modules/my-common/services/datasource.service";
import * as i9 from "../../../employee/services/helper.service";
import * as i10 from "../../../consumer/services/helper.service";
import * as i11 from "../../../vehicle/services/helper.service";
import * as i12 from "../../../../shared/modules/my-common/services/pusher.service";
import * as i13 from "../../../../shared/modules/ui/services/upload-helper.service";
import * as i14 from "../auto-dispatch-details-tab-accepted/auto-dispatch-details-tab-accepted.component";
import * as i15 from "../../../../shared/sdk/services/custom/logger.service";
import * as i16 from "../../../../shared/modules/ui/services/ui.service";
import * as i17 from "@angular/material/dialog";
import * as i18 from "../../../../shared/sdk/services/core/auth.service";
import * as i19 from "../auto-dispatch-details-tab-denied/auto-dispatch-details-tab-denied.component.ngfactory";
import * as i20 from "../auto-dispatch-details-tab-denied/auto-dispatch-details-tab-denied.component";
import * as i21 from "../../../../../../node_modules/devextreme-angular/ui/tab-panel/devextreme-angular-ui-tab-panel.ngfactory";
import * as i22 from "devextreme-angular/core";
import * as i23 from "devextreme-angular/ui/tab-panel";
import * as i24 from "@angular/platform-browser";
import * as i25 from "./auto-dispatch-details-tabs.component";
var styles_AutoDispatchDetailsTabsComponent = [i0.styles];
var RenderType_AutoDispatchDetailsTabsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AutoDispatchDetailsTabsComponent, data: {} });
export { RenderType_AutoDispatchDetailsTabsComponent as RenderType_AutoDispatchDetailsTabsComponent };
function View_AutoDispatchDetailsTabsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "app-auto-dispatch-details-tab-accepted", [], null, null, null, i2.View_AutoDispatchDetailsTabAcceptedComponent_0, i2.RenderType_AutoDispatchDetailsTabAcceptedComponent)), i1.ɵprd(512, null, i3.HelperService, i3.HelperService, [i4.HttpClient, i5.TripManifestApi, i6.CommonService, i7.ConfigService, i8.DataSourceService, "Window", i9.HelperService, i10.HelperService, i11.HelperService, i12.PusherService, i13.UploadHelperService]), i1.ɵdid(3, 245760, null, 0, i14.AutoDispatchDetailsTabAcceptedComponent, [i15.LoggerService, i16.UiService, i8.DataSourceService, i17.MatDialog, i3.HelperService, i18.LoopBackAuth], { message: [0, "message"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message; _ck(_v, 3, 0, currVal_0); }, null); }
function View_AutoDispatchDetailsTabsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-auto-dispatch-details-tab-denied", [], null, null, null, i19.View_AutoDispatchDetailsTabDeniedComponent_0, i19.RenderType_AutoDispatchDetailsTabDeniedComponent)), i1.ɵdid(2, 245760, null, 0, i20.AutoDispatchDetailsTabDeniedComponent, [i15.LoggerService, i16.UiService, i8.DataSourceService, i17.MatDialog], { message: [0, "message"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_AutoDispatchDetailsTabsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "dx-tab-panel", [["height", "100%"], ["width", "100%"]], null, [[null, "selectedIndexChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedIndexChange" === en)) {
        var pd_0 = ((_co.selectedIndex = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i21.View_DxTabPanelComponent_0, i21.RenderType_DxTabPanelComponent)), i1.ɵprd(512, null, i22.DxTemplateHost, i22.DxTemplateHost, []), i1.ɵprd(512, null, i22.WatcherHelper, i22.WatcherHelper, []), i1.ɵprd(512, null, i22.IterableDifferHelper, i22.IterableDifferHelper, [i1.IterableDiffers]), i1.ɵprd(512, null, i22.NestedOptionHost, i22.NestedOptionHost, []), i1.ɵdid(5, 7323648, [["tabPanel", 4]], 1, i23.DxTabPanelComponent, [i1.ElementRef, i1.NgZone, i22.DxTemplateHost, i22.WatcherHelper, i22.IterableDifferHelper, i22.NestedOptionHost, i24.TransferState, i1.PLATFORM_ID], { animationEnabled: [0, "animationEnabled"], dataSource: [1, "dataSource"], height: [2, "height"], loop: [3, "loop"], selectedIndex: [4, "selectedIndex"], showNavButtons: [5, "showNavButtons"], swipeEnabled: [6, "swipeEnabled"], width: [7, "width"] }, { selectedIndexChange: "selectedIndexChange" }), i1.ɵqud(603979776, 1, { itemsChildren: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AutoDispatchDetailsTabsComponent_1)), i1.ɵdid(8, 16384, null, 0, i22.DxTemplateDirective, [i1.TemplateRef, i1.ViewContainerRef, i22.DxTemplateHost, i1.Renderer2, i1.NgZone], { dxTemplateOf: [0, "dxTemplateOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AutoDispatchDetailsTabsComponent_2)), i1.ɵdid(10, 16384, null, 0, i22.DxTemplateDirective, [i1.TemplateRef, i1.ViewContainerRef, i22.DxTemplateHost, i1.Renderer2, i1.NgZone], { dxTemplateOf: [0, "dxTemplateOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; var currVal_1 = _co.tabs; var currVal_2 = "100%"; var currVal_3 = false; var currVal_4 = _co.selectedIndex; var currVal_5 = true; var currVal_6 = false; var currVal_7 = "100%"; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_8 = "accepted"; _ck(_v, 8, 0, currVal_8); var currVal_9 = "denied"; _ck(_v, 10, 0, currVal_9); }, null); }
export function View_AutoDispatchDetailsTabsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-auto-dispatch-details-tabs", [], null, null, null, View_AutoDispatchDetailsTabsComponent_0, RenderType_AutoDispatchDetailsTabsComponent)), i1.ɵdid(1, 114688, null, 0, i25.AutoDispatchDetailsTabsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AutoDispatchDetailsTabsComponentNgFactory = i1.ɵccf("app-auto-dispatch-details-tabs", i25.AutoDispatchDetailsTabsComponent, View_AutoDispatchDetailsTabsComponent_Host_0, { message: "message" }, {}, []);
export { AutoDispatchDetailsTabsComponentNgFactory as AutoDispatchDetailsTabsComponentNgFactory };
