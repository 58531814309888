import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { createSelector, select } from '@ngrx/store';
import { confirm } from 'devextreme/ui/dialog';
import { groupBy } from 'lodash-es';
import fromPairs from 'lodash-es/fromPairs';
import isEmpty from 'lodash-es/isEmpty';
import sortBy from 'lodash-es/sortBy';
import moment from 'moment';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import { gqlMongoLoad } from '../../../../shared/classes/loopback-custom-store/generic/store.utils';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseFormComponent } from '../../../../shared/modules/ui/components/abstract/a-base-form.component';
import { FORM_STATE } from '../../../../shared/modules/ui/components/abstract/a-base-model-loader.component';
import { FormHelperService } from '../../../../shared/modules/ui/services/form-helper.service';
import {
  Config,
  Consumer,
  ConsumerApi,
  ConsumerView,
  ConsumerViewApi,
  Facility,
  LoggerService,
  LoopBackFilter,
  Program,
} from '../../../../shared/sdk';
import { selectConfigState } from '../../../../store/reducers/core';
import { ConfigState } from '../../../../store/reducers/core/config';
import { selectSignInfoState } from '../../../../store/reducers/sign';
import { InfoState } from '../../../../store/reducers/sign/info';
import { PERSON_SEXES, REPETITIVE_MODES, WEEKDAYS } from '../../../employee/classes/enums';
import { CONSUMER_LANGS, CONSUMER_STATUSES, CONSUMER_TRANSPORTATION_CODES } from '../../classes/enums';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-consumer-form',
  templateUrl: './consumer-form.component.html',
  styleUrls: ['./consumer-form.component.scss'],
})
export class ConsumerFormComponent extends ABaseFormComponent<Consumer> implements OnInit {
  modelWithDetails$: BehaviorSubject<Consumer & { _mealsAuths; _brokerClient; _authClient; _mealsEstimated }> =
    new BehaviorSubject(null);

  adcClientCACFPEligibilityList = [
    { v: 'N', t: 'N/A' },
    { v: 'A', t: 'A / V / 1 - Free' },
    { v: 'B', t: 'B / W / 2 - Reduced' },
    { v: 'C', t: 'C / X / 3 - Paid' },
  ];

  get addressesFormArray(): FormArray {
    return this.form.get('person.contact.addresses') as FormArray;
  }

  get phonesFormArray(): FormArray {
    return this.form.get('person.contact.phones') as FormArray;
  }

  get emailsFormArray(): FormArray {
    return this.form.get('person.contact.emails') as FormArray;
  }

  get emRelationsFormArray(): FormArray {
    return this.form.get('emRelations') as FormArray;
  }

  protected get filter(): LoopBackFilter {
    return {
      include: [
        {
          relation: 'relatedNotes',
          scope: { order: 'dateTime DESC', limit: 5 },
        },
        'program',
        { person: { contact: ['addresses', 'phones', 'emails'] } },
        { emRelations: { person: { contact: ['addresses', 'phones', 'emails'] } } },
      ],
    };
  }

  protected get ModelClass(): any {
    return Consumer;
  }

  protected get dateFields(): string[] {
    return ['person.dob', 'clientSince', '_note.infoDate', '_note.followUpDate'];
  }

  get age() {
    return moment().diff(this.form.get('person.dob').value, 'years', false);
  }

  personSexesDS = PERSON_SEXES;
  consumerStatuses = CONSUMER_STATUSES;
  repetitiveModes = REPETITIVE_MODES;
  transpCodes = CONSUMER_TRANSPORTATION_CODES;
  consumerLangs = CONSUMER_LANGS;
  weekDays = WEEKDAYS;

  mealTypes = ['hot_W1759', 'frozen_W1760', 'sandwich_W1761', 'emergency_W1762', 'special_W1764'];

  mealTypeCodeMap = {
    hot_W1759: 'W1759',
    frozen_W1760: 'W1760',
    sandwich_W1761: 'W1761',
    emergency_W1762: 'W1762',
    special_W1764: 'W1764',
  };

  codeMealTypeMap = {
    W1759: 'hot_W1759',
    W1760: 'frozen_W1760',
    W1761: 'sandwich_W1761',
    W1762: 'emergency_W1762',
    W1764: 'special_W1764',
  };

  mealTypeConfigMap = {
    hot_W1759: 'meals_Hot_W1759_Enabled',
    frozen_W1760: 'meals_Frozen_W1760_Enabled',
    sandwich_W1761: 'meals_Sandwich_W1761_Enabled',
    emergency_W1762: 'meals_Emergency_W1762_Enabled',
    special_W1764: 'meals_Special_W1764_Enabled',
  };

  mealTypeTitleMap = {
    hot_W1759: 'Hot Meal (W1759)',
    frozen_W1760: 'Frozen Meal (W1760)',
    sandwich_W1761: 'Sandwich Meal (W1761)',
    emergency_W1762: 'Emergency Meal (W1762)',
    special_W1764: 'Special Meal (W1764)',
  };

  tooltips = {
    clientNotes:
      'General notes concerning client, like<br>' +
      '<em>"For any questions, please contact Daughter Jane"</em> or<br>' +
      '<em>"Client moved as of 1/1/2019 to new address: ..."</em>',
    program:
      "Client's State Program - 95% of clients will have <strong>PA-CHC</strong> " +
      '(Which is former OLTL-Waivers: Aging, Attendant Care, Independence, Or Dual Eligible Medicare & Medicaid)<br>' +
      '<strong>PA-OPTIONS</strong> - Another active state program, not under CHC<br>' +
      '<strong>NON-PROGRAM-YET</strong> - Clients in process of receiving program<br>' +
      '<strong>NOT-PROGRAM-ELIGIBLE</strong> - Clients not eligible for state programs<br>' +
      '<strong>PRIVATE PAY</strong> - Clients Paying out of Pocket<br>' +
      '<strong>SELF RIDE</strong> - Clients using their own means of transport<br>' +
      '<strong>OTHER COMPANY</strong> - Clients Transported by other company<br>',
    internalID:
      'This ID comes from "2019 Clients" Google Sheets, ' + 'please make sure client is entered there and ID assigned.',
    clientType:
      'Subscription or Single ride.<br>' +
      'For repetitive riders use <strong>"Subscription"</strong> value, ' +
      'these are all of the Adult Day Care ' +
      'and most of the clients receiving Dialysis services.<br>' +
      'For clients going to regular medical appointments use <strong>"Single Ride"</strong>.',
    weekDays:
      'For Subscription Clients select week days they are attending facility, ' +
      'refer to notes from Broker and confirm days with Facility.',
    transpInstructions:
      'Instructions for Drivers with details about pickup location, obstacles, etc.<br>' +
      'Sample: <em>"Client has 5 steps in front of the house, need assistance walking down the stairs."</em> ' +
      'or <em>"Call 10 min prior to arrival"</em> ' +
      'or <em>"Client using back door."</em> <br>' +
      'These Instructions are printed on manifest for the driver, ' +
      "never put in this field client's medical condition or diagnosis.",
    transpCodes:
      'Important flags Driver should be aware of and always keep in mind. ' +
      'These codes are printed on manifest for the driver.',
    attendanceNote:
      'Internal note specifically addressing clients attendance, ' +
      "fill out this note every time client's Status or " +
      'attendance changes always specify date of note, ' +
      'for example <em>"2/1/2019 ON-HOLD - Traveling to India, will be back 6/1/2019"</em> ' +
      'or <em>"4/1/2019 Inactive - Deceased on 3/28/2019, informed by ADC"</em>',
    relatedContacts:
      'Contact info of a person related to consumer, ' +
      'usually caregiver (Son, Daughter, Sister, Brother, Aid, PCP, etc).',
    brokerNotes: 'Most Recent Notes pulled from broker Trip records',
  };

  destinationsDS$: Observable<any[]>;
  programDso: any;
  addNoteEnabled: boolean;
  destinationDisplayExpr = data => (data ? `[${data.short}] ${data.name}` : '');

  constructor(
    protected logger: LoggerService,
    protected fb: FormBuilder,
    protected dss: DataSourceService,
    protected helper: FormHelperService<Consumer>,
    public config: ConfigService,
    @Inject(CommonService) private common: CommonService,
    public helperService: HelperService,
  ) {
    super(logger, fb, dss, helper);

    this.destinationsDS$ = this.common.store.pipe(
      select(
        createSelector(
          [selectSignInfoState, selectConfigState],
          (s1: InfoState, s2: ConfigState) => [s1.currentTenant, s2.config] as [number, Config],
        ),
      ),
      switchMap(([tenantId, conf]) =>
        combineLatest([
          !isNaN(tenantId) && tenantId > 0
            ? this.dss.models.getApi(Facility.getModelName()).findById<Facility>(tenantId)
            : of<null>(null),
          of(oc(conf).data.destinationList([]) as any[]),
        ]),
      ),
      map(([tenant, destinationList]) => {
        const addition = oc(tenant).type() !== 'BASE' ? [{ short: 'FACILITY', name: '' }] : [];
        const sorted = sortBy(
          destinationList.filter(d => ['FACILITY', 'SCHOOL'].includes(d.group)).filter(d => d && d.short),
          ['short'],
        );
        return [...addition, ...sorted];
      }),
    );

    const store = this.dss.getStore(Program, undefined, false);
    this.programDso = {
      store,
      // filter: ['type', 'inq', ['ADC', 'BASE']],
      sort: [{ selector: 'order' }],
    };

    this.setState(FORM_STATE.COLLAPSED);

    this.config.tenantType$
      .pipe(
        tap(t => {
          if (['BASE', 'MEALS'].includes(t)) this.tabsTitles = ['General', 'Transport', 'Meals'];
          else this.tabsTitles = ['General', 'Transport'];
        }),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  ngOnInit() {
    super.ngOnInit();

    this.model$
      .pipe(
        switchMap(model => {
          if (model) {
            const mealsAuths$ = (this.api as ConsumerApi).getMealsAuths(
              model.id,
              moment().format('YYYY-MM-DD'),
              this.customHeaders,
            );
            const mealsEstimated$ = (this.api as ConsumerApi).getMealsEstimated(
              model.id,
              moment().format('YYYY-MM-DD'),
              this.customHeaders,
            );

            const brokerClients$ = of(true).pipe(
              switchMap(() =>
                !isEmpty(model.mci)
                  ? gqlMongoLoad(this.dss, 'ExportsConsumersCache', {
                      filter: [['_mci', '=', model.mci]],
                    })
                  : of([]),
              ),
            );

            return combineLatest([mealsAuths$, brokerClients$, mealsEstimated$]).pipe(
              map(([mealsAuths, brokerClients, mealsEstimated]) => ({
                ...model,
                _mealsAuths: fromPairs(
                  Object.entries(groupBy(mealsAuths, 'Code')).map(([c, auths]) => [this.codeMealTypeMap[c], auths[0]]),
                ),
                _brokerClient: brokerClients.find(bc => !(bc._broker as string).includes('.AUTH')),
                _authClient: brokerClients.find(bc => (bc._broker as string).includes('.AUTH')),
                _mealsEstimated: fromPairs(
                  Object.entries(mealsEstimated).map(([c, o]) => [this.codeMealTypeMap[c], o]),
                ),
              })),
            );
          } else {
            return of(null);
          }
        }),
        tap(modelWithDetails => this.modelWithDetails$.next(modelWithDetails)),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  protected async processFormValueAsync(data: any): Promise<any> {
    const note = data._note;

    if (!isEmpty(note.text)) {
      note.meta = oc(note).meta({});
      note.source = oc(note).source('web');
      data.relatedNotes = [note];
    }

    if (this.model && this.model.status !== data.status && isEmpty(note.text)) {
      throw new Error('You should add note on changing client status');
    }

    const duplicates = !this.modelId
      ? await this.dss
          .getApi<ConsumerViewApi>(ConsumerView)
          .find<ConsumerView>({
            where: {
              ...(this.modelId ? { [Consumer.getModelDefinition().idName]: { neq: this.modelId } } : {}),
              person_firstname: { like: ((data.person.firstname as string) || '').trim().toLowerCase() },
              person_lastname: { like: ((data.person.lastname as string) || '').trim().toLowerCase() },
              // person_dob: ,
            },
            limit: 1,
          })
          .toPromise()
      : [];

    if (duplicates.length) {
      const res = await confirm(
        'Client with the same name already exists.<br>Abort client creation?',
        'Duplicate found',
      );

      if (res === true) throw new Error('This client already exists');
    }

    return super.processFormValueAsync(data);
  }

  addNoteClick(e, value: boolean) {
    this.addNoteEnabled = value;
  }

  protected buildForm(): void {
    this.formConfigMap.set('person.contact.addresses', {
      id: [],
      street: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zip: [],
      contactId: [],
    });

    this.formConfigMap.set('person.contact.phones', {
      id: [],
      label: [],
      value: ['', Validators.required],
      contactId: [],
    });

    this.formConfigMap.set('person.contact.emails', {
      id: [],
      label: [],
      value: ['', Validators.required],
      contactId: [],
    });

    this.formConfigMap.set('person.contact', {
      id: [],
      notes: [],
      addresses: this.fb.array([]),
      phones: this.fb.array([]),
      emails: this.fb.array([]),
    });

    this.formConfigMap.set('person.data', {
      nativeLang: [],
      nativeLangName: [],
    });

    this.formConfigMap.set('person', {
      id: [],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      middlename: [],
      nickname: [],
      sex: [],
      dob: [],
      notes: [],
      contactId: [],
      data: this.fb.group(this.formConfigMap.get('person.data')),
      contact: this.fb.group(this.formConfigMap.get('person.contact')),
    });

    this.formConfigMap.set('_note', {
      id: [],
      text: [],
      author: [],
      infoBy: [],
      infoDate: [],
      followUpDate: [],
      source: ['web'],
      meta: [{}],
    });

    this.formConfigMap.set('emRelations', {
      id: [],
      relation: [],
      consumerId: [],
      personId: ['', Validators.required],
    });

    this.formConfigMap.set('data', {
      icd10: [],
      unableToSign: [false],
      adcClientShift: [],
      adcClientShiftPerDay: [],
      adcDailyRate: [],
      adcClientCACFPEligibility: ['N'],
      //
      sdac_01_iihrucDCg: [],
      sdac_02_lnfis: [],
      sdac_03_csdacsccu: [],
      sdac_04_apasdi: [],
      sdac_05_ncwoh: [],
      sdac_06_mhced: [],
      sdac_07_sil: [],
      sdac_08_finr: [],
      sdac_09_others: [],
      sdac_09_othersText: [],
    });

    this.formConfigMap.set('weekdayMeta', () => ({
      schedUnits: [],
      actualDay: [],
      actualUnits: [],
      schedTime: [],
      mealRequests: [],
    }));

    this.formConfigMap.set('mealTypeMeta', () => ({
      notes: [''],

      weekdayMeta: this.fb.group({
        mo: this.fb.group(this.formConfigMap.get('weekdayMeta')()),
        tu: this.fb.group(this.formConfigMap.get('weekdayMeta')()),
        we: this.fb.group(this.formConfigMap.get('weekdayMeta')()),
        th: this.fb.group(this.formConfigMap.get('weekdayMeta')()),
        fr: this.fb.group(this.formConfigMap.get('weekdayMeta')()),
        sa: this.fb.group(this.formConfigMap.get('weekdayMeta')()),
        su: this.fb.group(this.formConfigMap.get('weekdayMeta')()),
      }),
    }));

    this.formConfigMap.set('mealMeta', {
      smsOnMealDropOff: [false],
      mealDeliveryI10s: [''],
      cuisinePreference: [''],
      foodAllergies: [''],
      mealGeneralNotes: [''],

      hot_W1759: this.fb.group(this.formConfigMap.get('mealTypeMeta')()),
      frozen_W1760: this.fb.group(this.formConfigMap.get('mealTypeMeta')()),
      sandwich_W1761: this.fb.group(this.formConfigMap.get('mealTypeMeta')()),
      emergency_W1762: this.fb.group(this.formConfigMap.get('mealTypeMeta')()),
      special_W1764: this.fb.group(this.formConfigMap.get('mealTypeMeta')()),
    });

    this.formConfigMap.set('', {
      id: [],
      status: ['', Validators.required],
      internalID: [],
      facilityID: [],
      mci: [],
      autoDispatch: [],
      keepStretcher: [],
      onBoardingDuration: [],
      offBoardingDuration: [],
      defaultDestination: [],
      daysNotes: [],
      brokerNotes: [],
      specialInstrs: [],
      transpInstrs: [],
      transpCodes: [[]],
      // notes: [],

      clientSince: [],
      repetitiveMode: ['SUBSCRIPTION'],
      weekDays: [{}],

      c10nAgency: [],
      cwName: [],
      cwPhone: [],
      cwFax: [],
      cwEmail: [],
      cwNote: [],

      personId: [],
      programId: [],
      data: this.fb.group(this.formConfigMap.get('data')),
      person: this.fb.group(this.formConfigMap.get('person')),
      mealMeta: this.fb.group(this.formConfigMap.get('mealMeta')),
      emRelations: this.fb.array([]),

      _note: this.fb.group(this.formConfigMap.get('_note')),
    });

    this.form = this.fb.group(this.formConfigMap.get(''));
  }
}
