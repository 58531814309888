import { AfterViewInit, Component, OnInit } from '@angular/core';
import notify from 'devextreme/ui/notify';
import moment from 'moment';
import { Observable, of } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { LoggerService, MyUtilsApi } from '../../../../shared/sdk/services/custom';
import { ComponentCanDeactivate } from '../../../../shared/modules/my-common/guards/pending-changes.guard';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { PusherService } from '../../../../shared/modules/my-common/services/pusher.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { PushNotificationsService } from '../../../../shared/modules/ui/services/push-notifications.service';
import { UploadHelperService } from '../../../../shared/modules/ui/services/upload-helper.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-import-form',
  templateUrl: './import-form.component.html',
  styleUrls: ['./import-form.component.scss'],
})
export class ImportFormComponent extends ABaseComponent implements OnInit, AfterViewInit, ComponentCanDeactivate {
  env = environment;

  deactivateWarning =
    'WARNING: You have unprocessed files.' +
    ' Press Cancel to go back and wait for process to be completed,' +
    ' or OK to abort processing.';

  error: any;
  validationSummary: any[] = [];
  summary: any[] = [];

  selectedStartValue: Date = moment().startOf('month').toDate();
  selectedEndValue: Date = moment().startOf('month').add(1, 'days').toDate();
  tenantType: string;

  importArgs: {
    use_import_2: boolean;
    // no_headers: boolean;
    source: string | null;
    payment_date?: Date | string;
  } = {
    use_import_2: false,
    source: null,
  };

  set paymentDate(value: Date) {
    this.importArgs.payment_date = moment(value).format('YYYY-MM-DD');
  }

  get paymentDate(): Date {
    // if (oc(this).importArgs.paymentDate())
    //   return moment(this.importArgs.paymentDate).toDate();
    return null;
  }

  constructor(
    protected logger: LoggerService,
    protected common: CommonService,
    public config: ConfigService,
    private pusher: PusherService,
    private uploadHelper: UploadHelperService,
    private utilsApi: MyUtilsApi,
    private notification: PushNotificationsService,
  ) {
    super(logger);
  }

  get errorMessage(): string {
    return this.error && this.error.message ? this.error.message : this.error;
  }

  ngOnInit() {
    super.ngOnInit();

    this.config.tenantType$.pipe(takeUntil(this.$onDestroy$)).subscribe(t => (this.tenantType = t));
  }

  ngAfterViewInit(): void {}

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }

  // region fill broker trips data

  fillBrokerTrips_onClick(e) {
    this.genPeriodReport('FILL_BROKER_TRIPS_DATA', {
      title: 'Done!',
      opts: {
        body: 'Fill Broker Trips Done!',
        requireInteraction: true,
      },
    });
  }

  private genPeriodReport(jobName: string, notification: { title: string; opts?: NotificationOptions }) {
    const start = this.selectedStartValue;
    const end = this.selectedEndValue;

    // console.log(start, end);

    const startMoment = moment(start);
    const endMoment = moment(end).add(1, 'days');

    notify('Fill Broker Trips Requested');

    this.pusher
      .rpc(jobName, {
        from: startMoment.format('YYYY-MM-DD'),
        to: endMoment.format('YYYY-MM-DD'),
        useRunService: true,
      })
      .pipe(
        tap(() => this.notification.generateNotification(notification)),
        catchError(err => {
          notify(err.message, 'error', 5000);
          return of(null);
        }),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  // endregion
}
