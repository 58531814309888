<app-content-w-header header="Import">
  <div class="content" style="">
    <mat-card>
      <!--      <ng-container *ngIf="(config.roles | async)['SU']">-->
      <!--        <app-import-form-ej2></app-import-form-ej2>-->
      <!--        <br>-->
      <!--      </ng-container>-->

      <!--      <mat-checkbox [(ngModel)]="importArgs.no_headers">File grid without headers</mat-checkbox>-->
      <!--      <br>-->

      <mat-form-field>
        <mat-label>File Source</mat-label>
        <mat-select [(ngModel)]="importArgs.source">
          <mat-option>undefined</mat-option>
          <mat-option *ngFor="let source of ['LGTC', 'LGTC.REMITTANCE']" [value]="source">
            {{ source }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      &nbsp;

      <mat-form-field *ngIf="importArgs.source === 'LGTC.REMITTANCE'">
        <input matInput placeholder="Choose payment date" [matDatepicker]="paymentPicker" [(ngModel)]="paymentDate" />
        <mat-datepicker-toggle matSuffix [for]="paymentPicker"></mat-datepicker-toggle>
        <mat-datepicker #paymentPicker></mat-datepicker>
      </mat-form-field>

      <ng-container *ngIf="'SU' | someRole">
        <mat-checkbox [(ngModel)]="importArgs.use_import_2">Use Import v2</mat-checkbox>
      </ng-container>

      <br>

      <ng-container *ngIf="true"
        ><!-- for repaint component -->
        <app-import-form-kendo
          [action]='importArgs.use_import_2 ? "import2" : "import"'
          [args]="importArgs"
          [disabled]="importArgs.source === 'LGTC.REMITTANCE' && !importArgs.payment_date"></app-import-form-kendo>
      </ng-container>

      <pre *ngIf="!env.production">{{ importArgs | json }}</pre>
    </mat-card>

    <ng-container *ngIf="(['SU', 'MANAGER'] | someRole) && tenantType === 'BASE'">
      <mat-card>
        <div class="dx-fieldset">
          <dx-toolbar>
            <dxi-item text="From" [location]="'before'">
              <dx-date-box [(value)]="selectedStartValue"></dx-date-box>
            </dxi-item>

            <dxi-item text="-" [location]="'before'"></dxi-item>

            <dxi-item text="To" [location]="'before'">
              <dx-date-box [(min)]="selectedStartValue" [(value)]="selectedEndValue"></dx-date-box>
            </dxi-item>

            <dxi-item [location]="'before'">
              <dx-button
                type="normal"
                text="Fill Broker Trips Data"
                (onClick)="fillBrokerTrips_onClick($event)"></dx-button>
            </dxi-item>
          </dx-toolbar>
        </div>
      </mat-card>
    </ng-container>

    <app-file-uploads></app-file-uploads>
  </div>
</app-content-w-header>
