import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MyCommonModule } from '../../shared/modules/my-common/my-common.module';
import { UiModule } from '../../shared/modules/ui/ui.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SettingsComponent } from './containers/settings/settings.component';
import { SettingsFormComponent } from './components/settings-form/settings-form.component';
import { DevicesGridComponent } from './components/devices-grid/devices-grid.component';
import { FuelCardsGridComponent } from './components/fuel-cards-grid/fuel-cards-grid.component';
import { DlgImportCardComponent } from './components/dlg-import-card/dlg-import-card.component';
import { DestinationsGridComponent } from './components/destinations-grid/destinations-grid.component';
import { FuelCardTxsGridComponent } from './components/fuel-card-txs-grid/fuel-card-txs-grid.component';
import { AcquisitionContactsComponent } from './components/acquisition-contacts/acquisition-contacts.component';
import { NotificationsFormComponent } from './components/notifications-form/notifications-form.component';
import { ScheduleSettingsComponent } from '../schedule/components/schedule-settings/schedule-settings.component';
import { AutoRepairShopGridComponent } from './components/auto-repair-shop-grid/auto-repair-shop-grid.component';
import { DlgPushToMobileComponent } from './dialogs/dlg-push-to-mobile/dlg-push-to-mobile.component';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, MyCommonModule, UiModule],
  declarations: [
    SettingsComponent,
    SettingsFormComponent,
    NotificationsFormComponent,
    DevicesGridComponent,
    DestinationsGridComponent,
    AutoRepairShopGridComponent,
    FuelCardsGridComponent,
    FuelCardTxsGridComponent,
    DlgImportCardComponent,
    AcquisitionContactsComponent,
    ScheduleSettingsComponent,
    DlgPushToMobileComponent,
  ],
  exports: [SettingsComponent, DlgPushToMobileComponent],
  entryComponents: [
    SettingsComponent,
    DestinationsGridComponent,
    AutoRepairShopGridComponent,
    DlgImportCardComponent,
    DlgPushToMobileComponent,
  ],
})
export class SettingsModule {}
