import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SplitterPaneComponent } from '@progress/kendo-angular-layout';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { DxPopupComponent } from 'devextreme-angular/ui/popup';
import { DxTooltipComponent } from 'devextreme-angular/ui/tooltip';
import DevExpress from 'devextreme/bundles/dx.all';
import Guid from 'devextreme/core/guid';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import query from 'devextreme/data/query';
import { confirm } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import { difference, isNumber } from 'lodash-es';
import compact from 'lodash-es/compact';
import get from 'lodash-es/get';
import identity from 'lodash-es/identity';
import isEmpty from 'lodash-es/isEmpty';
import set from 'lodash-es/set';
import sortBy from 'lodash-es/sortBy';
import uniq from 'lodash-es/uniq';
import moment from 'moment';
import { BehaviorSubject, from, iif, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, delay, exhaustMap, filter, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { gqlMongoLoad } from 'src/app/shared/classes/loopback-custom-store/generic/store.utils';
import { oc } from 'ts-optchain';
import { environment } from '../../../../../environments/environment';
//
import { promiseAsDeferred } from '../../../../shared/classes/utils/promise.utils';
import { asShortDate, asWeekday } from '../../../../shared/classes/utils/time.utils';
import { hasAmbTrips } from '../../../../shared/classes/utils/utils';
import { TripPopupPipe } from '../../../../shared/modules/my-common/pipes/trip-popup.pipe';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { StateStoreService } from '../../../../shared/modules/my-common/services/state-store.service';
import { ABaseModelLoaderComponent } from '../../../../shared/modules/ui/components/abstract/a-base-model-loader.component';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import {
  Consumer,
  DriverSchedule,
  Employee,
  Facility,
  FacilityApi,
  LoggerService,
  LoopBackAuth,
  LoopBackFilter,
  TripManifest,
  TripManifestRec,
  Vehicle,
} from '../../../../shared/sdk';
import { getCurrentTenant } from '../../../../store/reducers/sign';
import { ConsumerFormComponent } from '../../../consumer/components/consumer-form/consumer-form.component';
import { HelperService as ConsumerHelperService } from '../../../consumer/services/helper.service';
import { EmployeeFormComponent } from '../../../employee/components/employee-form/employee-form.component';
import { HelperService as EmployeeHelperService } from '../../../employee/services/helper.service';
import { PRINT_MODE, SERVICE_TYPE } from '../../classes/enums';
import { DlgCalendarComponent } from '../../dialogs/dlg-calendar/dlg-calendar.component';
import { DlgEditDailyNoteComponent } from '../../dialogs/dlg-edit-daily-note/dlg-edit-daily-note.component';
import { DlgPrintReportComponent } from '../../dialogs/dlg-print-report/dlg-print-report.component';
import { DlgSelectDriverComponent } from '../../dialogs/dlg-select-driver/dlg-select-driver.component';
import { DlgSelectEscortComponent } from '../../dialogs/dlg-select-escort/dlg-select-escort.component';
import { DlgSelectVehicleComponent } from '../../dialogs/dlg-select-vehicle/dlg-select-vehicle.component';
import { DlgSetTripNumberComponent } from '../../dialogs/dlg-set-trip-number/dlg-set-trip-number.component';
import { HelperService } from '../../services/helper.service';
import DataSourceOptions = DevExpress.data.DataSourceOptions;
import { ScheduleFormComponent } from 'src/app/modules/schedule/components/schedule-form/schedule-form.component';
import { ExtLoopBackAuth } from 'src/app/shared/modules/ext-sdk/services/ext-sdk-auth.service';

@Component({
  selector: 'app-trip-manifest-assignment',
  templateUrl: './trip-manifest-assignment.component.html',
  styleUrls: ['./trip-manifest-assignment.component.scss'],
  providers: [HelperService, ConsumerHelperService, EmployeeHelperService],
})
export class TripManifestAssignmentComponent
  extends ABaseModelLoaderComponent<Employee>
  implements OnInit, OnChanges, OnDestroy
{
  validationSummary: any[];
  grid_stateStoring: any;
  gridTarget: string;
  // loadingVisible = false;
  cellTooltipVisible = true;
  cellTooltipTarget;
  cellTooltipPosition;
  cellTooltipContent$ = of('');
  cellHoverLastKey;

  reloadManifest$: Subject<void> = new Subject<void>();
  //  reloadManifestWithBrokerData$: Subject<void> = new Subject<void>();
  manifest: TripManifest;
  // $manifest$: BehaviorSubject<TripManifest> = new BehaviorSubject(null);
  validateManifest$: Subject<void> = new Subject<void>();

  ds: DataSource;
  driversDSO: DataSourceOptions;
  vehiclesDSO: DataSourceOptions;
  consumerDSO: DataSourceOptions;
  destItems: any[] = [];
  originItems: any[] = [];
  destDS: DataSource = null;
  originDS: DataSource = null;
  serviceTypes: any[];
  isSU$: Observable<boolean>;
  facility: Facility;
  cloneSchedulesWithManifest = true;
  manifestEmplMap = {};
  schedulesMap = {};
  tripsMap = {};
  manifestSettings = { showSubscriptionTrips: false, highlightSubscriptionTrips: false, showDropOffTime: false };

  // matSnackBarRef: MatSnackBarRef<SnackBarCopyManifestComponent>;
  @Input() set selectedDate(date: Date) {
    this.$selectedDate$.next(date);
  }

  $selectedDate$: BehaviorSubject<Date> = new BehaviorSubject<Date>(new Date());

  @Output() manifestCreated: EventEmitter<TripManifest> = new EventEmitter();
  @Output() manifestDeleted: EventEmitter<number> = new EventEmitter();

  @ViewChild(DxDataGridComponent, { static: false }) grid: DxDataGridComponent;
  @ViewChild('popupCreateManifest', { static: false }) popupCreateManifest: DxPopupComponent;
  @ViewChild('splitPane', { static: false }) splitPane: SplitterPaneComponent;
  @ViewChild('tripTooltip', { static: false }) tripTooltip: DxTooltipComponent;

  constructor(
    protected cd: ChangeDetectorRef,
    protected logger: LoggerService,
    public config: ConfigService,
    protected dss: DataSourceService,
    private sss: StateStoreService,
    private common: CommonService,
    private ui: UiService,
    public helper: HelperService,
    private gridHelper: GridHelperService,
    public consumerHelper: ConsumerHelperService,
    public employeeHelper: EmployeeHelperService,
    private viewContainerRef: ViewContainerRef,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    @Inject(LoopBackAuth) protected auth: ExtLoopBackAuth,
  ) {
    super(logger, dss);

    this.isSU$ = this.config.isSU$;
    this.gridTarget = this.ui.genId();
    this.grid_stateStoring = this.sss.buildOptions('04bbfe0a-b69e-42ad-a1a4-57b77e1507d3');

    this.serviceTypes =
      oc(this.config).config.serviceType() === SERVICE_TYPE.MEALS
        ? [SERVICE_TYPE.MEALS]
        : oc(this.config).config.serviceType() === SERVICE_TYPE.PARATRANSIT
          ? difference([...Object.values(SERVICE_TYPE), ...(hasAmbTrips() ? ['AMB_TRIP'] : [])], [SERVICE_TYPE.MEALS])
          : [...Object.values(SERVICE_TYPE), ...(hasAmbTrips() ? ['AMB_TRIP'] : [])];

    this.buildOtherDs();
    this.setSubscriptions();
    this.loadSettings();
  }

  private _tenantType: string;
  get tenantType(): Observable<string> {
    return iif(
      () => !!this._tenantType,
      of(this._tenantType),
      this.config.tenantType$.pipe(
        tap(v => console.log(v)),
        tap(t => (this._tenantType = t)),
      ),
    );
  }

  get dailyNoteVisible(): boolean {
    if (!this.manifest) {
      return false;
    }

    return ((this.manifest.data || []) as TripManifestRec[]).filter(rec => rec.dn && rec.dn.length).length > 0;
  }

  get brokerNoteVisible(): boolean {
    if (!this.manifest) {
      return false;
    }

    return ((this.manifest.data || []) as TripManifestRec[]).filter(rec => rec.bn && rec.bn.length).length > 0;
  }

  protected get ModelClass(): any {
    return Employee;
  }

  protected get filter(): LoopBackFilter {
    return {
      include: ['employeePosition', 'person'],
    };
  }

  calculateVehicleFilterExpression = function (filterValue, selectedFilterOperation, target) {
    const res = this.defaultCalculateFilterExpression.apply(this, arguments);
    res[0] = 'getVehicleTitle';
    return res;
  };

  driver_calculateDisplayValue = (rowData: any): string => {
    // console.log(rowData);
    const employee: Employee = get(rowData, HelperService.REC_FIELD_MAP.employee, undefined);
    return this.employeeHelper.displayExpr(employee);
  };

  calculateDriverFilterExpression = function (filterValue, selectedFilterOperation, target) {
    const res = this.defaultCalculateFilterExpression.apply(this, arguments);
    res[0] = 'getEmployeeFullName';
    return res;
  };

  calculateEscortFilterExpression = function (filterValue, selectedFilterOperation, target) {
    const res = this.defaultCalculateFilterExpression.apply(this, arguments);
    res[0] = 'getEscortFullName';
    return res;
  };

  consumer_calculateDisplayValue = (rowData: any): string => {
    // console.log(rowData);
    const consumer: Consumer = get(rowData, HelperService.REC_FIELD_MAP.consumer, undefined);
    return this.consumerHelper.displayExpr(consumer);
  };

  calculateConsumerFilterExpression = function (filterValue, selectedFilterOperation, target) {
    const res = this.defaultCalculateFilterExpression.apply(this, arguments);
    res[0] = 'getConsumerFullName';
    return res;
  };

  driver_validationCallback = (options: any): boolean => {
    if (!this.modelId && !options.value) {
      options.rule.message = 'Driver not selected';
      return false;
    }

    return true;
  };

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    //    this.reloadManifestWithBrokerData$.next();
    this.reloadManifest$.next();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  // private async showCreateManifestSnackBarAsync(): Promise<void> {
  //   const actionLabels = {
  //     prev: 'Clone Latest',
  //     new: 'Create New',
  //     date: 'From Calendar',
  //   };
  //
  //   const date = this.selectedDate;
  //   let message = `There are no manifests for ${asWeekday(date)}`;
  //   const actions = [];
  //
  //   const prevManifest: TripManifest = await this.helper.api.getLastManifest(asShortDate(date)).toPromise();
  //   if (prevManifest && prevManifest.id) {
  //     message = `There is manifest generated from latest ${asWeekday(date)}`;
  //     actions.push(actionLabels.prev);
  //   }
  //
  //   actions.push(actionLabels.new);
  //   actions.push(actionLabels.date);
  //
  //   this.matSnackBarRef = this.snackBar.openFromComponent<SnackBarCopyManifestComponent>(
  //     SnackBarCopyManifestComponent,
  //     <MatSnackBarConfig>{
  //       viewContainerRef: this.viewContainerRef,
  //       data: {message, actions, prevId: prevManifest && prevManifest.id, date: asShortDate(date)},
  //     });
  //
  //   const subscription: Subscription = this.matSnackBarRef.instance.action.subscribe(async (e) => {
  //     try {
  //       const {action, data} = e;
  //       let newManifest: TripManifest;
  //
  //       switch (action) {
  //         case actionLabels.prev: {
  //           newManifest = await this.helper.api.copyManifest(data.prevId, data.date).toPromise();
  //           break;
  //         }
  //
  //         case actionLabels.new: {
  //           newManifest = new TripManifest({
  //             date: data.date,
  //             data: [],
  //           });
  //           newManifest = await this.helper.api.create<TripManifest>(newManifest).toPromise();
  //           break;
  //         }
  //
  //         case actionLabels.date: {
  //           const result = await this.dialog.open(
  //             DlgCalendarComponent,
  //             {
  //               hasBackdrop: true,
  //               data: {mode: 'DATE_TO_CURRENT'},
  //             }
  //           ).afterClosed().toPromise();
  //           if (result.mode === 'DATE_TO_CURRENT') {
  //             const dateManifest = await this.helper.api.getCurrentManifest(result.date).toPromise();
  //             if (dateManifest && dateManifest.id) {
  //               newManifest = await this.helper.api.copyManifest(dateManifest.id, data.date).toPromise();
  //             } else {
  //               throw new Error('No Manifest for selected date!');
  //             }
  //           }
  //           break;
  //         }
  //       }
  //
  //       if (newManifest && newManifest.id) {
  //         this.manifestCreated.emit(newManifest);
  //         this.snackBar.open('Done!', undefined, {duration: 2000});
  //       }
  //     } catch (err) {
  //       notify(err.message || err, 'error', 3000);
  //     }
  //
  //     this.reloadManifest$.next();
  //   });
  //   this.matSnackBarRef.afterDismissed().toPromise().then(() => subscription.unsubscribe());
  // }

  grid_repaint(): void {
    // setTimeout(() => this.grid && this.grid.instance && this.grid.instance.repaint());
    this.grid.instance.repaint();
  }

  grid_onInitialized(e) {
    this.gridHelper.handle(e.component, {
      // stateStoreGUID: null,
      notifyErrors: true,
      mapper: this.helper.manifest_saveFieldMapper,
      processor: this.manifest_saveDataProcessor,
      onGridRefresh: () => {
        this.reloadManifest$.next();
      },
    });
  }

  grid_onToolbarPreparing(e: any): void {
    // console.log(e);

    e.toolbarOptions.items.unshift(
      // {
      //   name: 'serviceType',
      //   locateInMenu: 'auto',
      //   widget: 'dxSelectBox',
      //   location: 'after',
      //   sortIndex: 10,
      //   showText: 'inMenu',
      //   options: {
      //     icon: 'far fa-changeService',
      //     placeholder: 'Change Service Type',
      //     hint: 'Change Service Type',
      //     dataSource: serviceTypes,
      //     onValueChanged: this.serviceType_valueChanged.bind(this),
      //   }
      // },
      {
        name: 'resetTripNumbers',
        locateInMenu: 'auto',
        widget: 'dxButton',
        location: 'after',
        sortIndex: 9,
        showText: 'inMenu',
        options: {
          icon: 'sorted',
          text: 'Re-set all Trip Numbers by time order',
          hint: 'Re-set all Trip Numbers by time order',
          onClick: () => this.resetTripNumbers(),
        },
      },
      {
        name: 'fillUpBrokerData',
        locateInMenu: 'auto',
        widget: 'dxButton',
        location: 'after',
        sortIndex: 10,
        showText: 'inMenu',
        options: {
          icon: 'far fa-export',
          text: 'Fill Broker Data',
          hint: 'Fill Broker Data',
          onClick: this.fillUpBrokerData_onClick.bind(this),
        },
      },
      {
        name: 'reassignCtrl',
        template: 'reassignCtrl',
        location: 'after',
        sortIndex: 30,
        locateInMenu: 'never',
      },
      {
        name: 'copyManifest',
        locateInMenu: 'auto',
        widget: 'dxButton',
        location: 'after',
        sortIndex: 30,
        showText: 'inMenu',
        options: {
          icon: 'far fa-clone',
          text: 'Clone manifest',
          hint: 'Clone to the other date',
          onClick: this.copyManifest_onClick.bind(this),
        },
      },
      // {
      //   name: 'clearManifest',
      //   locateInMenu: 'auto',
      //   widget: 'dxButton',
      //   location: 'after',
      //   sortIndex: 30,
      //   showText: 'inMenu',
      //   options: {
      //     icon: 'trash',
      //     text: 'Clear manifest',
      //     hint: 'Clear manifest',
      //     onClick: this.clearManifest_onClick.bind(this),
      //   }
      // },
      {
        name: 'printCtrl',
        template: 'printCtrl',
        location: 'after',
        sortIndex: 30,
        locateInMenu: 'never',
      },
      //      {
      //        name: 'importManifest',
      //        locateInMenu: 'auto',
      //        widget: 'dxButton',
      //        location: 'after',
      //        sortIndex: 30,
      //        showText: 'inMenu',
      //        options: {
      //          icon: 'fas fa-file-import',
      //          text: 'Import manifest',
      //          hint: 'Import manifest',
      //          onClick: this.importManifest_onClick.bind(this),
      //        }
      //      },
      {
        name: 'dropManifest',
        locateInMenu: 'auto',
        widget: 'dxButton',
        location: 'after',
        // sortIndex: 99,
        showText: 'inMenu',
        options: {
          type: 'danger',
          icon: 'trash',
          text: 'Drop manifest',
          hint: 'Drop manifest',
          onClick: this.dropManifest_onClick.bind(this),
        },
      },
    );

    e.toolbarOptions.items = sortBy(e.toolbarOptions.items, ['sortIndex']);
  }

  grid_onCellPrepared(e) {
    const noHints = [this.helper.getRecFieldMap.tripId];
    const codes = HelperService.FIELD_VALIDATION_CODES_MAP[e.column.dataField];
    const noHint = noHints.includes(e.column.dataField);
    if (codes) this.helper.processCellWarnings(this.validationSummary, e, codes, noHint);

    if (e.rowType === 'data') {
      if (
        this.manifestSettings.highlightSubscriptionTrips &&
        e.data.__consumer &&
        e.data.__consumer.repetitiveMode === 'SUBSCRIPTION' &&
        (e.column.dataField === this.helper.getRecFieldMap.serviceType ||
          e.column.dataField === this.helper.getRecFieldMap.origin)
      ) {
        (e.cellElement as HTMLElement).style.backgroundColor = '#ccffcc';
      }

      if (
        e.column.dataField === this.helper.getRecFieldMap.mealsUnits1 ||
        e.column.dataField === this.helper.getRecFieldMap.mealsUnits2
      ) {
        const v = isNumber(e.value) ? e.value : 0;

        if (e.data[this.helper.getRecFieldMap.serviceType] !== SERVICE_TYPE.MEALS && v > 0) {
          (e.cellElement as HTMLElement).style.backgroundColor = 'LightCoral';
        } else if (e.data[this.helper.getRecFieldMap.serviceType] === SERVICE_TYPE.MEALS) {
          let v1 = e.data[this.helper.getRecFieldMap.mealsUnits1];
          v1 = this.config.get('meals_Hot_W1759_Enabled', false) && isNumber(v1) ? v1 : 0;

          let v2 = e.data[this.helper.getRecFieldMap.mealsUnits2];
          v2 = this.config.get('meals_Special_W1764_Enabled', false) && isNumber(v2) ? v2 : 0;

          if (!(v1 + v2 > 0)) {
            (e.cellElement as HTMLElement).style.backgroundColor = 'LightCoral';
          }
        }
      }
    }
  }

  grid_onCellHoverChanged(e) {
    // console.log(e);

    if (
      e.rowType === 'data' &&
      [this.helper.getRecFieldMap.tripId, this.helper.getRecFieldMap.broker].includes(e.column.dataField)
    ) {
      const data: TripManifestRec = e.data;

      if (e.eventType === 'mouseout') {
        this.cellTooltipVisible = false;
        this.cellHoverLastKey = e.key;
      } else if (e.eventType === 'mouseover') {
        this.cellTooltipVisible = true;
        this.cellTooltipTarget = e.cellElement;

        if (this.cellHoverLastKey !== e.key) {
          this.cellHoverLastKey = e.key;
          // this.cellTooltipPosition = {of: e.cellElement, offset: {x: e.event.offsetX, y: e.event.offsetY}, at: 'bottom'};
          if (isEmpty(data.b) || isEmpty(data.tId)) this.cellTooltipContent$ = of('No Trip ID');
          else {
            let issuesHtml = '';
            const codes = HelperService.FIELD_VALIDATION_CODES_MAP[this.helper.getRecFieldMap.tripId];
            if (codes) {
              const groups = this.helper.getWarningGroupsWithRecs(this.validationSummary, e, codes);
              issuesHtml = groups
                .map(
                  g =>
                    `<div style="display: inline-block;margin:0 15px 10px 0;">${g.recHint}:<div style="margin-left:15px;">${g.rec.validationError}</div></div>`,
                )
                .join('');
            }
            this.cellTooltipContent$ = new TripPopupPipe(this.dss).transform(
              data.tId,
              data.b,
              this.manifest.date,
              5,
              this.tripsMap[data.tId],
              issuesHtml,
            );
          }
        }
      }
    }
  }

  grid_onContextMenuPreparing(e) {
    if (e.row && e.row.rowType === 'data' && !e.row.isEditing) {
      const eId = oc(e.row.data)[HelperService.REC_FIELD_MAP.employeeId]();
      const escId = oc(e.row.data)[HelperService.REC_FIELD_MAP.escortId]();
      const cId = oc(e.row.data)[HelperService.REC_FIELD_MAP.consumerId]();
      const selectedKeys = oc(this.grid).instance.getSelectedRowKeys()() || [];
      const selectedData = oc(this.grid).instance.getSelectedRowsData()() || [];

      e.items = [
        {
          text: 'Edit Daily Note',
          disabled: cId <= 0,
          onItemClick: () => {
            if (oc(this.manifest).id()) {
              this.dialog
                .open(DlgEditDailyNoteComponent, {
                  // width: '250px',
                  hasBackdrop: true,
                  data: { value: get(e.row.data, HelperService.REC_FIELD_MAP.dailyNote) },
                })
                .afterClosed()
                .toPromise()
                .then(async notes => {
                  try {
                    this.toggleLoadingPanelVisible(true);
                    if (notes !== false) {
                      const recId = oc(e.row.data).id();
                      await this.helper.api
                        .updateByIdRecords(this.manifest.id, recId, { [HelperService.REC_FIELD_MAP.dailyNote]: notes })
                        .toPromise();
                      e.row.data[HelperService.REC_FIELD_MAP.dailyNote] = notes;
                    }
                  } finally {
                    this.toggleLoadingPanelVisible(false);
                  }
                })
                .catch(err => notify(err.message || err, 'error', 5000));
            }
          },
        },
        {
          disabled:
            selectedData.length === 0 ||
            uniq(compact(selectedData.map(r => r[HelperService.REC_FIELD_MAP.employeeId]))).length !== 1,
          text: 'Assign Vehicle',
          onItemClick: () => {
            if (oc(this.manifest).id()) {
              this.dialog
                .open(DlgSelectVehicleComponent, {
                  width: '450px',
                  maxHeight: '650px',
                  hasBackdrop: true,
                  data: { recIds: selectedKeys },
                })
                .afterClosed()
                .pipe(
                  filter(keys => keys !== false && keys && keys.length === 1),
                  map(keys => keys[0]),
                  tap(async vId => {
                    this.toggleLoadingPanelVisible(true);

                    await this.helper.api.assignToVehicle(this.manifest.id, eId, vId).toPromise();

                    this.reloadManifest$.next();
                  }),
                  catchError(err => of(notify(err.message || err, 'error', 5000))),
                  tap(() => this.toggleLoadingPanelVisible(false)),
                  takeUntil(this.$onDestroy$),
                )
                .subscribe();
            }
          },
        },
        {
          disabled: selectedData.length === 0,
          text: 'Assign Driver',
          onItemClick: () => {
            if (oc(this.manifest).id()) {
              this.dialog
                .open(DlgSelectDriverComponent, {
                  width: '450px',
                  maxHeight: '650px',
                  hasBackdrop: true,
                  data: { recIds: selectedKeys },
                })
                .afterClosed()
                .pipe(
                  filter(keys => keys !== false && keys && keys.length === 1),
                  map(keys => keys[0]),
                  tap(async empId => {
                    this.toggleLoadingPanelVisible(true);
                    const fromEmpId = selectedData[0].e;
                    const m = this.manifestEmplMap[fromEmpId];
                    const allTripsForOneEmpl = !selectedData.find(t => t.e !== fromEmpId);
                    const allTripsSelectd =
                      allTripsForOneEmpl &&
                      selectedData.length === m.trips.length &&
                      !selectedData.find(st => !m.trips.find(et => et.id === st.id));
                    await this.helper.api
                      .reassignManifest(this.manifest.id, allTripsSelectd ? fromEmpId : selectedKeys, empId)
                      .toPromise();

                    this.reloadManifest$.next();
                  }),
                  catchError(err => of(notify(err.message || err, 'error', 5000))),
                  tap(() => this.toggleLoadingPanelVisible(false)),
                  takeUntil(this.$onDestroy$),
                )
                .subscribe();
            }
          },
        },
        {
          disabled: selectedData.length === 0,
          text: 'Assign Escort',
          onItemClick: () => {
            if (oc(this.manifest).id()) {
              this.dialog
                .open(DlgSelectEscortComponent, {
                  width: '450px',
                  maxHeight: '650px',
                  hasBackdrop: true,
                  data: { recIds: selectedKeys },
                })
                .afterClosed()
                .pipe(
                  filter(keys => keys !== false && keys && keys.length === 1),
                  map(keys => keys[0]),
                  tap(() => this.toggleLoadingPanelVisible(true)),
                  delay(100),
                  tap(async empId => {
                    for (const key of selectedKeys) {
                      await this.helper.api
                        .updateByIdRecords(this.manifest.id, key, {
                          [this.helper.getRecFieldMap.escortId]: empId,
                        })
                        .toPromise();
                    }

                    this.reloadManifest$.next();
                  }),
                  catchError(err => of(notify(err.message || err, 'error', 5000))),
                  tap(() => this.toggleLoadingPanelVisible(false)),
                  takeUntil(this.$onDestroy$),
                )
                .subscribe();
            }
          },
        },
        {
          disabled: selectedData.length === 0,
          text: 'Set Trip Number',
          onItemClick: () => {
            if (oc(this.manifest).id()) {
              this.dialog
                .open(DlgSetTripNumberComponent, {
                  hasBackdrop: true,
                  data: { recIds: selectedKeys },
                })
                .afterClosed()
                .pipe(
                  filter(tripNumber => tripNumber !== false),
                  tap(async tripNumber => {
                    try {
                      this.toggleLoadingPanelVisible(true);

                      await this.helper.api.setTripNumber(this.manifest.id, selectedKeys, tripNumber).toPromise();

                      this.reloadManifest$.next();
                    } finally {
                      this.toggleLoadingPanelVisible(false);
                    }
                  }),
                  catchError(err => of(notify(err.message || err, 'error', 5000))),
                  tap(() => this.toggleLoadingPanelVisible(false)),
                  takeUntil(this.$onDestroy$),
                )
                .subscribe();
            }
          },
        },
        {
          disabled: selectedData.length === 0,
          text: 'Re-set Trip Numbers by time order',
          onItemClick: () => {
            const empls = [...new Set<number>(selectedData.map(d => d.e))];
            this.resetTripNumbers(empls);
          },
        },
        {
          text: 'Edit Consumer Info',
          disabled: cId <= 0,
          onItemClick: () => {
            this.ui
              .openEditDialog({
                modelId: cId,
                ModelClass: Consumer,
                FormComponentClass: ConsumerFormComponent,
              })
              .afterClosed()
              .toPromise()
              .then(({ id }) => {
                if (id) {
                  this.reloadManifest$.next();
                }
              });
          },
        },
        {
          text: 'Edit Employee',
          visible: false,
          disabled: eId <= 0,
          onItemClick: () => {
            this.ui
              .openEditDialog({
                modelId: eId,
                ModelClass: Employee,
                FormComponentClass: EmployeeFormComponent,
              })
              .afterClosed()
              .toPromise()
              .then(({ id }) => {
                if (id) {
                  this.reloadManifest$.next();
                }
              });
          },
        },
        {
          text: 'Show Map',
          visible: true,
          onItemClick: () => {
            this.splitPane.collapsed = false;

            // void this.dialog.open(DlgMapComponent, {
            //   width: '60vw',
            //   // height: '80vh',
            //   hasBackdrop: true,
            //   data: {manifestId: oc(this.manifest).id(), recIds: selectedKeys},
            // })
            //   .afterClosed()
            //   .toPromise();
          },
        },
        {
          text: 'Get MTM transportation form',
          disabled: cId <= 0,
          onItemClick: () => {
            this.toggleLoadingPanelVisible(true);
            void this.consumerHelper
              .getMtmTranspForm(cId, {
                date: this.manifest.date,
                manifestRecId: oc(e.row.data).id(),
              })
              .toPromise()
              .catch(err => notify(err.message || err, 'error', 5000))
              .finally(() => this.toggleLoadingPanelVisible(false));
          },
        },
        {
          text: 'Get Broker Trip Data',
          visible: false,
          onItemClick: () => {
            void this.helper.api
              .getBrokerTrips(this.manifest.id, selectedKeys)
              .pipe(tap(data => console.log(data)))
              .toPromise();
          },
        },
      ];
    }
  }

  grid_onInitNewRow(e) {
    e.data[HelperService.REC_FIELD_MAP.serviceType] =
      oc(this.config).config.serviceType() === SERVICE_TYPE.MEALS ? SERVICE_TYPE.MEALS : SERVICE_TYPE.ADC_TRIP;
  }

  grid_onEditingStart(e) {
    //    if (
    //      !isEmpty(e.data[this.helper.getRecFieldMap.broker]) &&
    //      [
    //        this.helper.getRecFieldMap.consumerId,
    //        this.helper.getRecFieldMap.tripId,
    //        this.helper.getRecFieldMap.broker,
    //        this.helper.getRecFieldMap.appointmentTime,
    //      ].includes(e.column.dataField)
    //    ) {
    //      e.cancel = true;
    //    }
  }

  async grid_onRowUpdating(e) {
    if (HelperService.REC_FIELD_MAP.consumerId in e.newData) {
      e.newData[HelperService.REC_FIELD_MAP.broker] = null;
      e.newData[HelperService.REC_FIELD_MAP.tripId] = null;
      e.newData[HelperService.REC_FIELD_MAP.appointmentTime] = null;
      e.newData[HelperService.REC_FIELD_MAP.dailyNote] = null;
      e.newData[HelperService.REC_FIELD_MAP.dropOffTime] = null;
      e.newData[HelperService.REC_FIELD_MAP.mealsUnits1] = null;
      e.newData[HelperService.REC_FIELD_MAP.mealsUnits2] = null;
    }
    if (HelperService.REC_FIELD_MAP.tripId in e.newData) {
      const broker = e.oldData[this.helper.getRecFieldMap.broker];
      const tripId = e.newData[HelperService.REC_FIELD_MAP.tripId];
      this.tripsMap[tripId] = this.tripsMap[tripId] || (await this.getTrips(broker, [tripId]))[0];
    }
  }

  async grid_onSaved(e) {
    const d = e.changes[0].data;
    const schedule = this.schedulesMap[d.e] || (await this.getDriverSchedules([d.e]))[0];
    e.changes[0].data.schedule = schedule;
  }

  resetTripNumbers = async (empls: number[] | null = null) => {
    this.toggleLoadingPanelVisible(true);
    for (const e of empls || Object.keys(this.manifestEmplMap))
      for (const [i, t] of this.manifestEmplMap[e].trips.entries())
        await this.helper.api.setTripNumber(this.manifest.id, [t.id], i + 1).toPromise();
    this.toggleLoadingPanelVisible(false);
    this.reloadManifest$.next();
  };

  validateMealsUnit = e => {
    if (e.data[this.helper.getRecFieldMap.serviceType] !== SERVICE_TYPE.MEALS) return true;

    return true;

    const v = e.value;
    const v1 = e.data[this.helper.getRecFieldMap.mealsUnits1];
    const v2 = e.data[this.helper.getRecFieldMap.mealsUnits2];

    return (isNumber(v) ? v : 0) + (isNumber(v1) ? v : 0) + (isNumber(v2) ? v : 0) > 0;
  };

  //

  originLookup_onValueChanged(cellInfo, e) {
    setTimeout(() => cellInfo.setValue(e.value));
  }

  destLookup_onValueChanged(cellInfo, e) {
    setTimeout(() => cellInfo.setValue(e.value));
  }

  driverDropDown_onValueChanged(cellInfo, e) {
    cellInfo.data[HelperService.REC_FIELD_MAP.employee] = e.data;
    cellInfo.setValue(e.value);

    // auto preset vehicle
    //    this.manifest
  }

  editSchedule_onClick({ data: d }) {
    const dt = moment(this.$selectedDate$.value).format('M/D/YYYY');
    const n = d.getEmployeeFullName();
    const m: any = this.manifest;
    const v = (d.firstTrip && d.firstTrip.__vehicle && `, Vehicle: #${d.firstTrip.__vehicle.internalId}`) || '';
    const title = `Schedule for ${dt} - ${n}${v}`;
    const data = {
      ...d.__employee,
      schedule: d.schedule,
      facilityId: this.facility.id,
      facility: this.facility,
      firstTripConsumer: d.firstTrip && d.firstTrip.__consumer,
      lastTripConsumer: d.lastTrip && d.lastTrip.__consumer,
      manifest: { ...this.manifest, firstTrip: d.firstTrip, lastTrip: d.lastTrip },
    };
    this.ui
      .openEditDialog(
        {
          modelId: (d.schedule && d.schedule._id) || null,
          inputs: {
            data,
            selectedDate: this.$selectedDate$.value,
            facilityMap: { [this.facility.id]: this.facility },
          },
          ModelClass: DriverSchedule,
          FormComponentClass: ScheduleFormComponent,
          title,
        },
        { minWidth: 1200 },
      )
      .afterClosed()
      .toPromise()
      .then(schedule => {
        if (schedule) this.reloadManifest$.next();
      });
  }

  escortDropDown_onValueChanged(cellInfo, e) {
    cellInfo.data[HelperService.REC_FIELD_MAP.escort] = e.data;
    cellInfo.setValue(e.value);

    // auto preset vehicle
    //    this.manifest
  }

  vehicleDropDown_onValueChanged(cellInfo, e) {
    cellInfo.setValue(e.value);
    cellInfo.data[HelperService.REC_FIELD_MAP.vehicle] = e.data;
  }

  consumerDropDown_onValueChanged(cellInfo, e) {
    cellInfo.data[HelperService.REC_FIELD_MAP.consumer] = e.data;
    cellInfo.setValue(e.value);
  }

  serviceType_valueChanged(e) {
    if (this.manifest && this.manifest.id) {
      const manifestId = this.manifest.id;

      of(true)
        .pipe(
          tap(() => this.common.incPending()),
          switchMap(() => this.helper.api.patchAttributes(manifestId, { serviceType: e.value } as TripManifest)),
          tap(() => {
            this.manifest.serviceType = e.value;
          }),
          catchError(err => of(notify(err.message || err, 'error'))),
          tap(() => this.common.decPending()),
          takeUntil(this.$onDestroy$),
        )
        .toPromise();
    }
  }

  fillUpBrokerData_onClick() {
    if (this.manifest && this.manifest.id) {
      this.toggleLoadingPanelVisible(true);
      this.helper
        .tripsFromBrokerToManifestJob(this.manifest)
        .then(id => {
          if (id) {
            this.reloadManifest$.next();
          }
        })
        .catch(err => notify(err.message || err, 'error', 9000))
        .then(() => this.toggleLoadingPanelVisible(false));
    }
  }

  copyManifest_onClick() {
    if (this.manifest && this.manifest.id) {
      this.dialog
        .open(DlgCalendarComponent, {
          // width: '250px',
          hasBackdrop: true,
          data: { mode: 'CURRENT_TO_DATE' },
        })
        .afterClosed()
        .toPromise()
        .then(async result => {
          try {
            this.toggleLoadingPanelVisible(true);
            const newManifest =
              result && result.mode === 'CURRENT_TO_DATE'
                ? await this.helper.api.copyManifest(this.manifest.id, result.date).toPromise()
                : null;
            if (newManifest) {
              this.manifestCreated.emit(newManifest);
              this.snackBar.open('Done!', undefined, { duration: 2000 });
            }
          } finally {
            this.toggleLoadingPanelVisible(false);
          }
        })
        .catch(err => notify(err.message || err, 'error', 3000));
    }
  }

  async clearManifest_onClick() {
    if (oc(this).manifest.id()) {
      const manifestId = this.manifest.id;
      const title = 'Confirm clear';
      const msg = 'Are you sure you want to clear manifest records?';

      await from(confirm(msg, title))
        .pipe(
          filter(identity),
          switchMap(() => this.helper.api.patchAttributes(manifestId, { data: [] })),
          tap(() => this.reloadManifest$.next()),
        )
        .toPromise();
    }
  }

  async dropManifest_onClick() {
    if (oc(this.manifest).id()) {
      const manifestId = this.manifest.id;
      const title = 'Confirm delete';
      const msg = 'Are you sure want to drop current manifest?';

      await from(confirm(msg, title))
        .pipe(
          first(),
          filter(identity),
          switchMap(() => this.helper.api.deleteById(manifestId)),
          tap(() => {
            this.manifestDeleted.emit(manifestId);
            // this.manifestCreated.emit(null);
            this.reloadManifest$.next();
          }),
          catchError(err => of(notify(err.message || err, 'error', 5000))),
          takeUntil(this.$onDestroy$),
        )
        .subscribe();
    }
  }

  printBtn_onAction(action) {
    if (!this.manifest.id) {
      notify('No manifest exists', 'error', 3000);
      return;
    }

    const { id: mode, settings: subSettings } = action;
    subSettings.selectedKeys = this.grid.selectedRowKeys;

    if (
      [
        PRINT_MODE.MERCY_SIGN_IN_FORM_WO_CALC_TIMES_FOR_ONE,
        PRINT_MODE.MERCY_SIGN_IN_FORM_WO_CALC_TIMES_FOR_ONE_FILLED,
      ].includes(mode)
    ) {
      this.dialog
        .open(DlgPrintReportComponent, {
          width: '450px',
          hasBackdrop: true,
          data: { mode },
        })
        .afterClosed()
        .toPromise()
        .then(async result => {
          console.log(result);
          if (result) {
            const settings = {
              ...subSettings,
              ...result,
              mode,
            };

            this.toggleLoadingPanelVisible(true);
            this.helper
              .printManifestAsync(this.manifest, settings)
              .catch(err => notify(err.message || err, 'error', 8000))
              .then(() => this.toggleLoadingPanelVisible(false));
          }
        })
        .catch(err => notify(err.message || err, 'error', 3000));
    } else if (['MEALS_BILLING_FILE__KEYSTONE', 'MEALS_BILLING_FILE__ALL'].includes(mode)) {
      const recs: TripManifestRec[] = this.manifest.data;
      const ids: number[] = query(recs || [])
        .groupBy(HelperService.REC_FIELD_MAP.employeeId)
        .toArray()
        .filter(({ key }) => (this.modelId ? key === this.modelId : true))
        // .filter(({key}) => key !== this.employeeHelper.selfEntity.id)
        .map(({ key }) => key);

      const settings = {
        employeeIds: ids,
        ...subSettings,
        mode,
      };

      this.toggleLoadingPanelVisible(true);
      this.helper
        .getXlsxManifestAsync(this.manifest, settings)
        .catch(err => notify(err.message || err, 'error', 8000))
        .then(() => this.toggleLoadingPanelVisible(false));
    } else {
      const recs: TripManifestRec[] = this.manifest.data;
      const ids: number[] = query(recs || [])
        .groupBy(HelperService.REC_FIELD_MAP.employeeId)
        .toArray()
        .filter(({ key }) => (this.modelId ? key === this.modelId : true))
        // .filter(({key}) => key !== this.employeeHelper.selfEntity.id)
        .map(({ key }) => key);

      const settings = {
        employeeIds: ids,
        ...subSettings,
        mode,
      };

      this.toggleLoadingPanelVisible(true);
      this.helper
        .printManifestAsync(this.manifest, settings)
        .catch(err => notify(err.message || err, 'error', 8000))
        .then(() => this.toggleLoadingPanelVisible(false));
    }
  }

  //

  reassign_onReassigned(e) {
    this.reloadManifest$.next();
  }

  private afterDataSourceChanged = () => {
    this.validateManifest$.next();
  };

  private manifest_saveDataProcessor = data => {
    if (!get(data, HelperService.REC_FIELD_MAP.employeeId, null) && this.modelId) {
      set(data, HelperService.REC_FIELD_MAP.employeeId, this.modelId);
    }

    delete data[HelperService.REC_FIELD_MAP.employee];
    delete data[HelperService.REC_FIELD_MAP.escort];
    delete data[HelperService.REC_FIELD_MAP.consumer];

    return data;
  };

  private setSubscriptions(): void {
    const currentTenant$ = this.common.store.select(getCurrentTenant);
    currentTenant$
      .pipe(
        switchMap(async (tenantId: number) => {
          this.facility = await this.dss
            .getApi<FacilityApi>(Facility)
            .findById<Facility>(tenantId, { include: [{ contact: ['addresses'] }] })
            .toPromise();
          const destinations = await this.helper.getDestinationsDsAsync(tenantId);
          const origins = destinations;

          this.destItems = destinations;
          this.destDS = new DataSource({
            store: new ArrayStore({
              data: destinations,
              key: 'ID',
            }),
            group: 'Group',
          });

          this.originItems = origins;
          this.originDS = new DataSource({
            store: new ArrayStore({
              data: origins,
              key: 'ID',
            }),
            group: 'Group',
          });
        }),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();

    const loadManifest$ = (source: Observable<void>) =>
      source.pipe(
        tap(() => this.ui.showLoading()),
        switchMap(() => this.helper.api.getCurrentManifest(asShortDate(this.$selectedDate$.value))),
        switchMap(async (manifest: TripManifest) => {
          const ds: DataSource = await this.buildMainDsAsync(manifest);

          if (manifest.data) {
            const facilityId = this.facility.id;
            this.manifestEmplMap = {};
            manifest.data.forEach(trip => {
              if (trip.e && trip.s !== -1) {
                const m = this.manifestEmplMap[trip.e];
                const toAdc = trip.d !== 'RESIDENCE';
                this.manifestEmplMap[trip.e] = {
                  firstTrip:
                    (toAdc && (!m || !m.firstTrip || m.firstTrip.t > trip.t) && { facilityId, ...trip }) ||
                    (m && m.firstTrip),
                  lastTrip:
                    (!toAdc && (!m || !m.lastTrip || m.lastTrip.t < trip.t) && { facilityId, ...trip }) ||
                    (m && m.lastTrip),
                  trips: [...((m && m.trips) || []), trip],
                };
              }
            });
            Object.values(this.manifestEmplMap).forEach((m: any) =>
              m.trips.sort((ta, tb) => (ta.t < tb.t ? -1 : ta.t > tb.t ? 1 : 0)),
            );
            const driverIds = [...new Set(manifest.data.map(d => d[this.helper.getRecFieldMap.employeeId]))];
            const tripIds = [...new Set(manifest.data.map(d => d[this.helper.getRecFieldMap.tripId]))];
            const broker = manifest.data[0] && manifest.data[0][this.helper.getRecFieldMap.broker];
            const [schs, trps] = await Promise.all([
              this.getDriverSchedules(driverIds),
              this.getTrips(broker, tripIds),
            ]);
            this.schedulesMap = schs.reduce((p, v) => ({ ...p, [v.driverId]: v }), {});
            this.tripsMap = trps.reduce((p, v) => ({ ...p, [v._tripId]: v }), {});
            manifest.data.forEach(d => {
              d.schedule = this.schedulesMap[d[this.helper.getRecFieldMap.employeeId]];
              const m = this.manifestEmplMap[d[this.helper.getRecFieldMap.employeeId]];
              if (m) {
                d.firstTrip = m.firstTrip;
                d.lastTrip = m.lastTrip;
              }
            });
          }

          this.manifest = manifest;
          this.ds = ds;
          return manifest;
        }),
        tap(() => this.ui.hideLoading()),
        catchError(err => {
          this.ui.hideLoading();
          return throwError(err);
        }),
      );

    //    const loadManifestWithBrokerData$ = (source: Observable<void>) =>
    //      source.pipe(
    //        tap(() => this.ui.showLoading()),
    //        switchMap(() => this.helper.api.getCurrentManifest(asShortDate(this.$selectedDate$.value))),
    //        switchMap(async (manifest: TripManifest) => {
    //          if (manifest && manifest.id) {
    //            await this.helper.api.fillUpBrokerData(manifest.id).toPromise();
    //            manifest = await this.helper.api.findById<TripManifest>(manifest.id).toPromise();
    //          }
    //
    //          const ds: DataSource = await this.buildMainDsAsync(manifest);
    //          this.manifest = manifest;
    //          this.ds = ds;
    //          return manifest;
    //        }),
    //        tap(() => this.ui.hideLoading()),
    //        catchError((err) => {
    //          this.ui.hideLoading();
    //          return of(err);
    //        }),
    //      );

    this.reloadManifest$
      .pipe(
        // prepare
        switchMap(async () => {
          if (this.popupCreateManifest && this.popupCreateManifest.instance) {
            this.popupCreateManifest.instance.hide();
          }

          this.ds = new DataSource([]);
        }),
        // process
        loadManifest$,
        // finally
        switchMap(async (manifest: TripManifest) => {
          if (this.grid && this.grid.instance) {
            this.grid.instance.pageIndex(0);
          }

          if (!oc(manifest).id()) {
            await this.showCreateManifestPopupAsync();
          }
        }),
        catchError(err => of(notify(err.message || err, 'error', 3000))),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();

    this.validateManifest$
      .pipe(
        tap(() => this.grid.instance.beginCustomLoading('Validating...')),
        exhaustMap(() =>
          this.helper.validateManifest$(this.manifest).pipe(
            tap(res => {
              this.validationSummary = [
                ...this.helper.validateManifest(this.auth.getCurrentTenant(), this.manifest, this.tripsMap),
                ...res,
              ];
              this.grid_repaint();
            }),
            catchError(err => of(notify(err.message || err, 'error', 5000))),
          ),
        ),
        tap(() => this.grid.instance.endCustomLoading()),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();

    //      this.reloadManifestWithBrokerData$.pipe(
    //        // prepare
    //        switchMap(async () => {
    //          if (this.popupCreateManifest && this.popupCreateManifest.instance)
    //            this.popupCreateManifest.instance.hide();
    //
    //          this.ds = new DataSource([]);
    //        }),
    //        // process
    //        loadManifestWithBrokerData$,
    //        // finally
    //        switchMap(async (manifest: TripManifest) => {
    //          if (this.grid && this.grid.instance) {
    //            this.grid.instance.pageIndex(0);
    //          }
    //
    //          if (!get(manifest, 'id')) {
    //            await this.showCreateManifestPopupAsync();
    //          }
    //        }),
    //        catchError(err => of(notify(err.message || err, 'error', 3000))),
    //        takeUntil(this.$onDestroy$),
    //      ).subscribe();
  }

  private async buildMainDsAsync(manifest: TripManifest): Promise<DataSource> {
    const as: ArrayStore = await this.helper.buildArrayStoreAsync(manifest);

    const dso: DataSourceOptions = {
      store: as,
      filter: this.modelId ? [HelperService.REC_FIELD_MAP.employeeId, '=', this.modelId] : undefined,
      onChanged: this.afterDataSourceChanged,
    } as DataSourceOptions;

    return new DataSource(dso);
  }

  private buildOtherDs(): void {
    this.driversDSO = {
      store: this.dss.getStore(Employee, null, false),
      filter: [this.helper.buildDriversFilter('employeePosition_name'), ['status', '=', 'ACTIVE']],
      postProcess: (data: any[]): any[] => {
        if (!data.filter(item => item.id === this.employeeHelper.selfEntity.id).length) {
          data.unshift(this.employeeHelper.selfEntity);
        }
        return data;
      },
    } as DataSourceOptions;

    const _store_byKey = this.driversDSO.store.byKey;
    this.driversDSO.store.byKey = (key: any): Promise<any> => {
      if (key === this.employeeHelper.selfEntity.id) {
        return promiseAsDeferred(Promise.resolve(this.employeeHelper.selfEntity)).promise() as any;
      } else {
        return _store_byKey.bind(this.ds.store)(key);
      }
    };

    this.vehiclesDSO = {
      store: this.dss.getStore(Vehicle, null, false),
    };

    this.consumerDSO = {
      store: this.dss.getStore(Consumer, null, false),
    };
  }

  private async loadSettings(): Promise<void> {
    this.manifestSettings = (await gqlMongoLoad(this.dss, 'ManifestSettings', {}, []).pipe().toPromise())[0] || {};
  }

  private async showCreateManifestPopupAsync(): Promise<void> {
    const targetDate = this.$selectedDate$.value;
    const actionLabels = {
      prev: `Clone latest ${asWeekday(targetDate)}`,
      date: 'Clone from the other date',
      new: 'Create from scratch',
    };

    const message = `There are no manifests for ${asWeekday(targetDate)}`;
    const actions = [];

    const prevManifest: TripManifest = await this.helper.api.getLastManifest(asShortDate(targetDate)).toPromise();
    if (prevManifest && prevManifest.id) {
      actions.push(actionLabels.prev);
    }

    actions.push(actionLabels.date);
    actions.push(actionLabels.new);

    const doActionAsync = async action => {
      try {
        this.popupCreateManifest.instance.hide();

        let newManifest: TripManifest;
        switch (action) {
          case actionLabels.prev: {
            this.toggleLoadingPanelVisible(true);
            newManifest = await this.helper.api
              .copyManifest(prevManifest.id, asShortDate(targetDate), this.cloneSchedulesWithManifest)
              .toPromise();
            break;
          }

          case actionLabels.new: {
            this.toggleLoadingPanelVisible(true);
            newManifest = new TripManifest({
              requestID: new Guid().toString(),
              date: asShortDate(targetDate),
              data: [],
            });
            newManifest = await this.helper.api.create(newManifest).toPromise();
            break;
          }

          case actionLabels.date: {
            const result = await this.dialog
              .open(DlgCalendarComponent, { hasBackdrop: true, data: { mode: 'DATE_TO_CURRENT' } })
              .afterClosed()
              .toPromise();

            this.toggleLoadingPanelVisible(true);
            if (result && result.mode === 'DATE_TO_CURRENT') {
              const dateManifest = await this.helper.api.getCurrentManifest(result.date).toPromise();
              if (dateManifest && dateManifest.id) {
                newManifest = await this.helper.api
                  .copyManifest(dateManifest.id, asShortDate(targetDate), this.cloneSchedulesWithManifest)
                  .toPromise();
              } else {
                throw new Error('No Manifest for selected date!');
              }
            }
            break;
          }
        }

        if (newManifest && newManifest.id) {
          this.manifestCreated.emit(newManifest);
          this.snackBar.open('Done!', undefined, { duration: 2000 });
        }
      } catch (err) {
        notify(err.message || err, 'error', 5000);
      } finally {
        this.toggleLoadingPanelVisible(false);
        //        this.reloadManifestWithBrokerData$.next();
        this.reloadManifest$.next();
      }
    };

    this.popupCreateManifest.instance.beginUpdate();
    this.popupCreateManifest.container = this.grid.instance.element();
    this.popupCreateManifest.position = { my: 'center', at: 'center', of: `#${this.gridTarget}` || null };
    this.popupCreateManifest.toolbarItems = actions.map(action => ({
      widget: 'dxButton',
      toolbar: 'bottom',
      location: 'center',
      options: { type: 'default', text: action, onClick: () => doActionAsync(action) },
    }));
    this.popupCreateManifest.instance.show();
    this.popupCreateManifest.instance.endUpdate();
  }

  private toggleLoadingPanelVisible(value: boolean): void {
    if (value) {
      this.ui.showLoading();
    } else {
      this.ui.hideLoading();
    }
  }

  async getDriverSchedules(driverIds) {
    const d = this.$selectedDate$.value;
    const $and = [
      { $in: ['$driverId', driverIds] },
      { $gte: ['$date', { $date: { v: moment(d).utc(true).startOf('day') } }] },
      { $lte: ['$date', { $date: { v: moment(d).utc(true).endOf('day') } }] },
    ];
    const stages = [{ $match: { $expr: { $and } } }];
    return await gqlMongoLoad(this.dss, 'DriverSchedule', {}, stages).pipe().toPromise();
  }

  async getTrips(broker, tripIds): Promise<any[]> {
    const d = this.$selectedDate$.value;
    const $and = [
      { $in: ['$_tripId', tripIds] },
      { $eq: ['$_date', { $date: { v: moment(d).utc(true).startOf('day') } }] },
    ];
    const stages = [{ $match: { $expr: { $and } } }];
    const col = `${broker === 'LGTC' ? 'LGTC_' : ''}ExportsDataCache`;

    return await new Promise(async (resolve, reject) => {
      const res: any[] = [];
      gqlMongoLoad(this.dss, col, {}, stages).subscribe(
        data => res.push(...data),
        error => console.error(error),
        () => resolve(res),
      );
    });
    // return await gqlMongoLoad(this.dss, col, {}, stages).pipe().toPromise();
  }
}
