import { Action } from '@ngrx/store';
import { type } from '../../shared/classes/utils/utils';
import { Config } from '../../shared/sdk/models';
import { IEnvVars } from '../../shared/modules/my-common/services/config.service';

export const CHECK_ONLINE = type('[Core] Check Online');
export const SET_ONLINE = type('[Core] Set Online');
export const SET_OFFLINE = type('[Core] Set Offline');

export const INC_PENDING = type('[Core] Inc Pending');
export const DEC_PENDING = type('[Core] Dec Pending');
export const SHOW_LOADING_PANEL = type('[Core] Show Loading Panel');
export const HIDE_LOADING_PANEL = type('[Core] Hide Loading Panel');

export const LOAD_ENVVARS = type('[Core] Load EnvVars');
export const LOAD_ENVVARS_SUCCESS = type('[Core] Load EnvVars Success');
export const LOAD_ENVVARS_FAIL = type('[Core] Load EnvVars Fail');

export const LOAD_CONFIG = type('[Core] Load Config');
export const LOAD_CONFIG_SUCCESS = type('[Core] Load Config Success');
export const LOAD_CONFIG_FAIL = type('[Core] Load Config Fail');

export const SAVE_CONFIG = type('[Core] Save Config');
export const SAVE_CONFIG_SUCCESS = type('[Core] Save Config Success');
export const SAVE_CONFIG_FAIL = type('[Core] Save Config Fail');

export const SET_CONFIG = type('[Core] Set Config');

export const SET_ALERT = type('[Core] Set Alert');
export const DROP_ALERT = type('[Core] Drop Alert');
export const CLEAR_ALERTS = type('[Core] Clear Alerts');

/**
 * Core Actions
 */

export class SetOnline implements Action {
  readonly type = SET_ONLINE;

  constructor() {}
}

export class SetOffline implements Action {
  readonly type = SET_OFFLINE;

  constructor() {}
}

export class CheckOnline implements Action {
  readonly type = CHECK_ONLINE;

  constructor() {}
}

export class IncPending implements Action {
  readonly type = INC_PENDING;

  constructor() {}
}

export class DecPending implements Action {
  readonly type = DEC_PENDING;

  constructor() {}
}

export class ShowLoadingPanel implements Action {
  readonly type = SHOW_LOADING_PANEL;

  constructor() {}
}

export class HideLoadingPanel implements Action {
  readonly type = HIDE_LOADING_PANEL;

  constructor() {}
}

/**
 * Load Env Vars
 */

export class LoadEnvVars implements Action {
  readonly type = LOAD_ENVVARS;

  constructor() {}
}

export class LoadEnvVarsSuccess implements Action {
  readonly type = LOAD_ENVVARS_SUCCESS;

  constructor(public payload: IEnvVars) {}
}

export class LoadEnvVarsFail implements Action {
  readonly type = LOAD_ENVVARS_FAIL;

  constructor(public payload: any) {}
}

/**
 * Load Config
 */

export class LoadConfig implements Action {
  readonly type = LOAD_CONFIG;

  constructor() {}
}

export class LoadConfigSuccess implements Action {
  readonly type = LOAD_CONFIG_SUCCESS;

  constructor(public payload: Config) {}
}

export class LoadConfigFail implements Action {
  readonly type = LOAD_CONFIG_FAIL;

  constructor(public payload: any) {}
}

/**
 * Save Config
 */

export class SaveConfig implements Action {
  readonly type = SAVE_CONFIG;

  constructor(public payload: Config) {}
}

export class SaveConfigSuccess implements Action {
  readonly type = SAVE_CONFIG_SUCCESS;

  constructor(public payload: Config) {}
}

export class SaveConfigFail implements Action {
  readonly type = SAVE_CONFIG_FAIL;

  constructor(public payload: any) {}
}

/**
 * Set Config
 */

export class SetConfig implements Action {
  readonly type = SET_CONFIG;

  constructor(public payload: Config) {}
}

/**
 * Alerts
 */

export class SetAlert implements Action {
  readonly type = SET_ALERT;

  constructor(public payload: { [key: string]: any }) {}
}

export class DropAlert implements Action {
  readonly type = DROP_ALERT;

  constructor(public payload: string) {}
}

export class ClearAlerts implements Action {
  readonly type = CLEAR_ALERTS;

  constructor() {}
}

export type Actions =
  | CheckOnline
  | SetOnline
  | SetOffline
  | IncPending
  | DecPending
  | ShowLoadingPanel
  | HideLoadingPanel
  | LoadEnvVars
  | LoadEnvVarsSuccess
  | LoadEnvVarsFail
  | LoadConfig
  | LoadConfigSuccess
  | LoadConfigFail
  | SaveConfig
  | SaveConfigSuccess
  | SaveConfigFail
  | SetConfig
  | SetAlert
  | DropAlert
  | ClearAlerts;
