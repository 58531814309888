import * as tslib_1 from "tslib";
import { FormBuilder } from '@angular/forms';
import notify from 'devextreme/ui/notify';
//
import { LoggerService, MyUserView, MyUtils } from '../../../../shared/sdk';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { HelperService as EmployeeHelperService } from '../../../employee/services/helper.service';
import { gqlMongoLoad } from 'src/app/shared/classes/loopback-custom-store/generic/store.utils';
import { intersection } from 'lodash-es';
export class NotificationsFormComponent extends ABaseComponent {
    constructor(logger, common, fb, dss, config, employeeHelper) {
        super(logger);
        this.logger = logger;
        this.common = common;
        this.fb = fb;
        this.dss = dss;
        this.config = config;
        this.employeeHelper = employeeHelper;
        this.processing = false;
        this.usersDso$ = [];
        this.formSettingsMap = new Map();
        this.displayExpr = (u) => `${this.employeeHelper.displayExpr(u) || u.username} - ${u.email}`;
        this.buildDataSource();
        this.buildForm();
        this.loadSettings();
    }
    form_ngSubmit(e) {
        e.preventDefault();
        void (() => tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.processing = true;
            if (this.form.valid) {
                const data = this.form.value;
                yield this.dss.getApi(MyUtils).mongoSaveNotificationSettings(data).toPromise();
                this.form.markAsPristine();
                notify('Done!', 'success');
            }
            else {
                notify('There are not valid fields', 'warning', 5000);
            }
        }))()
            .catch(err => notify(err.message, 'error', 5000))
            .finally(() => (this.processing = false));
        return false;
    }
    reset_onClick(e) {
        setTimeout(() => this.form.reset(this.defaultData));
    }
    buildForm() {
        this.formSettingsMap.set('', {
            opsAlertUsers: [],
            opsAlertUsersIndividual: [],
            opsAlertUsersIndividualSms: [],
            opsAlertUsersCheckedInWithOtherVehicle: [],
            opsAlertUsersNewClientAutoCreated: [],
            opsAlertUsersMinutesLate: [],
            opsAlertUsersIndividualMinutesLate: [],
            fleetStatusReportUsers: [],
            fleetBackupReportUsers: [],
            fleetMaintenanceReportUsers: [],
            hrNotificationUsers: [],
            unresolvedEtdIssuesUsers: [],
            vehicleStatusChangeNotificationUsers: [],
            vehicleBackup2AssignedAutoChangeNotificationUsers: [],
        });
        this.form = this.fb.group(this.formSettingsMap.get(''));
        this.defaultData = this.form.value;
    }
    buildDataSource() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const users = yield this.dss
                .getApi(MyUserView)
                .find({ where: { status: 'ACTIVE' }, order: ['person_firstname ASC', 'person_lastname ASC', 'username ASC'] })
                .toPromise();
            this.usersDso$ = users.filter(({ _roles }) => intersection(['SU', 'CLERK', 'MANAGER', 'BILLER'], _roles).length);
        });
    }
    loadSettings() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const settings = (yield gqlMongoLoad(this.dss, 'NotificationSettings', {}, []).pipe().toPromise())[0] || {};
            this.form.get('opsAlertUsers').setValue(settings.opsAlertUsers);
            this.form.get('opsAlertUsersIndividual').setValue(settings.opsAlertUsersIndividual);
            this.form.get('opsAlertUsersIndividualSms').setValue(settings.opsAlertUsersIndividualSms);
            this.form.get('opsAlertUsersCheckedInWithOtherVehicle').setValue(settings.opsAlertUsersCheckedInWithOtherVehicle);
            this.form.get('opsAlertUsersNewClientAutoCreated').setValue(settings.opsAlertUsersNewClientAutoCreated);
            this.form.get('opsAlertUsersMinutesLate').setValue(settings.opsAlertUsersMinutesLate);
            this.form.get('opsAlertUsersIndividualMinutesLate').setValue(settings.opsAlertUsersIndividualMinutesLate);
            this.form.get('fleetStatusReportUsers').setValue(settings.fleetStatusReportUsers);
            this.form.get('fleetBackupReportUsers').setValue(settings.fleetBackupReportUsers);
            this.form.get('fleetMaintenanceReportUsers').setValue(settings.fleetMaintenanceReportUsers);
            this.form.get('hrNotificationUsers').setValue(settings.hrNotificationUsers);
            this.form.get('unresolvedEtdIssuesUsers').setValue(settings.unresolvedEtdIssuesUsers);
            this.form.get('vehicleStatusChangeNotificationUsers').setValue(settings.vehicleStatusChangeNotificationUsers);
            this.form
                .get('vehicleBackup2AssignedAutoChangeNotificationUsers')
                .setValue(settings.vehicleBackup2AssignedAutoChangeNotificationUsers);
        });
    }
}
