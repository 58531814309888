<div class="title">Acceped for {{ message?.broker?.firstName }}, {{ message?.incomingAt | date : 'short' }}</div>

<dx-data-grid
  #grid
  height="100%"
  width="auto"
  [dataSource]="dso"
  [remoteOperations]="false"
  (onToolbarPreparing)="grid_onToolbarPreparing($event)"
  (onContextMenuPreparing)="grid_onContextMenuPreparing($event)"
  [(selectedRowKeys)]="grid_selectedRowKeys"
  (onSelectionChanged)="grid_onSelectionChanged($event)"
  (onCellPrepared)="grid_onCellPrepared($event)">
  <!-- Settings -->

  <dxo-selection selectAllMode="page" showCheckBoxesMode="always" mode="multiple"></dxo-selection>
  <dxo-editing [allowAdding]="false" [allowDeleting]="false" [allowUpdating]="false"></dxo-editing>

  <dxo-export [enabled]="true" fileName="history-export"></dxo-export>

  <!-- Columns -->

  <dxi-column caption="Settings" [calculateDisplayValue]="settings_calculateValue"></dxi-column>
  <dxi-column caption="Status" [calculateDisplayValue]="status_calculateValue"></dxi-column>
  <dxi-column caption="Pick State" dataField="Pick State" dataType="string"></dxi-column>
  <dxi-column caption="Pick County" dataField="Pick County" dataType="string"></dxi-column>
  <dxi-column caption="Date" dataField="Date" dataType="string"></dxi-column>
  <dxi-column caption="APPOINT TIME" dataField="meta.at" dataType="date" format="shortTime"></dxi-column>
  <dxi-column caption="Customer" dataField="Customer" dataType="string"></dxi-column>
  <dxi-column caption="Mode" dataField="consumer.repetitiveMode" dataType="string"></dxi-column>
  <dxi-column caption="Leg" dataField="leg"></dxi-column>
  <dxi-column caption="Driver" dataField="Driver"></dxi-column>
  <dxi-column caption="Proposed Trip Sequesce" dataField="Proposed Trip Sequesce"></dxi-column>
  <dxi-column caption="Vehicle #" dataField="Vehicle"></dxi-column>

  <!-- Templates -->
</dx-data-grid>

