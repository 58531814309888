import { Injectable } from '@angular/core';
import { BaseContainer } from '../../models/BaseContainer';
import { Container } from '../../models/Container';
import { Role } from '../../models/Role';
import { RoleMapping } from '../../models/RoleMapping';
import { ACL } from '../../models/ACL';
import { AccessToken } from '../../models/AccessToken';
import { MyUser } from '../../models/MyUser';
import { MyMessage } from '../../models/MyMessage';
import { Config } from '../../models/Config';
import { State } from '../../models/State';
import { Contact } from '../../models/Contact';
import { Address } from '../../models/Address';
import { Phone } from '../../models/Phone';
import { EmailAddress } from '../../models/EmailAddress';
import { Facility } from '../../models/Facility';
import { Employee } from '../../models/Employee';
import { EmployeePayroll } from '../../models/EmployeePayroll';
import { Consumer } from '../../models/Consumer';
import { Vehicle } from '../../models/Vehicle';
import { Acquisition } from '../../models/Acquisition';
import { Person } from '../../models/Person';
import { Program } from '../../models/Program';
import { FacilityEmPerson } from '../../models/FacilityEmPerson';
import { EmployeeEmPerson } from '../../models/EmployeeEmPerson';
import { ConsumerEmPerson } from '../../models/ConsumerEmPerson';
import { TrainedAtFacility } from '../../models/TrainedAtFacility';
import { AssignedToFacility } from '../../models/AssignedToFacility';
import { Document } from '../../models/Document';
import { DocumentType } from '../../models/DocumentType';
import { EmployeePosition } from '../../models/EmployeePosition';
import { MyFile } from '../../models/MyFile';
import { CustomField } from '../../models/CustomField';
import { ObjectCFV } from '../../models/ObjectCFV';
import { TripManifest } from '../../models/TripManifest';
import { TripManifestRec } from '../../models/TripManifestRec';
import { Signature } from '../../models/Signature';
import { FuelRefill } from '../../models/FuelRefill';
import { DayService } from '../../models/DayService';
import { EmployeeDayService } from '../../models/EmployeeDayService';
import { Covid19Checks } from '../../models/Covid19Checks';
import { Note } from '../../models/Note';
import { Alert } from '../../models/Alert';
import { MyUserView } from '../../models/MyUserView';
import { ContactView } from '../../models/ContactView';
import { FacilityView } from '../../models/FacilityView';
import { EmployeeView } from '../../models/EmployeeView';
import { EmployeeDocExpCntView } from '../../models/EmployeeDocExpCntView';
import { ConsumerView } from '../../models/ConsumerView';
import { ConsumerAddressView } from '../../models/ConsumerAddressView';
import { PersonView } from '../../models/PersonView';
import { DocumentView } from '../../models/DocumentView';
import { CustomFieldValuesView } from '../../models/CustomFieldValuesView';
import { DocumentFieldsView } from '../../models/DocumentFieldsView';
import { LastFileView } from '../../models/LastFileView';
import { LastDocumentWithExpiration } from '../../models/LastDocumentWithExpiration';
import { SignatureView } from '../../models/SignatureView';
import { SignatureConsUniqImgView } from '../../models/SignatureConsUniqImgView';
import { FuelRefillView } from '../../models/FuelRefillView';
import { TripSignatureStats } from '../../models/TripSignatureStats';
import { ActiveClientSignatureView } from '../../models/ActiveClientSignatureView';
import { MealsAdcSignatureStats } from '../../models/MealsAdcSignatureStats';
import { TripSignatureCount } from '../../models/TripSignatureCount';
import { AdcTripSignatureStats } from '../../models/AdcTripSignatureStats';
import { FacilitySignatureStats } from '../../models/FacilitySignatureStats';
import { ByClientsSignatureStats } from '../../models/ByClientsSignatureStats';
import { ByEmployeeSignatureStats } from '../../models/ByEmployeeSignatureStats';
import { VehicleCheckUp } from '../../models/VehicleCheckUp';
import { PostShiftReport } from '../../models/PostShiftReport';
import { MobileDevice } from '../../models/MobileDevice';
import { AcquisitionRelatedContact } from '../../models/AcquisitionRelatedContact';
import { AnyNote } from '../../models/AnyNote';
import { AutoDispatchLog } from '../../models/AutoDispatchLog';
import { AutoRepairShop } from '../../models/AutoRepairShop';
import { FuelCard } from '../../models/FuelCard';
import { VehicleGeotab } from '../../models/VehicleGeotab';
import { VehicleGeotabEvent } from '../../models/VehicleGeotabEvent';
import { DriverSchedule } from '../../models/DriverSchedule';
import { EmployeeWorkingTime } from '../../models/EmployeeWorkingTime';
import { TripInfo } from '../../models/TripInfo';
import { MyUtils } from '../../models/MyUtils';
import { Common } from '../../models/Common';
import { MyJob } from '../../models/MyJob';
import { MyService } from '../../models/MyService';
import { AuthService } from '../../models/AuthService';
import { PersonalPhoto } from '../../models/PersonalPhoto';
import { Router } from '../../models/Router';

export interface Models { [name: string]: any }

@Injectable()
export class SDKModels {

  private models: Models = {
    BaseContainer: BaseContainer,
    Container: Container,
    Role: Role,
    RoleMapping: RoleMapping,
    ACL: ACL,
    AccessToken: AccessToken,
    MyUser: MyUser,
    MyMessage: MyMessage,
    Config: Config,
    State: State,
    Contact: Contact,
    Address: Address,
    Phone: Phone,
    EmailAddress: EmailAddress,
    Facility: Facility,
    Employee: Employee,
    EmployeePayroll: EmployeePayroll,
    Consumer: Consumer,
    Vehicle: Vehicle,
    Acquisition: Acquisition,
    Person: Person,
    Program: Program,
    FacilityEmPerson: FacilityEmPerson,
    EmployeeEmPerson: EmployeeEmPerson,
    ConsumerEmPerson: ConsumerEmPerson,
    TrainedAtFacility: TrainedAtFacility,
    AssignedToFacility: AssignedToFacility,
    Document: Document,
    DocumentType: DocumentType,
    EmployeePosition: EmployeePosition,
    MyFile: MyFile,
    CustomField: CustomField,
    ObjectCFV: ObjectCFV,
    TripManifest: TripManifest,
    TripManifestRec: TripManifestRec,
    Signature: Signature,
    FuelRefill: FuelRefill,
    DayService: DayService,
    EmployeeDayService: EmployeeDayService,
    Covid19Checks: Covid19Checks,
    Note: Note,
    Alert: Alert,
    MyUserView: MyUserView,
    ContactView: ContactView,
    FacilityView: FacilityView,
    EmployeeView: EmployeeView,
    EmployeeDocExpCntView: EmployeeDocExpCntView,
    ConsumerView: ConsumerView,
    ConsumerAddressView: ConsumerAddressView,
    PersonView: PersonView,
    DocumentView: DocumentView,
    CustomFieldValuesView: CustomFieldValuesView,
    DocumentFieldsView: DocumentFieldsView,
    LastFileView: LastFileView,
    LastDocumentWithExpiration: LastDocumentWithExpiration,
    SignatureView: SignatureView,
    SignatureConsUniqImgView: SignatureConsUniqImgView,
    FuelRefillView: FuelRefillView,
    TripSignatureStats: TripSignatureStats,
    ActiveClientSignatureView: ActiveClientSignatureView,
    MealsAdcSignatureStats: MealsAdcSignatureStats,
    TripSignatureCount: TripSignatureCount,
    AdcTripSignatureStats: AdcTripSignatureStats,
    FacilitySignatureStats: FacilitySignatureStats,
    ByClientsSignatureStats: ByClientsSignatureStats,
    ByEmployeeSignatureStats: ByEmployeeSignatureStats,
    VehicleCheckUp: VehicleCheckUp,
    PostShiftReport: PostShiftReport,
    MobileDevice: MobileDevice,
    AcquisitionRelatedContact: AcquisitionRelatedContact,
    AnyNote: AnyNote,
    AutoDispatchLog: AutoDispatchLog,
    AutoRepairShop: AutoRepairShop,
    FuelCard: FuelCard,
    VehicleGeotab: VehicleGeotab,
    VehicleGeotabEvent: VehicleGeotabEvent,
    DriverSchedule: DriverSchedule,
    EmployeeWorkingTime: EmployeeWorkingTime,
    TripInfo: TripInfo,
    MyUtils: MyUtils,
    Common: Common,
    MyJob: MyJob,
    MyService: MyService,
    AuthService: AuthService,
    PersonalPhoto: PersonalPhoto,
    Router: Router,
    
  };

  public get(modelName: string): any {
    return this.models[modelName];
  }

  public getAll(): Models {
    return this.models;
  }

  public getModelNames(): string[] {
    return Object.keys(this.models);
  }
}
