import { AfterViewInit, Component, Inject, Input, OnChanges, OnDestroy, OnInit, Type, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataSourceOptions } from 'devextreme/data/data_source';
import { Observable, of } from 'rxjs';
import {
  AutoDispatchLog,
  Config,
  Consumer,
  ConsumerAddressView,
  TripManifest,
  TripManifestRec,
} from '../../../../shared/sdk/models';
import {
  AutoDispatchLogApi,
  ConfigApi,
  ConsumerAddressViewApi,
  ConsumerApi,
  LoggerService,
} from '../../../../shared/sdk/services/custom';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseFormComponent } from '../../../../shared/modules/ui/components/abstract/a-base-form.component';
import { ABaseModelPaneWToolbarComponent } from '../../../../shared/modules/ui/components/abstract/a-base-model-pane-w-toolbar.component';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import moment, { duration } from 'moment';
import { ABaseComponent } from 'src/app/shared/modules/ui/components/abstract/a-base.component';
import { gqlMongoLoad } from 'src/app/shared/classes/loopback-custom-store/generic/store.utils';
import { headersAllTenantsAppend } from 'src/app/shared/classes/utils/utils';
import { ShowLoopDialogComponent } from 'src/app/shared/modules/ui/components/show-loop-dialog/show-loop-dialog.component';
import dxDataGrid from 'devextreme/ui/data_grid';
import DxDataGrid from 'devextreme/ui/data_grid';
import { HelperService } from '../../../trip-manifest/services/helper.service';
import Guid from 'devextreme/core/guid';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { ExtLoopBackAuth } from '../../../../shared/modules/ext-sdk/services/ext-sdk-auth.service';
import { LoopBackAuth } from '../../../../shared/sdk';

@Component({
  selector: 'app-auto-dispatch-details-tab-accepted',
  templateUrl: './auto-dispatch-details-tab-accepted.component.html',
  styleUrls: ['./auto-dispatch-details-tab-accepted.component.scss'],
  providers: [HelperService],
})
export class AutoDispatchDetailsTabAcceptedComponent extends ABaseComponent {
  @ViewChild('grid', { static: false }) grid: DxDataGridComponent;
  _message: AutoDispatchLog;

  @Input()
  set message(m: AutoDispatchLog) {
    this._message = m;
    this.changeHandle();
  }
  get message() {
    return this._message;
  }

  dso: DataSourceOptions;
  grid_selectedRowKeys: number[] = [];
  selected: any[];
  destinationsMap: any;

  constructor(
    protected logger: LoggerService,
    protected ui: UiService,
    protected dss: DataSourceService,
    protected dialog: MatDialog,
    public helper: HelperService,
    @Inject(LoopBackAuth) private auth: ExtLoopBackAuth,
  ) {
    super(logger);
  }

  async changeHandle() {
    if (this.message) {
      this.message.accepted.forEach(s => {
        s.dt = moment(s.Date, 'MM/DD/YY').format('YYYY-MM-DD');
      });
      const destinations = await this.dss.getApi<ConfigApi>(Config).getAllDestinations().toPromise();
      this.destinationsMap = destinations.reduce(
        (p, d) => (d.address ? { ...p, [d.address.split(',')[0]]: d } : p),
        {},
      );
      await this.loadConsumers(this.message.accepted);
      this.dso = this.message.accepted;
    }
  }

  grid_onSelectionChanged = (event: any) => {
    this.selected = event.selectedRowsData;
  };

  grid_onToolbarPreparing(e: any): void {
    // console.log(e);

    e.toolbarOptions.items.unshift({
      name: 'addToManifest',
      widget: 'dxButton',
      locateInMenu: 'auto',
      location: 'before',
      sortIndex: 30,
      // showText: 'inMenu',
      options: {
        icon: 'plus',
        text: 'Add Selected Trips To Manifest',
        hint: 'Add Selected Trips To Manifest',
        // disabled: !this.model,
        onClick: () => this.addToManifest(),
      },
    });
  }

  grid_onContextMenuPreparing(e) {
    if (e.row && e.row.rowType === 'data' && !e.row.isEditing) {
      this.grid_selectedRowKeys = [e.row.data];
      e.items = [
        {
          text: `Add To Manifest:  ${e.row.data['APPOINT TIME/RR TIME']} - ${e.row.data.Customer}`,
          onItemClick: () => this.addToManifest(),
        },
      ];
    }
  }

  grid_onCellPrepared(e) {
    if (e.rowType === 'data' && e.data.meta.allowed) (e.cellElement as HTMLElement).classList.add('cell-green');
  }

  status_calculateValue = (rowData): string => {
    return rowData.meta && rowData.meta.allowed ? 'On Manifest' : 'Requested';
  };

  settings_calculateValue = (rowData): string => {
    const { ks, puPlus, doPlus }: any = rowData.meta || {};
    return [ks && 'KS', puPlus && 'PU+', doPlus && 'DO+'].filter(Boolean).join(', ');
  };

  async loadManfiests(manifestsMap) {
    const manifests = await Promise.all(
      Object.keys(manifestsMap).map(date => this.helper.api.getCurrentManifest(date).toPromise()),
    );
    manifests.filter(m => m.date).forEach(m => (manifestsMap[m.date] = { ...m, data: [] }));
  }

  getCastomerNameAndAddress(s: any) {
    const [ln, fn] = s.Customer.split(', ');
    const [pick, drop] = ['Pick Location', 'Drop Location'];
    const [rAddr, adcAddr] = s.meta.isReturn ? [s[drop], s[pick]] : [s[pick], s[drop]];
    const [street, city, state, zip] = rAddr.split(/, ?/);
    const resAddr = { street, city, state, zip };
    return [fn, ln, adcAddr, resAddr];
  }

  async createUnexistentConsumers(tenantId) {
    const customersMap = this.selected.reduce((p, s) => {
      const [fn, ln, , resAddr] = this.getCastomerNameAndAddress(s);
      const sn = s['Scheduling Note'] || (p[s.Customer] && p[s.Customer].sn) || '';
      return { ...p, [s.Customer]: { name: s.Customer, consumer: s.consumer, ln, fn, resAddr, sn } };
    }, {});
    const customers: any[] = Object.values(customersMap);
    const consumers = await Promise.all(
      customers.map(({ consumer, ln, fn, resAddr, sn }: any) => {
        if (consumer) return consumer;
        this.dss
          .getApi<ConsumerApi>(Consumer)
          .sendEmailAboutPendingConsumer(`${fn} ${ln}`, resAddr, sn, moment().format('MM/DD/YYYY hh:mm A'))
          .toPromise();
        return this.dss
          .getApi<ConsumerApi>(Consumer)
          .myCreateWithRelated({
            status: 'PENDING',
            tenantId,
            person: { firstname: fn, lastname: ln, contact: { addresses: [resAddr] } },
          })
          .toPromise();
      }),
    );
    consumers.forEach((c: ConsumerAddressView, i) => (customersMap[customers[i].name].consumer = c));
    this.selected.forEach(s => (s.consumer = customersMap[s.Customer].consumer));
  }

  async loadConsumers(accepted) {
    const customersMap = accepted.reduce((p, s) => {
      const [fn, ln, adcAddr, resAddr] = this.getCastomerNameAndAddress(s);
      const adc = this.destinationsMap[adcAddr.split(',')[0]] || { short: adcAddr };
      return { ...p, [s.Customer]: { name: s.Customer, ln, fn, resAddr, adc } };
    }, {});
    const customers: any[] = Object.values(customersMap);
    const conss = await Promise.all(
      customers.map(({ ln, fn, resAddr: { street } }: any) =>
        this.dss
          .getApi<ConsumerAddressViewApi>(ConsumerAddressView)
          .find<ConsumerAddressView>(
            {
              where: {
                person_lastname: { like: `%${ln}%` },
                person_firstname: { like: `%${fn}%` },
                contact_addresses_0_street: { like: `%${street}%` },
                status: 'ACTIVE',
              },
            },
            headersAllTenantsAppend,
          )
          .toPromise(),
      ),
    );
    const consumers: ConsumerAddressView[] = conss.map(([c]) => c);
    consumers.forEach((c: ConsumerAddressView, i) => (customersMap[customers[i].name].consumer = c));
    accepted.forEach(s => {
      s.consumer = customersMap[s.Customer].consumer;
      s.adc = customersMap[s.Customer].adc;
    });
  }

  pushDataToManifestsMap(manifestsMap) {
    this.selected.forEach(({ meta, dt, consumer, adc, leg }) => {
      const [o, d] = meta.isReturn ? [adc.short, 'RESIDENCE'] : ['RESIDENCE', adc.short];
      const [e, c, v, esc] = [meta.employeeId, consumer.id, meta.vehicleId, meta.esc];
      const [t, at, dot] = [meta.t, meta.at, meta.dot];
      const [tr, b, st, rt, rid] = [leg['Proposed Trip Sequesce'], 'MTM', 'AMB_TRIP', false, meta.tripId];
      manifestsMap[dt].data.push({ t, at, dot, tr, s: 0, rt, rid, o, d, b, st, e, c, v, esc });
    });
  }

  async addToManifest() {
    const tenantId = this.auth.getCurrentTenant();
    const manifestsMap = this.selected.reduce(
      (p, { dt }) => ({ ...p, [dt]: { requestID: new Guid().toString(), date: dt, data: [] } }),
      {},
    );
    await this.loadManfiests(manifestsMap);
    await this.createUnexistentConsumers(tenantId);
    this.pushDataToManifestsMap(manifestsMap);
    for (const { id, data } of Object.values<TripManifest>(manifestsMap)) {
      if (!id) continue;
      for (const d of data)
        try {
          const res: TripManifestRec = await this.helper.api.safeSaveRecord(id, d).toPromise();
          // wait for the response a few seconds
          // await new Promise(resolve => setTimeout(resolve, 2000));
          const a = this.message.accepted.find(({ meta }) => meta.tripId === res.rid);
          if (a) a.meta.allowed = true;
        } catch {}
    }
    const manifests = await Promise.all(
      Object.values(manifestsMap).map(({ id, requestID, data, date }: any) =>
        id ? null : this.helper.api.create(new TripManifest({ requestID, date, data })).toPromise(),
      ),
    );

    manifests.forEach(
      m =>
        m &&
        m.data.forEach(d => {
          const a = this.message.accepted.find(({ meta }) => meta.tripId === d.rid);
          if (a) a.meta.allowed = true;
        }),
    );

    await this.updateTripsAllowed();
  }

  async updateTripsAllowed() {
    this.message.tripsAllowed = this.message.accepted.reduce((p, { meta: { allowed } }) => p + +!!allowed, 0);
    await this.dss
      .getApi<AutoDispatchLogApi>(AutoDispatchLog)
      .updateAll({ id: this.message.id }, { tripsAllowed: this.message.tripsAllowed, accepted: this.message.accepted })
      .toPromise();
    this.grid.instance.refresh();
  }
}

