import {
  GeoPoint
} from '../index';

declare var Object: any;
export interface SignatureViewInterface {
  "id": number;
  "arrivedTime"?: string;
  "captured": number;
  "consumer_data"?: any;
  "consumer_eligibility"?: any;
  "consumer_facilityID"?: string;
  "consumer_internalID"?: string;
  "consumer_mci"?: string;
  "consumer_person_data"?: any;
  "consumer_person_dob"?: any;
  "consumer_person_firstname"?: string;
  "consumer_person_lastname"?: string;
  "consumer_status"?: string;
  "consumer_tenantId"?: number;
  "consumer_weekDays"?: any;
  "consumerId"?: number;
  "ctime": Date;
  "dateTime": Date;
  "dateTimeOfService": Date;
  "dropoffTime"?: string;
  "dtime"?: Date;
  "dtime_str"?: string;
  "employee_data"?: any;
  "employee_person_data"?: any;
  "employee_person_dob"?: any;
  "employee_person_firstname"?: string;
  "employee_person_lastname"?: string;
  "employee_status"?: string;
  "employee_tenantId"?: number;
  "employee_tenantIds"?: any;
  "employeeId"?: number;
  "hash"?: string;
  "imgFileId"?: string;
  "location"?: GeoPoint;
  "manifestRecId"?: string;
  "marker"?: string;
  "meta": any;
  "note"?: string;
  "oneWayOnly": number;
  "pickupTime"?: string;
  "requestID": string;
  "scheduledTime"?: string;
  "status"?: string;
  "tenantId": number;
  "type": string;
  "unableToSign": number;
  "userId": number;
  "utime": Date;
  "validationState": string;
  "vAppName"?: string;
  "vdate"?: any;
  "vehicle_internalId"?: number;
  "vehicle_vin"?: string;
  "vehicleId"?: number;
  "vServiceType"?: string;
  "vTotalMealsUnits"?: number;
  "vTripId"?: string;
  "vUnits"?: number;
  "weekday"?: number;
}

export class SignatureView implements SignatureViewInterface {
  "id": number;
  "arrivedTime": string;
  "captured": number;
  "consumer_data": any;
  "consumer_eligibility": any;
  "consumer_facilityID": string;
  "consumer_internalID": string;
  "consumer_mci": string;
  "consumer_person_data": any;
  "consumer_person_dob": any;
  "consumer_person_firstname": string;
  "consumer_person_lastname": string;
  "consumer_status": string;
  "consumer_tenantId": number;
  "consumer_weekDays": any;
  "consumerId": number;
  "ctime": Date;
  "dateTime": Date;
  "dateTimeOfService": Date;
  "dropoffTime": string;
  "dtime": Date;
  "dtime_str": string;
  "employee_data": any;
  "employee_person_data": any;
  "employee_person_dob": any;
  "employee_person_firstname": string;
  "employee_person_lastname": string;
  "employee_status": string;
  "employee_tenantId": number;
  "employee_tenantIds": any;
  "employeeId": number;
  "hash": string;
  "imgFileId": string;
  "location": GeoPoint;
  "manifestRecId": string;
  "marker": string;
  "meta": any;
  "note": string;
  "oneWayOnly": number;
  "pickupTime": string;
  "requestID": string;
  "scheduledTime": string;
  "status": string;
  "tenantId": number;
  "type": string;
  "unableToSign": number;
  "userId": number;
  "utime": Date;
  "validationState": string;
  "vAppName": string;
  "vdate": any;
  "vehicle_internalId": number;
  "vehicle_vin": string;
  "vehicleId": number;
  "vServiceType": string;
  "vTotalMealsUnits": number;
  "vTripId": string;
  "vUnits": number;
  "weekday": number;
  constructor(data?: SignatureViewInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `SignatureView`.
   */
  public static getModelName() {
    return "SignatureView";
  }

  /**
  * This method creates an instance of SignatureView for dynamic purposes.
  **/
  public static factory(data: SignatureViewInterface): SignatureView{
    return new SignatureView(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'SignatureView',
      plural: 'SignatureViews',
      path: 'SignatureViews',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'number'
        },
        "arrivedTime": {
          name: 'arrivedTime',
          type: 'string'
        },
        "captured": {
          name: 'captured',
          type: 'number'
        },
        "consumer_data": {
          name: 'consumer_data',
          type: 'any'
        },
        "consumer_eligibility": {
          name: 'consumer_eligibility',
          type: 'any'
        },
        "consumer_facilityID": {
          name: 'consumer_facilityID',
          type: 'string'
        },
        "consumer_internalID": {
          name: 'consumer_internalID',
          type: 'string'
        },
        "consumer_mci": {
          name: 'consumer_mci',
          type: 'string'
        },
        "consumer_person_data": {
          name: 'consumer_person_data',
          type: 'any'
        },
        "consumer_person_dob": {
          name: 'consumer_person_dob',
          type: 'any'
        },
        "consumer_person_firstname": {
          name: 'consumer_person_firstname',
          type: 'string'
        },
        "consumer_person_lastname": {
          name: 'consumer_person_lastname',
          type: 'string'
        },
        "consumer_status": {
          name: 'consumer_status',
          type: 'string'
        },
        "consumer_tenantId": {
          name: 'consumer_tenantId',
          type: 'number'
        },
        "consumer_weekDays": {
          name: 'consumer_weekDays',
          type: 'any'
        },
        "consumerId": {
          name: 'consumerId',
          type: 'number'
        },
        "ctime": {
          name: 'ctime',
          type: 'Date'
        },
        "dateTime": {
          name: 'dateTime',
          type: 'Date'
        },
        "dateTimeOfService": {
          name: 'dateTimeOfService',
          type: 'Date'
        },
        "dropoffTime": {
          name: 'dropoffTime',
          type: 'string'
        },
        "dtime": {
          name: 'dtime',
          type: 'Date'
        },
        "dtime_str": {
          name: 'dtime_str',
          type: 'string'
        },
        "employee_data": {
          name: 'employee_data',
          type: 'any'
        },
        "employee_person_data": {
          name: 'employee_person_data',
          type: 'any'
        },
        "employee_person_dob": {
          name: 'employee_person_dob',
          type: 'any'
        },
        "employee_person_firstname": {
          name: 'employee_person_firstname',
          type: 'string'
        },
        "employee_person_lastname": {
          name: 'employee_person_lastname',
          type: 'string'
        },
        "employee_status": {
          name: 'employee_status',
          type: 'string'
        },
        "employee_tenantId": {
          name: 'employee_tenantId',
          type: 'number'
        },
        "employee_tenantIds": {
          name: 'employee_tenantIds',
          type: 'any'
        },
        "employeeId": {
          name: 'employeeId',
          type: 'number'
        },
        "hash": {
          name: 'hash',
          type: 'string'
        },
        "imgFileId": {
          name: 'imgFileId',
          type: 'string'
        },
        "location": {
          name: 'location',
          type: 'GeoPoint'
        },
        "manifestRecId": {
          name: 'manifestRecId',
          type: 'string'
        },
        "marker": {
          name: 'marker',
          type: 'string'
        },
        "meta": {
          name: 'meta',
          type: 'any'
        },
        "note": {
          name: 'note',
          type: 'string'
        },
        "oneWayOnly": {
          name: 'oneWayOnly',
          type: 'number'
        },
        "pickupTime": {
          name: 'pickupTime',
          type: 'string'
        },
        "requestID": {
          name: 'requestID',
          type: 'string'
        },
        "scheduledTime": {
          name: 'scheduledTime',
          type: 'string'
        },
        "status": {
          name: 'status',
          type: 'string'
        },
        "tenantId": {
          name: 'tenantId',
          type: 'number'
        },
        "type": {
          name: 'type',
          type: 'string'
        },
        "unableToSign": {
          name: 'unableToSign',
          type: 'number'
        },
        "userId": {
          name: 'userId',
          type: 'number'
        },
        "utime": {
          name: 'utime',
          type: 'Date'
        },
        "validationState": {
          name: 'validationState',
          type: 'string'
        },
        "vAppName": {
          name: 'vAppName',
          type: 'string'
        },
        "vdate": {
          name: 'vdate',
          type: 'any'
        },
        "vehicle_internalId": {
          name: 'vehicle_internalId',
          type: 'number'
        },
        "vehicle_vin": {
          name: 'vehicle_vin',
          type: 'string'
        },
        "vehicleId": {
          name: 'vehicleId',
          type: 'number'
        },
        "vServiceType": {
          name: 'vServiceType',
          type: 'string'
        },
        "vTotalMealsUnits": {
          name: 'vTotalMealsUnits',
          type: 'number'
        },
        "vTripId": {
          name: 'vTripId',
          type: 'string'
        },
        "vUnits": {
          name: 'vUnits',
          type: 'number'
        },
        "weekday": {
          name: 'weekday',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
