import { Action } from '@ngrx/store';
import { type } from '../../shared/classes/utils/utils';
import { AccessToken, MyUser } from '../../shared/sdk';
import { IEnabledComponents } from '../reducers/sign/info';

export const CHECK_AUTH = type('[Sign] Check Auth');
export const CHECK_AUTH_SUCCESS = type('[Sign] Check Auth Success');
export const CHECK_AUTH_FAIL = type('[Sign] Check Auth Fail');

export const SET_TENANT = type('[Sign] Set Tenant');
// export const SET_TENANT_LOCAL = type('[Sign] Set Tenant Local');
export const SET_TENANT_SUCCESS = type('[Sign] Set Tenant Success');
export const SET_TENANT_FAIL = type('[Sign] Set Tenant Fail');

export const LOGIN = type('[Sign] Login');
export const LOGIN_SUCCESS = type('[Sign] Login Success');
export const LOGIN_WITH_MFA = type('[Sign] Login With MFA');
export const LOGIN_FAIL = type('[Sign] Login Fail');

export const LOGOUT = type('[Sign] Logout');
export const LOGOUT_SUCCESS = type('[Sign] Logout Success');
export const LOGOUT_FAIL = type('[Sign] Logout Fail');

export const LOAD_ROLES = type('[Sign] Load Roles');
export const LOAD_ROLES_SUCCESS = type('[Sign] Load Roles Success');
export const LOAD_ROLES_FAIL = type('[Sign] Load Roles Fail');
/**
 * Check Auth Actions
 */

export class CheckAuth implements Action {
  readonly type = CHECK_AUTH;

  constructor() {}
}

export class CheckAuthSuccess implements Action {
  readonly type = CHECK_AUTH_SUCCESS;

  constructor(
    public payload: {
      token: AccessToken;
      currentTenant: number;
      tenants: number[];
      // roles: string[];
      enabledComponents: IEnabledComponents;
    },
  ) {}
}

export class CheckAuthFail implements Action {
  readonly type = CHECK_AUTH_FAIL;

  constructor(public payload: any) {}
}

/**
 * Login Actions
 */

export class Login implements Action {
  readonly type = LOGIN;

  constructor(public payload: MyUser) {}
}

export class LoginSuccess implements Action {
  readonly type = LOGIN_SUCCESS;

  constructor(
    public payload: {
      token: AccessToken;
      tenants: number[];
      // roles: string[];
      enabledComponents: IEnabledComponents;
    },
  ) {}
}

export class LoginWithMfa implements Action {
  readonly type = LOGIN_WITH_MFA;

  constructor(public payload: { token: AccessToken }) {}
}

export class LoginFail implements Action {
  readonly type = LOGIN_FAIL;

  constructor(public payload: any) {}
}

/**
 * Logout Actions
 */

export class Logout implements Action {
  readonly type = LOGOUT;

  constructor() {}
}

export class LogoutSuccess implements Action {
  readonly type = LOGOUT_SUCCESS;

  constructor() {}
}

export class LogoutFail implements Action {
  readonly type = LOGOUT_FAIL;

  constructor(public payload: any) {}
}

/**
 * Tenant
 */

export class SetTenant implements Action {
  readonly type = SET_TENANT;

  constructor(public payload: number) {}
}

// export class SetTenantLocal implements Action {
//   readonly type = SET_TENANT_LOCAL;
//
//   constructor(public payload: number | null) {}
// }

export class SetTenantSuccess implements Action {
  readonly type = SET_TENANT_SUCCESS;

  constructor(public payload: number | null) {}
}

export class SetTenantFail implements Action {
  readonly type = SET_TENANT_FAIL;

  constructor(public payload: any) {}
}

/**
 * Roles
 */

export class LoadRoles implements Action {
  readonly type = LOAD_ROLES;

  constructor() {}
}

export class LoadRolesSuccess implements Action {
  readonly type = LOAD_ROLES_SUCCESS;

  constructor(public payload: string[]) {}
}

export class LoadRolesFail implements Action {
  readonly type = LOAD_ROLES_FAIL;

  constructor(public payload: any) {}
}

export type Actions =
  | CheckAuth
  | CheckAuthSuccess
  | CheckAuthFail
  | Login
  | LoginSuccess
  | LoginWithMfa
  | LoginFail
  | Logout
  | LogoutSuccess
  | LogoutFail
  | SetTenant
  // | SetTenantLocal
  | SetTenantSuccess
  | SetTenantFail
  | LoadRoles
  | LoadRolesSuccess
  | LoadRolesFail;
