<div mat-dialog-content>
  <kendo-upload
    [autoUpload]="true"
    [batch]="false"
    [withCredentials]="false"
    [saveField]="'files'"
    [saveMethod]="'PUT'"
    [saveUrl]="saveUrl"
    [responseType]="'text'"
    [concurrent]="false"
    [multiple]="false"
    [disabled]="uploadDisabled"
    [showFileList]="false"
    (select)="uploader_select($event)"
    (upload)="uploader_upload($event)"
    (success)="uploader_success($event)"></kendo-upload>

  <div *ngIf="files?.length">
    <br />
    <div *ngFor="let file of files">
      {{ file.name }}
    </div>
  </div>

  <div *ngIf="processing">
    <br />
    <dx-load-indicator height="20" width="20"></dx-load-indicator>
    Processing Uploaded File
  </div>

  <div *ngIf="done">
    <br />
    Done!
    <br />
    {{ response }}
  </div>

  <div *ngIf="error">
    <br />
    Error: {{ error }}
  </div>
</div>

<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="imported">Close</button>
</div>
