import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import DevExpress from 'devextreme/bundles/dx.all';
import ArrayStore from 'devextreme/data/array_store';
import CustomStore from 'devextreme/data/custom_store';
import { LoadOptions } from 'devextreme/data/load_options';
import { confirm } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import numeral from 'numeral';
import { Observable, of } from 'rxjs';
import { catchError, map, takeUntil, tap } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import {
  gqlMongoByKey,
  gqlMongoCount,
  gqlMongoLoad,
} from '../../../../shared/classes/loopback-custom-store/generic/store.utils';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { MyUtils } from '../../../../shared/sdk';
import { LoggerService, MyUserApi, MyUtilsApi } from '../../../../shared/sdk/services/custom';
import DataSourceOptions = DevExpress.data.DataSourceOptions;

@Component({
  selector: 'app-file-uploads',
  templateUrl: './file-uploads.component.html',
  styleUrls: ['./file-uploads.component.scss'],
})
export class FileUploadsComponent extends ABaseComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: true }) grid: DxDataGridComponent;
  grid_stateStoring: any;
  dso: DataSourceOptions;
  usersDso$: Observable<ArrayStore>;

  constructor(
    public logger: LoggerService,
    public config: ConfigService,
    public common: CommonService,
    private api: MyUtilsApi,
    private userApi: MyUserApi,
    private gridHelper: GridHelperService,
    private dialog: MatDialog,
    private dss: DataSourceService,
  ) {
    super(logger);

    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: '8a397537-c9e7-47ab-8061-6fb112657986',
    };
  }

  ngOnInit() {
    super.ngOnInit();

    this.dso = this.buildDataSource();
    this.usersDso$ = this.userApi.getUsernames().pipe(map(arr => new ArrayStore(arr)));
  }

  grid_onInitialized(e) {
    this.gridHelper.handle(e.component, {
      flatToTreeObject: false,
      copyIdsOnSaving: false,
      selectRowOnEdit: false,
      notifyErrors: true,
    });
  }

  grid_onToolbarPreparing(e) {}

  grid_onContextMenuPreparing(e) {
    if (e.row && e.row.rowType === 'data' && !e.row.isEditing) {
      const doc = e.row.data;
      const key = e.row.key;

      e.items = [
        {
          text: 'Remove imported docs',
          onItemClick: () => {
            confirm(`Are you sure want to delete [${oc(doc).meta.origFileName()}]?`, 'Confirm deletion').then(
              dialogResult => {
                if (dialogResult) {
                  this.grid.instance.beginCustomLoading('Removing...');

                  void this.dss
                    .getApi<MyUtilsApi>(MyUtils)
                    .revertImport(key)
                    .pipe(
                      tap(res => {
                        notify(`Removed ${res.n} documents`, 'success', 5000);
                        this.grid.instance.refresh();
                      }),
                      catchError(err => of(notify(err.message, 'error', 5000))),
                      tap(() => {
                        this.grid.instance.endCustomLoading();
                      }),
                      takeUntil(this.$onDestroy$),
                    )
                    .subscribe();
                }
              },
            );
          },
        },
      ];
    }
  }

  lengthFormatter(value) {
    return numeral(value).format('0,0.00 b');
  }

  private buildDataSource() {
    const self = this;
    const col = 'ExportsMeta';
    const store = new CustomStore({
      key: '_id',
      useDefaultSearch: true,
      cacheRawData: false,
      load: async (loadOptions: LoadOptions): Promise<any> =>
        gqlMongoLoad(self.dss, col, loadOptions).pipe().toPromise(),
      byKey: async (key: any | string | number): Promise<any> => gqlMongoByKey(self.dss, col, key).toPromise(),
      totalCount: async (loadOptions: LoadOptions): Promise<number> =>
        gqlMongoCount(self.dss, col, loadOptions).toPromise(),
    });

    const dso: DataSourceOptions = {
      store,
      // filter: ['state', '=', 'ACTIVE'],
      sort: [{ selector: 'ctime', desc: true }],
      postProcess: (data: Array<any>): Array<any> => {
        data
          .filter(rec => oc(rec).meta.contentLength())
          .forEach(rec => {
            rec.meta.contentLength = Number(rec.meta.contentLength);
            rec.meta.dates = (oc(rec).meta.dates([]) as string[]).sort();
          });
        return data;
      },
    } as DataSourceOptions;
    return dso;
  }
}
