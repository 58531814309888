
declare var Object: any;
export interface CustomFieldValuesViewInterface {
  "id"?: number;
  "cf_field": string;
  "cf_id": number;
  "cf_label": string;
  "cf_meta": any;
  "cf_objectId": number;
  "cf_objectType": string;
  "cf_optional": number;
  "cf_order": number;
  "cf_predefined": number;
  "cf_type": string;
  "customFieldId"?: number;
  "objectId"?: number;
  "objectType"?: string;
  "value"?: string;
  "valueBool"?: number;
  "valueDate"?: any;
  "valueDatetime"?: Date;
  "valueFloat"?: number;
  "valueInt"?: number;
  "valueJSON"?: any;
  "valueString"?: string;
  "valueText"?: string;
  "valueTime"?: string;
}

export class CustomFieldValuesView implements CustomFieldValuesViewInterface {
  "id": number;
  "cf_field": string;
  "cf_id": number;
  "cf_label": string;
  "cf_meta": any;
  "cf_objectId": number;
  "cf_objectType": string;
  "cf_optional": number;
  "cf_order": number;
  "cf_predefined": number;
  "cf_type": string;
  "customFieldId": number;
  "objectId": number;
  "objectType": string;
  "value": string;
  "valueBool": number;
  "valueDate": any;
  "valueDatetime": Date;
  "valueFloat": number;
  "valueInt": number;
  "valueJSON": any;
  "valueString": string;
  "valueText": string;
  "valueTime": string;
  constructor(data?: CustomFieldValuesViewInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `CustomFieldValuesView`.
   */
  public static getModelName() {
    return "CustomFieldValuesView";
  }

  /**
  * This method creates an instance of CustomFieldValuesView for dynamic purposes.
  **/
  public static factory(data: CustomFieldValuesViewInterface): CustomFieldValuesView{
    return new CustomFieldValuesView(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'CustomFieldValuesView',
      plural: 'CustomFieldValuesViews',
      path: 'CustomFieldValuesViews',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'number'
        },
        "cf_field": {
          name: 'cf_field',
          type: 'string'
        },
        "cf_id": {
          name: 'cf_id',
          type: 'number'
        },
        "cf_label": {
          name: 'cf_label',
          type: 'string'
        },
        "cf_meta": {
          name: 'cf_meta',
          type: 'any'
        },
        "cf_objectId": {
          name: 'cf_objectId',
          type: 'number'
        },
        "cf_objectType": {
          name: 'cf_objectType',
          type: 'string'
        },
        "cf_optional": {
          name: 'cf_optional',
          type: 'number'
        },
        "cf_order": {
          name: 'cf_order',
          type: 'number'
        },
        "cf_predefined": {
          name: 'cf_predefined',
          type: 'number'
        },
        "cf_type": {
          name: 'cf_type',
          type: 'string'
        },
        "customFieldId": {
          name: 'customFieldId',
          type: 'number'
        },
        "objectId": {
          name: 'objectId',
          type: 'number'
        },
        "objectType": {
          name: 'objectType',
          type: 'string'
        },
        "value": {
          name: 'value',
          type: 'string'
        },
        "valueBool": {
          name: 'valueBool',
          type: 'number'
        },
        "valueDate": {
          name: 'valueDate',
          type: 'any'
        },
        "valueDatetime": {
          name: 'valueDatetime',
          type: 'Date'
        },
        "valueFloat": {
          name: 'valueFloat',
          type: 'number'
        },
        "valueInt": {
          name: 'valueInt',
          type: 'number'
        },
        "valueJSON": {
          name: 'valueJSON',
          type: 'any'
        },
        "valueString": {
          name: 'valueString',
          type: 'string'
        },
        "valueText": {
          name: 'valueText',
          type: 'string'
        },
        "valueTime": {
          name: 'valueTime',
          type: 'string'
        },
      },
      relations: {
      }
    }
  }
}
