
declare var Object: any;
export interface AdcTripSignatureStatsInterface {
  "id"?: string;
  "consumerId"?: number;
  "count": number;
  "serviceType"?: string;
  "tenantId": number;
  "vdate"?: any;
}

export class AdcTripSignatureStats implements AdcTripSignatureStatsInterface {
  "id": string;
  "consumerId": number;
  "count": number;
  "serviceType": string;
  "tenantId": number;
  "vdate": any;
  constructor(data?: AdcTripSignatureStatsInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `AdcTripSignatureStats`.
   */
  public static getModelName() {
    return "AdcTripSignatureStats";
  }

  /**
  * This method creates an instance of AdcTripSignatureStats for dynamic purposes.
  **/
  public static factory(data: AdcTripSignatureStatsInterface): AdcTripSignatureStats{
    return new AdcTripSignatureStats(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'AdcTripSignatureStats',
      plural: 'AdcTripSignatureStats',
      path: 'AdcTripSignatureStats',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'string'
        },
        "consumerId": {
          name: 'consumerId',
          type: 'number'
        },
        "count": {
          name: 'count',
          type: 'number'
        },
        "serviceType": {
          name: 'serviceType',
          type: 'string'
        },
        "tenantId": {
          name: 'tenantId',
          type: 'number'
        },
        "vdate": {
          name: 'vdate',
          type: 'any'
        },
      },
      relations: {
      }
    }
  }
}
