import * as tslib_1 from "tslib";
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import get from 'lodash-es/get';
import isDate from 'lodash-es/isDate';
import isEmpty from 'lodash-es/isEmpty';
import isObjectLike from 'lodash-es/isObjectLike';
import isPlainObject from 'lodash-es/isPlainObject';
import isString from 'lodash-es/isString';
import set from 'lodash-es/set';
import unset from 'lodash-es/unset';
import { getPathsByKey } from '../../../classes/utils/object.utils';
import { deferredAsPromise } from '../../../classes/utils/promise.utils';
import { asShortDate } from '../../../classes/utils/time.utils';
import { ExtSDKModels } from '../../ext-sdk/services/ext-sdk-models.service';
import { DataSourceService } from '../../my-common/services/datasource.service';
export class FormHelperService {
    constructor(models, dss) {
        this.models = models;
        this.dss = dss;
    }
    processFormValueAsync(model, data, options) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // convert Date to string format
            (options.datePaths || []).forEach(path => {
                const val = get(data, path, undefined);
                if (isDate(val) || (isString(val) && !isEmpty(val))) {
                    set(data, path, asShortDate(val));
                }
            });
            // copy data field json
            const dataPaths = [...getPathsByKey(model, 'data'), ...getPathsByKey(model, 'meta')];
            dataPaths
                .filter(path => get(data, path, undefined))
                .filter(path => get(model, path, undefined))
                .filter(path => isPlainObject(get(model, path, undefined)) || isObjectLike(get(model, path, undefined)))
                .map(path => [path, get(model, path, {}), get(data, path, {})])
                .map(([path, oldValue, newValue]) => [path, Object.assign({}, oldValue, newValue)])
                .forEach(([path, value]) => set(data, path, value));
            // unset all '_' prefixed properties
            const prefixPaths = getPathsByKey(data, /^_.*/);
            prefixPaths.forEach(path => {
                unset(data, path);
            });
            return data;
        });
    }
    saveModelAsync(model) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const store = this.dss.getStore(model.constructor);
            const modelName = model.constructor.getModelName();
            const keyName = this.models.getIdName(modelName);
            //    console.log(modelName, keyName, model[keyName]);
            let id;
            let values;
            if (model[keyName]) {
                [values, id] = yield deferredAsPromise(store.update(model[keyName], model));
            }
            else {
                [values, id] = yield deferredAsPromise(store.insert(model));
                // model[keyName] = values;
            }
            // console.log('saveModelAsync', [values, id]);
            return [values, id];
        });
    }
    getPathFormArrayPairs(form, formConfigMap) {
        return Array.from(formConfigMap.keys())
            .reverse() // from root to children
            .map(path => [path, form.get(path)])
            .filter(([path, ctrl]) => ctrl && ctrl instanceof FormArray);
    }
    withEachFormControl(form, fn) {
        Object.keys(form.controls).forEach(key => {
            const ctrl = form.get(key);
            if (ctrl instanceof FormArray) {
                this.withEachFormControl(ctrl, fn);
            }
            else if (ctrl instanceof FormGroup) {
                this.withEachFormControl(ctrl, fn);
            }
            else if (ctrl instanceof FormControl) {
                fn(ctrl);
            }
        });
    }
}
