
declare var Object: any;
export interface ByClientsSignatureStatsInterface {
  "id"?: string;
  "consumerId"?: number;
  "count": number;
  "vdate"?: any;
  "weekday"?: number;
}

export class ByClientsSignatureStats implements ByClientsSignatureStatsInterface {
  "id": string;
  "consumerId": number;
  "count": number;
  "vdate": any;
  "weekday": number;
  constructor(data?: ByClientsSignatureStatsInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `ByClientsSignatureStats`.
   */
  public static getModelName() {
    return "ByClientsSignatureStats";
  }

  /**
  * This method creates an instance of ByClientsSignatureStats for dynamic purposes.
  **/
  public static factory(data: ByClientsSignatureStatsInterface): ByClientsSignatureStats{
    return new ByClientsSignatureStats(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ByClientsSignatureStats',
      plural: 'ByClientsSignatureStats',
      path: 'ByClientsSignatureStats',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'string'
        },
        "consumerId": {
          name: 'consumerId',
          type: 'number'
        },
        "count": {
          name: 'count',
          type: 'number'
        },
        "vdate": {
          name: 'vdate',
          type: 'any'
        },
        "weekday": {
          name: 'weekday',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
