<dx-validation-group>
  <form #formElm (ngSubmit)="form_ngSubmit($event)">
    <div mat-dialog-title>
      <mat-toolbar [@scrollState]="scrollOnTop ? 'top' : 'non-top'">
        <mat-toolbar-row>
          <div style="flex: auto; text-overflow: ellipsis">
            <ng-container *ngIf="formComponent?.$windowTitle$">
              {{ formComponent?.$windowTitle$ | async }}
              <span style="font-size: 0.5em">
                {{ formComponent?.$windowSubTitle$ | async }}
              </span>
            </ng-container>

            <ng-container *ngIf="!formComponent?.$windowTitle$">
              {{ data?.title || (data?.modelId ? 'Edit ' : 'New ') + data?.ModelClass?.getModelName() }}
            </ng-container>

            <ng-container *ngIf="formComponent?.form?.dirty"> *</ng-container>
          </div>

          <!--          <span style="flex: auto;"></span>-->

          <ng-container *ngFor="let btn of data?.headerBtns || []">
            <button
              mat-icon-button
              type="button"
              [matTooltip]="btn.tooltip"
              color="primary"
              style="margin: -1em 0em -1em -1em"
              (click)="header_btn_click($event, btn.click)">
              <mat-icon [fontSet]="'fas'" [fontIcon]="btn.icon"></mat-icon>
              {{ btn.title }}
            </button>
          </ng-container>

          <button
            mat-icon-button
            type="button"
            [mat-dialog-close]="false"
            matTooltip="Close"
            color="primary"
            style="margin: -1em">
            <mat-icon aria-label="Close">close</mat-icon>
          </button>
        </mat-toolbar-row>

        <mat-toolbar-row style="padding: 0; height: auto" *ngIf="formComponent?.tabsTitles?.length > 0">
          <dx-tabs
            width="auto"
            [dataSource]="formComponent.tabsTitles"
            [(selectedIndex)]="formComponent.selectedTab"></dx-tabs>
        </mat-toolbar-row>
      </mat-toolbar>
    </div>

    <mat-dialog-content cdkScrollable>
      <ng-template #target></ng-template>

      <dx-load-panel
        [visible]="formComponent?.loading || formComponent?.formLoading || formComponent?.submitting"
        [message]="formComponent?.submitting ? 'Submitting...' : 'Loading...'"
        [showIndicator]="true"
        [showPane]="true"
        [shading]="true"
        [closeOnOutsideClick]="false"></dx-load-panel>
    </mat-dialog-content>

    <mat-dialog-actions>
      <!--    <p>-->
      <dx-validation-summary></dx-validation-summary>
      <!--    </p>-->

      <div class="alert-wrapper">
        <p class="alert-danger" *ngIf="formRef?.instance?.errorMessage">
          <small>{{ formRef?.instance?.errorMessage }}</small>
        </p>
      </div>

      <!-- /// -->

      <div class="toolbar">
        <button
          *ngIf="formComponent?.collapsibleForm"
          mat-button
          type="button"
          color="primary"
          (click)="showMore_click($event)"
          matTooltip="Show/Hide additional fields">
          {{ formComponent?.stateCollapsed ? 'Show more' : 'Show less' }}
        </button>

        <button
          *ngIf="formComponent?.resetableForm"
          mat-button
          type="button"
          color="primary"
          (click)="reset_click($event)"
          matTooltip="Reset form fields to initial values">
          Reset
        </button>

        <span style="flex: auto"></span>

        <button mat-button type="button" color="primary" (click)="cancel_click($event)">Cancel</button>

        <button mat-button type="submit" color="primary" cdkFocusInitial (click)="submit_click($event)">Save</button>

        <button mat-button type="submit" color="primary" (click)="submitAndClose_click($event)">Save and Close</button>
      </div>
    </mat-dialog-actions>
  </form>
</dx-validation-group>
