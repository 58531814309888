import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import DevExpress from 'devextreme/bundles/dx.all';
import { promiseAsDeferred } from '../../../../shared/classes/utils/promise.utils';
import { Employee, TripManifest } from '../../../../shared/sdk/models';
import { LoggerService } from '../../../../shared/sdk/services/custom';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { HelperService as EmployeeHelperService } from '../../../employee/services/helper.service';
import { HelperService } from '../../services/helper.service';
import DataSourceOptions = DevExpress.data.DataSourceOptions;

@Component({
  selector: 'app-drop-down-grid-employees',
  templateUrl: './drop-down-grid-employees.component.html',
  styleUrls: ['./drop-down-grid-employees.component.scss'],
  providers: [HelperService, EmployeeHelperService],
})
export class DropDownGridEmployeesComponent implements OnChanges, OnInit {
  @Input() ds: DataSourceOptions;
  @Input() gridColumns: any[] = [
    { dataField: 'person_firstname', caption: 'Firstname' },
    { dataField: 'person_lastname', caption: 'Lastname' },
    { dataField: 'person_nickname', caption: 'Nickname' },
  ];

  @Input() manifest: TripManifest;
  @Input() placeholder = 'Select...';

  @Input() value: any;
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Output() valueChanged: EventEmitter<any> = new EventEmitter();

  constructor(
    protected logger: LoggerService,
    private dss: DataSourceService,
    public helper: HelperService,
    public employeeHelper: EmployeeHelperService,
    protected cd: ChangeDetectorRef,
  ) {
    this.buildDataSource();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnInit() {}

  private buildDataSource() {
    this.ds = {
      store: this.dss.getStore(Employee, null, false),
      filter: [this.helper.buildDriversFilter('employeePosition_name'), ['status', '=', 'ACTIVE']],
      postProcess: (data: any[]): any[] => {
        if (!data.filter(item => item.id === this.employeeHelper.selfEntity.id).length) {
          data.unshift(this.employeeHelper.selfEntity);
        }
        return data;
      },
    } as DataSourceOptions;

    const _store_byKey = this.ds.store.byKey;
    this.ds.store.byKey = (key: any): Promise<any> => {
      if (key === this.employeeHelper.selfEntity.id) {
        return promiseAsDeferred(Promise.resolve(this.employeeHelper.selfEntity)).promise() as any;
      } else {
        return _store_byKey.bind(this.ds.store)(key);
      }
    };
  }
}
