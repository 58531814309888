import { ApplicationRef, Inject, Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select } from '@ngrx/store';
import { defer, iif, of, Subject, throwError, timer } from 'rxjs';
import { catchError, delay, exhaustMap, first, map, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ServerError } from '../../shared/classes/ServerUnavailableError';
import { ExtSDKModels } from '../../shared/modules/ext-sdk/services/ext-sdk-models.service';
import { CommonService } from '../../shared/modules/my-common/services/common.service';
import { ConfigService, IEnvVars } from '../../shared/modules/my-common/services/config.service';
import { PusherService } from '../../shared/modules/my-common/services/pusher.service';
import { UiService } from '../../shared/modules/ui/services/ui.service';
import { Config, ConfigApi, LoggerService, MyUserApi, MyUtilsApi, SDKModels } from '../../shared/sdk';
import { SwUpdatesService } from '../../sw-updates/sw-updates.service';
import {
  LOAD_CONFIG,
  LOAD_ENVVARS,
  LoadConfigFail,
  LoadConfigSuccess,
  LoadEnvVarsFail,
  LoadEnvVarsSuccess,
  SAVE_CONFIG,
  SaveConfig,
  SaveConfigFail,
  SaveConfigSuccess,
  SET_OFFLINE,
  SET_ONLINE,
  SetOffline,
  SetOnline,
} from '../actions/core';
import { LOAD_ROLES, LoadRoles, LoadRolesFail, LoadRolesSuccess } from '../actions/sign';
import { isOnline } from '../reducers/core';

const USE_PING = environment.production;

@Injectable()
export class CoreEffects implements OnDestroy {
  @Effect({ dispatch: false })
  online$ = this.actions$.pipe(
    ofType(SET_ONLINE, SET_OFFLINE),
    tap((action: Action) => {
      if (action instanceof SetOffline) {
        this.ui.showOverlay();
      } else {
        this.ui.hideOverlay();
      }
    }),
  );

  @Effect()
  loadRoles$ = this.actions$.pipe(
    ofType(LOAD_ROLES),
    switchMap(() =>
      iif(
        () => this.userApi.isAuthenticated(),
        this.userApi.getRoles(this.common.auth.getCurrentUserId()),
        of([]),
      ).pipe(
        // tap(roles => console.log('LoadRolesSuccess:', roles)),
        map((roles: string[]) => new LoadRolesSuccess(roles)),
        catchError(err => of(new LoadRolesFail(err))),
      ),
    ),
  );

  @Effect()
  loadEnvVars$ = this.actions$.pipe(
    ofType(LOAD_ENVVARS),
    switchMap(() =>
      iif(() => this.userApi.isAuthenticated(), this.configApi.getEnvVars(), throwError('User not authenticated')).pipe(
        map((vars: IEnvVars) => new LoadEnvVarsSuccess(vars)),
        catchError(err => of(new LoadEnvVarsFail(err))),
      ),
    ),
  );

  @Effect()
  loadConfig$ = this.actions$.pipe(
    ofType(LOAD_CONFIG),
    switchMap(() =>
      iif(() => this.userApi.isAuthenticated(), this.configApi.getConfig(), throwError('User not authenticated')).pipe(
        map((config: Config) => new LoadConfigSuccess(config)),
        catchError(err => of(new LoadConfigFail(err))),
      ),
    ),
  );

  @Effect()
  saveConfig$ = this.actions$.pipe(
    ofType(SAVE_CONFIG),
    map((action: SaveConfig) => action.payload),
    switchMap((config: Config) =>
      iif(
        () => this.userApi.isAuthenticated(),
        this.configApi.saveConfig(config),
        throwError('User not authenticated'),
      ).pipe(
        map(savedConfig => new SaveConfigSuccess(savedConfig)),
        catchError(err => of(new SaveConfigFail(err))),
      ),
    ),
  );

  private $onDestroy$ = new Subject<void>();
  @Effect({ dispatch: false })
  init$ = defer(() => this.appRef.isStable).pipe(
    first(v => v === true),
    delay(1), // wait for load all effects
    tap(() => {
      this.logger.log('Core Init effect raised');

      USE_PING && this.common.store.dispatch(new SetOffline());

      USE_PING &&
        timer(0, 5000)
          .pipe(
            // tap(console.log),
            exhaustMap(() =>
              this.utilsApi.ping().pipe(
                catchError(error => of({ error })),
                withLatestFrom(this.common.store.pipe(select(isOnline))),
                tap(([pingRes, online]) => {
                  // console.log(pingRes, online, this.router.url);

                  if (
                    pingRes === true ||
                    (pingRes.error &&
                      pingRes.error.code !== ServerError.ServerUnavailableError().code &&
                      pingRes.error.status !== 504) // 504 Gateway Time-out – The server didn’t respond in time
                  ) {
                    if (!online) this.common.store.dispatch(new SetOnline());
                  } else {
                    if (online) this.common.store.dispatch(new SetOffline());
                  }
                }),
              ),
            ),
            takeUntil(this.$onDestroy$),
          )
          .subscribe();

      timer(0, 5000)
        .pipe(
          tap(() => this.common.store.dispatch(new LoadRoles())),
          takeUntil(this.$onDestroy$),
        )
        .subscribe();

      //////////////////////////////////////////////////////////////////////

      /////////////////////////////////////////////////////////////////////

      //        this.common.store.pipe(
      //          select(isOnline),
      //          filter(identity),
      //          first(),
      // takeUntil(this.$onDestroy$),
      //        ).subscribe(() => {
      //          console.log('createChangeStream: ', Consumer.getModelName());
      //
      //          this.models.getApi(Consumer.getModelName())
      //            .createChangeStream().subscribe((data) => {
      //            console.log(`[${Consumer.getModelName()}] changes:`, data);
      //          });
      //        });
    }),
  );

  constructor(
    private appRef: ApplicationRef,
    private actions$: Actions,
    private router: Router,
    private logger: LoggerService,
    @Inject(SDKModels) private models: ExtSDKModels,
    private common: CommonService,
    private config: ConfigService,
    private pusher: PusherService,
    private ui: UiService,
    private configApi: ConfigApi,
    private userApi: MyUserApi,
    private utilsApi: MyUtilsApi,
    private swUpdates: SwUpdatesService, // private io: IoService,
  ) {}

  ngOnDestroy(): void {
    this.$onDestroy$.next();
  }
}
